import React from 'react';
import './App.css';
import Responsive from "./ResponsiveView";
import { BrowserRouter } from "react-router-dom";
//import { ThemeProvider, createTheme } from '@mui/styles/makeStyles';

//const theme = createTheme();

function storageChange(event) {
    if (event.key === 'login') {
        if (event.newValue !== true) {
            window.location.href = '/';
        }
    }
}

function App() {

    window.addEventListener('storage', storageChange, false)

    return (
        
            <BrowserRouter >
                <Responsive />
            </BrowserRouter>
        
        
    );
}

export default App;
