//import create from 'zustand';;
//import  {FetchApi}  from '../service';

import {IconUsers, IconKey,IconDashboard ,IconTypography
  , IconPalette, IconShadow, IconWindmill
  ,IconList,IconListDetails,IconUserCircle
  ,IconDeviceLaptop,IconUserCode,IconUserBolt,IconPoint
  ,IconTable,IconDatabase,IconFileDatabase,IconHierarchy2,IconUsb
} from '@tabler/icons-react';


import {CreateNewFolderOutlined as CreateNewFolderOutlinedIcon
  , FolderDeleteOutlined as FolderDeleteOutlinedIcon 
  , EditOutlined as  EditOutlineIcon
} from '@mui/icons-material';


import {APIService} from "../service";

const icons = {
  IconUsers,
  IconList,
  IconListDetails,
  IconUserCode,
  IconUserBolt,
  IconPoint,
  IconUserCircle,
  IconDeviceLaptop,
  IconKey,
  IconDashboard,
  IconTypography, IconPalette, IconShadow, IconWindmill,
  IconTable,IconDatabase,IconFileDatabase,IconHierarchy2,IconUsb
  ,CreateNewFolderOutlinedIcon,FolderDeleteOutlinedIcon,EditOutlineIcon
};


const success = 'success';

export const userStore = (set, get) => ({
   
    isErroredAdmin:false,

    errorMessageAdmin:"",

    toggleErrorDisplayAdmin: () => 
      set({isErroredAdmin:false}) ,

    

    sideBarAdminMenus:{items:[{
      id: 'admin',
      title: '',
      type: 'group',
      children: [
              {
              id: 'userlist',
              title: 'Users',
              type: 'item',
              icon: icons.IconUsers,
              url: '/gui/admin/users',
              children:[/*
                  {
                      id: 'active',
                      title: 'Active',
                      type: 'item',
                      url: '/gui/admin/users',
                      icon: icons.IconUserCircle,
                      breadcrumbs: false
                  },
                  {
                    id: 'inactive',
                    title: 'Inactive',
                    type: 'item',
                    url: '/gui/admin/inactive',
                    icon: icons.IconUserCircle,
                    breadcrumbs: false
                  },
                  {
                    id: 'terminated',
                    title: 'Terminated',
                    type: 'item',
                    url: '/gui/admin/suspend',
                    icon: icons.IconUserCircle,
                    breadcrumbs: false
                  } */
                  ]
              }
                  
          ]
      },
      {
        id: 'roles',
        title: '',
        type: 'group',
        children:[
            {
              id: 'roles',
              title: 'Roles',
              type: 'item',
              url: '/gui/admin/roles',
              icon: icons.IconList,
              breadcrumbs: false
            },
        ]
      },
      /* {
        id: 'activity',
        //title: 'Activity',
        type: 'group',
        children:[
            {
              id: 'activity',
              title: 'Activity',
              type: 'collapse',
              icon: icons.IconUserCode,
              children:[
                  {
                    id: 'activityOnline',
                    title: 'Online',
                    type: 'item',
                    url: '/admin/useronline',
                    icon: icons.IconUserBolt,
                    breadcrumbs: false
                  },
                  {
                    id: 'activitytxns',
                    title: 'Transaction',
                    type: 'item',
                    url: '/user/useractivities',
                    icon: icons.IconListDetails,
                    breadcrumbs: false
                  }
              ]
            }
        ]
      } */
    ]},
    
    users: [],

    getUsers: ()=> APIService.fetchApi("GET", {}, "getUsers",{}, set)
                    .then(res => {
                      set ({users: res.result.data
                        ,errorMessage: res.errorMessage
                        ,isErrored:res.error})
                      }),
    roles:{},
    fetchRoles: () => APIService.fetchApi("POST", {}, "fetchRoles",{},set)
                      .then(res => {
                        set ({roles: res.result
                          ,errorMessage: res.errorMessage
                          ,isErrored:res.error})
                        }),
          /* APIService.apiCall("POST", {}, "fetchRoles")
                    .then(res => {
                      console.log('asds',res.status);
                      return res.json()

                    })
                    .then(res => {
                      if (res.status==success) {
                        set ({roles: res})                 
                      } else {
                        set ({roles: {}})
                        set({errorMessage:res.message})
                        set({isErrored:true})
                      }
                    })
                    .catch(error => {
                      set ({roles: {}})
                      set({errorMessage:error})
                      set({isErrored:true})
                    })*/ 
    userRole:{},
    fetchRoleByUserId: (user_id) => APIService.apiCall ("POST", {user_id}, "fetchUserAppRoleByUser",{},set)
                            .then(res => res.json())
                            .then(res => {
                              set ({userRole: res})
                            })
                            .catch(error => {
                              set ({userRole: {}})
                            }),
              
    
    /* async (type,input,APIUrl) =>  {
       const res = await FetchApi(type,input,APIUrl);
      console.log('store',res);
      set( {users:  res } )
    }, */

    userInfo:[],

    updateUserInfo: (apiData) => {
      set({userInfo:apiData})
    }

    
});



