import {APIService} from "../service";
import {IconFileDatabase,IconDatabase} from '@tabler/icons-react';
import {Table} from "../icons/SvgIcons"
import util from "../util/util";

export const catalog = (set,get) => ({
    subjectFolderList:{},
    updateSubjectFolderList : () => APIService.fetchApi("POST", {},"fetchMetadataFolder",{},set)
                                    .then(res => {
                                        APIService.fetchApi("POST", {},"fetchMetadataSubjectTable",{},set)
                                        .then(childRows => {
                                            const parentParams = {idKey:'subject_repo_id'
                                                ,titleKey:'subject_repo_name'
                                                ,type:'collapse'
                                                ,icon:IconFileDatabase,url:'url'
                                                ,parentIdkey:'parent_repo_id'
                                                ,breadCrumb: false
                                                ,contextMenu: true
                                                ,treeType:"metadata"
                                                ,rows:res.result.data}

                                            const childParams = {idKey:'dataset_id'
                                                ,titleKey:'dataset_name'
                                                ,type:'item'
                                                ,icon:Table
                                                ,url:false
                                                ,parentIdkey:'parent_repo_id'
                                                ,breadCrumb: true
                                                ,childRows:childRows.result.data} 

                                            const menus = {items:[
                                                { 
                                                id: 'catalog',
                                                title: '',
                                                type: 'group',
                                                children: [
                                                    {
                                                        id: 'metadata',
                                                        title: 'Metadata',
                                                        type: 'collapse',
                                                        icon: IconDatabase,
                                                        open:true,
                                                        contextMenu: true,
                                                        menuRequired:false,
                                                        children:[ ...util.treeHierarchy(parentParams,childParams)
                                                        ]
                                                    },
                                                ]},
                                            ]}

                                            set({sideBarMetadataMenus:menus})

                                        })
                                        set (
                                            {subjectFolderList: res.result
                                            ,errorMessage: res.errorMessage
                                            ,isErrored:res.error
                                            }
                                        )
    
      }),


    postCatalogSubjectRepo: (input, params) => APIService.fetchApi('POST', input, 'postCatalogSubjectRepo' ,params,set)
                      .then(res=>{
                            set ({//subjectApplsData: res.result.data,
                                errorMessage: res.errorMessage,
                                isErrored:res.error,
                                })
                            if (!res.error) {
                                set((state) => ({ ...state.updateSubjectFolderList()
                                                }
                                ))
                            }
                            return res.error
                            }
                      ),

    updateCatalogSubjectRepo: (input, params) => APIService.fetchApi('PUT', input, 'postCatalogSubjectRepo' ,params,set)
                      .then(res=>{
                            set ({//subjectApplsData: res.result.data,
                              errorMessage: res.errorMessage,
                              isErrored:res.error,
                              })
                            if (!res.error) {
                                set((state) => ({ ...state.updateSubjectFolderList()
                                                }
                                ))
                            }
                            return res.error
                          }
                      ),


    deleteCatalogSubjectRepo: ( params) => APIService.fetchApi('DELETE', {}, 'deleteCatalogSubjectRepo' ,params,set)
                        .then(res=>{
                                set ({//subjectApplsData: res.result.data,
                                    errorMessage: res.errorMessage,
                                    isErrored:res.error,
                                    })
                                if (!res.error) {
                                    set((state) => ({ ...state.updateSubjectFolderList()
                                                    }
                                    ))
                                }
                                return res.error
                            }
                        ),

    postMetadataSubjectRepo: (input, params) => APIService.fetchApi('POST', input, 'postMetadataSubjectRepo' ,params,set)
                        .then(res=>{
                                set ({//subjectApplsData: res.result.data,
                                    errorMessage: res.errorMessage,
                                    isErrored:res.error,
                                    })
                                if (!res.error) {
                                    set((state) => ({ ...state.updateSubjectFolderList()
                                                    }
                                    ))
                                }
                                return res.error
                            }
                        ),
  
    updataMetadataSubjectRepo: (input, params) => APIService.fetchApi('PUT', input, 'updataMetadataSubjectRepo' ,params,set)
                        .then(res=>{
                                set ({//subjectApplsData: res.result.data,
                                    errorMessage: res.errorMessage,
                                    isErrored:res.error,
                                    })
                                if (!res.error) {
                                    set((state) => ({ ...state.updateSubjectFolderList()
                                                    }
                                    ))
                                }
                                return res.error
                            }
                        ),
  
  
    deleteMetadataSubjectRepo: ( params) => APIService.fetchApi('DELETE', {}, 'deleteMetadataSubjectRepo' ,params,set)
                          .then(res=>{
                                set ({//subjectApplsData: res.result.data,
                                    errorMessage: res.errorMessage,
                                    isErrored:res.error,
                                    })
                                    if (!res.error) {
                                        set((state) => ({ ...state.updateSubjectFolderList()
                                                        }
                                        ))
                                    }
                                return res.error
                              }
                          ),


    subjectApplsData:[],
    postSubjectAppls: (input, params) => APIService.fetchApi('POST', input, 'postSubjectAppls' ,params,set)
                    .then(res=>{
                        set ({//subjectApplsData: res.result.data,
                            errorMessage: res.errorMessage,
                            isErrored:res.error,
                            })
                        return res.error
                        }
    ),

    getSubjectAppls: ( params) => APIService.fetchApi('GET', {} , 'getSubjectAppls' ,params,set)
                        .then(res=>{
                            set ({subjectApplsData: res.result.data
                                ,errorMessage: res.errorMessage
                                ,isErrored:res.error
                                })
                            return res.error
                            }
    ),
    
    deleteSubjectAppl: (params) => APIService.fetchApi('DELETE',{}, 'deleteSubjectAppl' ,params,set)
                    .then(res=>{
                        set ({//subjectApplsData: res.result.data,
                            errorMessage: res.errorMessage,
                            isErrored:res.error,
                            })
                        return res.error
                        }
    ),

    getImportMetatableTables: (params) => APIService.fetchApi('GET',{}, 'getImportMetatableTables' ,params,set)
                    .then(res=>{
                        set ({
                            errorMessage: res.errorMessage,
                            isErrored:res.error,
                            })
                        return res
                        }
    ),

    postImportMetatableTables: (input,params) => APIService.fetchApi('POST',input, 'postImportMetatableTables' ,params,set)
                    .then(res=>{
                        set ({
                            errorMessage: res.errorMessage,
                            isErrored:res.error,
                            })
                        return res
                        }
    ),

    postFileUploadDataset: (input,params) => APIService.fetchApi('POST',input, 'postUploadDataset' ,params,set)
    .then(res=>{
        set ({
            errorMessage: res.errorMessage,
            isErrored:res.error,
            })
        return res.error
        }
    ),

    dataset:[],
    fetchDataset: (param) => APIService.fetchApi('GET', {} , 'getDataset', param,set)
            .then(res=>{
                    set ({dataset: res.result.data
                        ,errorMessage: res.errorMessage
                        ,isErrored:res.error
                        })
                }
            ),

    updateDataset: (data,param) => APIService.fetchApi('PUT', data, 'updateDataset',param,set)
                    .then(res=>{
                        set ({dataset: res.result.data
                            ,errorMessage: res.errorMessage
                            ,isErrored:res.error
                            })
                        }
                    ),

    datasetSchema: [],
    fetchMetadataSchema: (param) => APIService.fetchApi('GET', {}, 'getDatasetSchema',param,set)
                                .then(datasetSchmaData=>{

                                 /*    APIService.fetchApi('POST', input, 'getDataset')
                                        .then(datasetData=>{
                                            set ({dataset: datasetData.result
                                                ,errorMessage: datasetData.message
                                                ,isErrored:datasetData.error
                                                }) */

                                            set ({datasetSchema: datasetSchmaData.result.data
                                                    ,errorMessage: datasetSchmaData.errorMessage
                                                    ,isErrored:datasetSchmaData.error
                                                     })
                                        return datasetSchmaData.result.data
                                    }
                                ),

    postDatasetSchema: (data,param) => APIService.fetchApi('POST', data, 'postDatasetSchema', param,set)
                    .then(res=>{
                        set ({//datasetSchema: res.result.data
                            errorMessage: res.errorMessage,
                            isErrored:res.error,
                            })

                            return res.error
                        }
                    ),

    updateDatasetSchema: (data, param) => APIService.fetchApi('PUT', data, 'updateDatasetSchema', param,set)
                                    .then(res=>{
                                       if (res.error) {
                                        set ({errorMessage: res.errorMessage
                                            ,isErrored:res.error
                                            })
                                        } else { 
                                        set ({datasetSchema: res.result.data
                                            ,errorMessage: res.errorMessage
                                            ,isErrored:res.error
                                            })
                                        }
                                       
                                    }   
                                        
                                    ),
    
    deleteDatasetSchema: (param) => APIService.fetchApi('DELETE', {}, 'deleteDatasetSchema', param,set)
                                    .then(res=>{
                                        set ({datasetSchema: res.result.data
                                            ,errorMessage: res.errorMessage
                                            ,isErrored:res.error
                                            })
                                            return res.error
                                        }
                                       
                                    ),
    //subject level attributes
    subjectConnectionData:{data:[]},
    fetchSubjectConnectionData: (subject_id) => APIService.fetchApi('POST', subject_id, 'fetchMetadataSubjectConn',{},set)
                                    .then(res=>{
                                                set ({subjectConnectionData: res.result
                                                        ,errorMessage: res.message
                                                        ,isErrored:res.error
                                                         })
                                        }
                                    ),
    subjectConnDatasetData:[],
    fetchSubjectConnDataset: (params) => APIService.fetchApi('POST', params, 'fetchSubjectConnDataset',{},set)
                                    .then(res=>{
                                        set ({subjectConnDatasetData: res.result.data
                                                ,errorMessage: res.errorMessage
                                                ,isErrored:res.error
                                                })
                                        }
                                    ),
    
    //Connection Module Attributes
    connectionDataAll:{data:[]},
    fetchConnectionDataAll:() => APIService.fetchApi('POST', {}, 'fetchConnection')
                                    .then(res=>{
                                        set ({connectionDataAll: res.result
                                                ,errorMessage: res.errorMessage
                                                ,isErrored:res.error
                                                })
                                        }
                                    ),
    // ERModel Module Attributes
    updateSideBarERModelMenus:()=> APIService.fetchApi("POST", {},"fetchMetadataFolder", {}, set)
                                    .then(res => {
                                        //'app_folder_id','app_folder_name','collapse',IconFileDatabase,'url','parent_folder_id', false,res.result.data
                                        APIService.fetchApi("POST", {},"fetchMetadataSubjectTable")
                                        .then(childRows => {
                                            const parentParams = {idKey:'app_folder_id'
                                                ,titleKey:'app_folder_name'
                                                ,type:'collapse',icon:IconFileDatabase,url:'url'
                                                ,parentIdkey:'parent_folder_id'
                                                ,breadCrumb: false
                                                ,rows:res.result.data}

                                            const childParams = {idKey:'dataset_id'
                                                ,titleKey:'dataset_name'
                                                ,type:'item'
                                                ,icon:Table
                                                ,url:false
                                                ,parentIdkey:'parent_folder_id'
                                                ,breadCrumb: true
                                                ,childRows:childRows.result.data} 

                                            const menus = {items:[
                                                { 
                                                id: 'metadata',
                                                title: '',
                                                type: 'group',
                                                children: [
                                                    {
                                                        id: 'metadata',
                                                        title: 'Metadata',
                                                        type: 'collapse',
                                                        icon: IconDatabase,
                                                        open:true,
                                                        children:[ ...util.treeHierarchy(parentParams,childParams)
                                                        ]
                                                    },
                                                ]},
                                                { 
                                                    id: 'ermodels',
                                                    title: '',
                                                    type: 'group',
                                                    children: [
                                                        {
                                                            id: 'ermodel',
                                                            title: 'ER Model',
                                                            type: 'collapse',
                                                            icon: IconDatabase,
                                                            children:[
                                                            ]
                                                        },
                                                    ]},
                                            ]}

                                            set({sideBarERModelMenus:menus})

                                        })
                                        set ({subjectFolderList: res.result
                                        ,errorMessage: res.errorMessage
                                        ,isErrored:res.error
                                        
                                    })
            }),

});



/*


updateSubjectFolderList : () => APIService.fetchApi("POST", {},"fetchMetadataFolder")
                                    .then(res => {
                                        APIService.fetchApi("POST", {},"fetchMetadataSubjectTable")
                                        .then(childRows => {
                                            const parentParams = {idKey:'app_folder_id'
                                                ,titleKey:'app_folder_name'
                                                ,type:'collapse',icon:IconFileDatabase,url:'url'
                                                ,parentIdkey:'parent_folder_id'
                                                ,breadCrumb: false
                                                ,rows:res.result.data}

                                            const childParams = {idKey:'dataset_id'
                                                ,titleKey:'dataset_name'
                                                ,type:'item'
                                                ,icon:Table
                                                ,url:false
                                                ,parentIdkey:'parent_folder_id'
                                                ,breadCrumb: true
                                                ,childRows:childRows.result.data} 

                                            const menus = {items:[
                                                { 
                                                id: 'catalog',
                                                title: '',
                                                type: 'group',
                                                children: [
                                                    {
                                                        id: 'metadata',
                                                        title: 'Metadata',
                                                        type: 'collapse',
                                                        icon: IconDatabase,
                                                        open:true,
                                                        menuRequired:false,
                                                        children:[ ...util.treeHierarchy(parentParams,childParams)
                                                        ]
                                                    },
                                                ]},
                                            ]}

                                            set({sideBarMetadataMenus:menus})

                                        })
                                        set ({subjectFolderList: res.result
                                        ,errorMessage: res.errorMessage
                                        ,isErrored:res.error
                                        
                                    })
    
      }),

*/