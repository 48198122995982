let now = Date.now();

export const connectionAdhocForm = [
     
      {
        "key": "db_host", "displayName": "Host", "order": 4
        ,"type": "string", "error": "", "value":"", "required": true,
        "display":true ,hidden:false,disabled:false
      },
      {
        "key": "db_port", "displayName": "Port", "order": 5
        ,"type": "string", "error": "", "value":"", "required": true,
        "display":true ,hidden:false,disabled:false
      },
      {
        "key": "database_nm", "displayName": "Database Name", "order": 6
        ,"type": "string", "error": "", "value":"", "required": true,
        "display":true ,hidden:false,disabled:false
      },
      {
        "key": "db_user", "displayName": "User", "order": 7
        ,"type": "string", "error": "", "value":"", "required": true,
        "display":true ,hidden:false,disabled:false
      },
      {
        "key": "db_password", "displayName": "Password", "order": 8
        ,"type": "password", "error": "", "value":"", "required": true,
        "display":true ,hidden:false,disabled:false
      },
      {
        "key": "datawarehouse_nm", "displayName": "Warehouse Name(Snowflake)", "order": 9
        ,"type": "string", "error": "", "value":"", "required": true,
        "display":true ,hidden:false,disabled:false
      },
      {
        "key": "db_schema", "displayName": "Schema Name", "order": 11
        ,"type": "string", "error": "", "value":"", "required": true,
        "display":true ,hidden:false,disabled:false
      },      
      {
        "key": "vendor_database", "displayName": "Vendor Database", "order": 17
        ,"type": "string", "error": "", "value":""
        ,"options":[{value:1,key:"Teradata" }
                   ,{value:2,key:"Snowflake"}
                   ,{value:3,key:"Oracle"}
                   ,{value:4,key:"Postresql"}
                   ,{value:6,key:"MySQL"}
                   ,{value:7,key:"SqlServer"}
                   ,{value:8,key:"Vertica"}   
                   ,{value:9,key:"Redshift"} 
                 ,] 
        , "required": true,select:true
        ,"display":true ,hidden:false,disabled:false
      },     
      {
        "key": "database_id", "displayName": "Database Id", "order": 2
        ,"type": "number", "error": "", "value":"", "required": true,
        "display":false ,hidden:true,disabled:false
      },

];