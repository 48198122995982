import * as React from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';

import { NewTable } from '../../../icons/SvgIcons';
import { useStore } from '../../../store/useStore';
import Rename from "./Rename"
import NewFolder from './NewFolder';
import DeleteFolder from './DeleteFolder';
import NewLkpTable from './NewLkpTable';



const  SidebarFolderMenus = () => {

    const { treeViewSubMenu
            ,treeViewMenusAction
            ,updateTreeViewMenusAction
            ,updateFolderMenuAction
            ,updateDataFactoryMenuOpen
            } = useStore((state)=>({
        treeViewSubMenu:state.treeViewSubMenu,
        treeViewMenusAction:state.treeViewMenusAction,
        updateTreeViewMenusAction : state.updateTreeViewMenusAction,
        updateFolderMenuAction: state.updateFolderMenuAction,
        updateDataFactoryMenuOpen:state.updateDataFactoryMenuOpen,
        })
    )

    const open = Boolean(treeViewMenusAction.anchorEl);
    const [rename, setRename] = React.useState(false);
    const [newFolder, setNewFolder] = React.useState(false);
    const [newLkpTable, setNewLkpTable] = React.useState(false);
    const [deleteFolder, setDeleteFolder] = React.useState(false);

    

    const handleFolderAction = (actionData) => {
        
        //updateFolderMenuAction({type:actionData.actionType, dialog:true, content:actionData, treeData:treeViewMenusAction.clickedTree})
        updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null })
        updateDataFactoryMenuOpen(true)
    }


    const handleDeleteRepo = () => {
        setDeleteFolder(true)
    };

    const handleRenameRepo = () => {
        //updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null })
        //setFolderAction(true)
        setRename(true)
    };

    const handleAddFolderRepo = () => {
        setNewFolder(true);
    };

    const handleCancelRename = () =>{
        setRename(false);
    };

    const handleNewLkpTable = () => {
        setNewLkpTable(true);
    }

    const handleClose = () => {
        updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null, })
        setRename(false);
        setDeleteFolder(false);
        setNewLkpTable(false);
        setNewFolder(false);

    };


    return (
        <React.Fragment>

            <Menu
                anchorEl={treeViewMenusAction.anchorEl}
                id="sub-menus"
                open={open}
                onClose={handleClose}
                slotProps={{
                    elevation: 0,
                    sx: { minWidth:"100px",
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        border: '2px solid #d1c4e9',
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            borderTop: '1px solid #d1c4e9',
                            borderLeft: '1px solid #d1c4e9',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >

                {!newFolder &&
                    <MenuItem 
                        onClick={handleAddFolderRepo}
                    >
                        <ListItemIcon>
                            <CreateNewFolderOutlinedIcon />
                        </ListItemIcon>
                        New Subject Folder
                    </MenuItem>
                }

                {
                    (newFolder  )&& 
                    <NewFolder
                        treeViewMenusAction={treeViewMenusAction}
                        updateTreeViewMenusAction={updateTreeViewMenusAction} 
                        handleCancelNewFolder={()=>setNewFolder(false)}
                    />
                }

                {
                    treeViewMenusAction.clickedTree.treeType === 'lkp' &&
                    newLkpTable &&
                        (
                            <NewLkpTable
                                treeViewMenusAction={treeViewMenusAction}
                                updateTreeViewMenusAction={updateTreeViewMenusAction} 
                                handleCancelNewFolder={()=>setNewLkpTable(false)}
                            /> 
                        )
                }    


                {

                        treeViewMenusAction.clickedTree.treeType === 'lkp' &&
                        !newLkpTable &&
                        (
                            (<MenuItem 
                                onClick={handleNewLkpTable}
                            >
                                <ListItemIcon>
                                    <NewTable />
                                </ListItemIcon>
                                New Lookup Table
                            </MenuItem>) 
                        )
                }

                
                

                
                { ( !rename && !(treeViewMenusAction.clickedTree.title === 'Code Values' 
                                || treeViewMenusAction.clickedTree.title ===  'Code Translation'
                                || treeViewMenusAction.clickedTree.title === 'Lookup Data'
                                )
                    ) &&
                    (<MenuItem 
                        onClick={handleRenameRepo}
                    >
                        <ListItemIcon>
                            <DriveFileRenameOutlineOutlinedIcon />
                        </ListItemIcon>
                        Rename
                    </MenuItem>)
                }

                { (rename 
                    )  &&
                    <Rename
                        treeViewMenusAction={treeViewMenusAction}
                        updateTreeViewMenusAction={updateTreeViewMenusAction} 
                        handleCancelRename={handleCancelRename}
                    />
                }
                            
                
                 
                 {  (!deleteFolder  && !(treeViewMenusAction.clickedTree.title === 'Code Values' 
                                || treeViewMenusAction.clickedTree.title ===  'Code Translation'
                                || treeViewMenusAction.clickedTree.title === 'Lookup Data'
                                 )
                    )   && 
                        <MenuItem 
                            onClick={handleDeleteRepo}
                        >
                            <ListItemIcon>
                                <DeleteForeverOutlinedIcon />
                            </ListItemIcon>
                            Delete
                        </MenuItem>
                }

                { (deleteFolder 
                ) &&
                    <DeleteFolder 
                        treeViewMenusAction={treeViewMenusAction}
                        updateTreeViewMenusAction={updateTreeViewMenusAction} 
                        handleCanceDelete={()=>{setDeleteFolder(false)}}
                    />
                }


            </Menu>

        </React.Fragment>
    );
};

export default SidebarFolderMenus;
