import React from "react";
import SubMenusApplMgmt from "../ApplManagement/SubMenus";
import FolderMenus from "../catalog/Metadata/FolderMenus";
import ERModelMenus from  "../catalog/ERModel/SidebarMenus";
import UDPFolderMenus from "../UnifiedDataPipeline/SidebarFolderMenus"
import BusinessFolderMenus from "../Business/SidebarFolderMenus";

import { useStore } from "../../store/useStore";

const SubMenus = () => {

    const {moduleOpened} = useStore((state)=>({
            moduleOpened: state.appMenuBarAction.module,
        })
    );

    return(
        <React.Fragment>
            {moduleOpened==='applManagement' && <SubMenusApplMgmt />}
            { moduleOpened==='metadata' && <FolderMenus />}
            {moduleOpened==='ermodel' &&   <ERModelMenus />}
            {moduleOpened==='datapipeline' &&  <UDPFolderMenus />}
            {moduleOpened ==='business' && <BusinessFolderMenus />}

        </React.Fragment>
    )

}

export default SubMenus;