
import moment from "moment"
import { fetchFormByType } from "../schema"
//import { hierarchyMenu, addMemberMenuList  } from "../constants/menuConstants"
import { AuthService, APIService } from "../service";
import React from 'react';

import MuiAlert from '@mui/material/Alert';
const commons = {}

const response = {
    status: "failure",
    message: ""
};

commons.detectInternet = () => {

    
    return !navigator.onLine ? <MuiAlert severity="warning" elevation={6}
        variant="filled" >No Internet Connection!</MuiAlert> : ''
  
}
commons.redirectHome = () => {
    localStorage.clear();
    window.location.href = "/";
};
commons.genericRes = () => {
    const obj = response;

    return obj;
};


commons.getAPIRes = (params, type, api, props) => {
    try {
        let res = commons.genericRes();
        if (!navigator.onLine ) {
            return res;
        }
        
        return new Promise((resolve, reject) => {
            APIService.apiCall(type, params, api)
                .then(res => {
                    if (res.status === 401 || res.status === 440) {
                        commons.redirectHome();
                    }
                    return res.json();
                })
                .then(res => {
                    resolve(res);
                })
                .catch(error => {
                    commons.errorLog(error);
                    resolve(error);
                });
        });
    } catch (e) {
        console.log(e);
        return e;
    }
};

commons.setParent = () => {
    let pId = "";
    pId = AuthService.getUserId();
    return pId
}
commons.fetchRole = (node) => {
    let pRole = "";
    if (node == undefined || node == null) {
        pRole = AuthService.getUserRole()
    }
    if (node && node.orgProf && node.orgProf[0] && node.orgProf[0].roles &&
        node.orgProf[0].roles[0] && node.orgProf[0].roles[0].type) {
        pRole = node.orgProf[0].roles[0].type;
    }
    return pRole
}

commons.fetchAbbreviation = (node) => {
    let clientCode = "";
    if (node && node.orgProf && node.orgProf[0] && node.orgProf[0].roles &&
        node.orgProf[0].roles[0] && node.orgProf[0].roles[0].profile) {
        clientCode = node.orgProf[0].roles[0].profile.orgAbbreviation
    }
    return clientCode
}
/*
commons.setMenuListByMenu = (item) => {
    item.menuList = [];
    if (item.key.toLowerCase() === "addmember") item.menuList = [...addMemberMenuList];
    if (item.key.toLowerCase() === "remove") item.menuList = [...disableMenuList]
    if (item.key.toLowerCase() === "profile") item.menuList = [...profileMenuList]
    return item;
}
*/

commons.filterMenuByLogin = (menuData, role) => {
    if (role !== AuthService.getUserRole()) {
        console.log("entered filter menu")
        let defaultMenu = JSON.parse(localStorage.getItem("defaultMenu"));
        console.log("defaultMenu ... " + JSON.stringify(defaultMenu))
        let defMenus = defaultMenu.menus;
        let defSubMenus = defaultMenu.submenus;
        console.log("defMenus ... " + defMenus)
        console.log("defSubMenus ... " + defSubMenus)


        menuData.map(async (item, index) => {
            let menu = item.name;
            let filteredMenu = defMenus.find(filterItem => (filterItem.name.toLowerCase() == menu.toLowerCase()))
            if (filteredMenu == undefined) menuData.splice(index, 1)
            await commons.setMenuListByMenu(item);
            if (filteredMenu && item.menuList.length) {
                let parentVal = filteredMenu.value;
                let filteredMenuList = []
                let subList = defSubMenus.filter(item => (item.parent == parentVal))
                subList.map((nestedItem, index) => {
                    let subMenu = nestedItem.name;
                    console.log("subMenu .." + subMenu)
                    let filteredSubMenu = item.menuList.find(subFilterItem =>
                        (subFilterItem.key == subMenu)
                    )

                    if (filteredSubMenu != undefined) filteredMenuList.push(filteredSubMenu)
                })
                item.menuList = filteredMenuList
            }
        })

    }
    return menuData;
}
/*
commons.filterMenuByRole = async (data, type, role) => {
    let menuData = [];
    if (type == "hierarchyMenu")
        menuData = [...hierarchyMenu]
    let menus = data.menus;
    let subMenus = data.submenus;

    menuData.map(async (item, index) => {
        let menu = item.name;
        let filteredMenu = menus.find(filterItem => (filterItem.name.toLowerCase() == menu.toLowerCase()))
        if (filteredMenu == undefined) menuData.splice(index, 1)
        await commons.setMenuListByMenu(item);
        if (filteredMenu && item.menuList.length) {
            let parentVal = filteredMenu.value;
            let filteredMenuList = []
            let subList = subMenus.filter(item => (item.parent == parentVal))
            subList.map((nestedItem, index) => {
                let subMenu = nestedItem.name;
                console.log("subMenu .." + subMenu)
                let filteredSubMenu = item.menuList.find(subFilterItem =>
                    (subFilterItem.key == subMenu)
                )

                if (filteredSubMenu != undefined) filteredMenuList.push(filteredSubMenu)
            })
            item.menuList = filteredMenuList
        }
    })

    menuData = await commons.filterMenuByLogin(menuData, role);
    return menuData
}

*/
commons.showError = () => {

}
commons.errorLog = () => {

}

commons.forceLogOut = () => {
    localStorage.clear();
    window.location.href = '/';
}

commons.checkIfPastDate = (inputDate) => {
    var givenDate = moment(new Date(inputDate))
    var now = moment();
    if (now > givenDate) {
        return true;
    }
    return false;
}

commons.checkIfFutureDate = (inputDate) => {
    var givenDate = moment(new Date(inputDate))
    var now = moment();
    return givenDate.isSameOrAfter(now, 'day');
}


commons.compareDate = (inputDate1, inputDate2) => {

    var inputDate1Val = moment(new Date(inputDate1))
    var inputDate2Val = moment(new Date(inputDate2))
    if (inputDate2Val > inputDate1Val) return true;
    return false;

}


commons.affValiations = (formInputDataValid) => {


    let affValid = formInputDataValid.find((item) => {
        if (item.key === "affType" && item.value === "custom") {
            return item;
        }
        return false;
    })

    if (affValid) {
        formInputDataValid.find((item) => {
            if (item.key === "affId" && item.value.length === 0) {
                item.error = "Affiliation ID required";
                return item;
            }
        })
    }
    else {
        formInputDataValid.find((item) => {
            if (item.key === "affId" && item.value.length === 0) {
                item.error = "";
                return item;
            }
        })
    }

    //check for affiliation dates
    let affIdStDt = formInputDataValid.find((item) => {
        if (item.key === "affIdStDt" && item.value !== undefined && item.value !== null && item.value !== "") {
            return item;
        }
    })

    let affIdClsDt = formInputDataValid.find((item) => {
        if (item.key === "affIdClsDt" && item.value !== undefined && item.value !== null && item.value !== "") {
            return item;
        }
    })

    if (affIdClsDt && affIdStDt) {
        let valid = commons.compareDate(affIdStDt.value, affIdClsDt.value);
        if (!valid) affIdClsDt.error = "Affiliation End date should be greater than start date"
    }
    return formInputDataValid
}


commons.subOrgReqFields = (type) => {
    let formData = fetchFormByType(type)
    console.log("formData .. "+formData)
    let required = [];
    let defValues = [];
    formData.map(nestedItem => {

    
            if (nestedItem.required === true) {
                required.push(nestedItem.key)
            }
            if (nestedItem.options) {
                let obj = {};
                obj[nestedItem["key"]] = nestedItem["options"];
                defValues.push(obj)
            }
        
    })
    return { "required": required, "defValues": defValues }
}

commons.affValiationsCSV = (obj) => {

    if (obj["affType"] === "custom") {
        if (obj["affId"].length === 0)
            obj.error.push("Affiliation ID required")
    }
    if (obj["dateOfInc"]) {
        if (!commons.checkIfPastDate(obj["dateOfInc"]))
            obj.error.push("DateOfInc start date should be a past date")
    }

    if (obj["dob"]) {
        if (!commons.checkIfPastDate(obj["dob"]))
            obj.error.push("dob  date should be a past date")
    }


    let affIdStDt = obj["affIdStDt"];
    let affIdClsDt = obj["affIdClsDt"];


    if (!commons.checkIfFutureDate(affIdStDt)) {
        obj.error.push("Affiliation start date should be a  future date")

    }
    if (!commons.checkIfFutureDate(affIdClsDt)) {
        obj.error.push("Affiliation end date should be a  future date")

    }


    if (affIdClsDt && affIdStDt) {
        let valid = commons.compareDate(affIdStDt, affIdClsDt);
        if (!valid) obj.error.push("Affiliation End date should be greater than start date")
    }

    return obj
}


export default commons;
