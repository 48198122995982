
import {userStore} from './userStore';
import {commonStore} from './commonStore';
import { subMenuItems } from './subMenuItems';
import { catalog } from './catalog';
import { dag } from './dag';
import { datapipeline } from './datapipeline';
import { jobFlow } from './jobFlow';
import applManagement from './applManagament';
import ermodel from './ermodel';
import business from './business';


export const resetStore = (set) => ({
    resetAll: () => {
        userStore(set).reset()
        commonStore(set).reset()
        subMenuItems(set).reset()
        catalog(set).reset()
        dag(set).reset()
        datapipeline(set).reset()
        jobFlow(set).reset()
        applManagement(set).reset()
        ermodel(set).reset()
        business(set).reset()
    },
});
