

export const commonStore = (set, get) => ({

    customBorderRadius: 12,
    updateBordeRadius: (radius) => {
      set((state)=>({customBorderRadius:radius}))
    },

    appSideBarToggle:true,
    updateAppSideBarToggle: (action) =>{
      set({appSideBarToggle:action})
    },

    appSiderBarWidth: 260,
    updateAppSideBarWidth: (width) => {
      set({appSiderBarWidth:width})
    },

    sideBarToggleButtonVisibility:'visible',
    updateSideBarToggleButtonVisibility: (toggleVisibility)=>{
      set({sideBarToggleButtonVisibility:toggleVisibility})
    },

    adminMenuActions:{
      isOpen: [], // for active default menu
      defaultId: 'default',
      fontFamily: `'Roboto', sans-serif`,
      opened: true,
      borderRadius:12
    },

    updateAdminMenuAction: (action) => {
      let id;
      if (action.type==='MENU_OPEN') {
        id = action.id;
        set((state)=>({adminMenuActions:{...state,isOpen:[...id,state.isOpen]}}))
      } else if(action.type==='SET_MENU') {
        set((state)=>({adminMenuActions:{...state,opened:action.opened}}))
      }
    },

    sideBarNavItem:{
      isOpen: [], // for active default menu
      defaultId: 'default',
      fontFamily: `'Roboto', sans-serif`,
      opened: true,
      borderRadius:12
    },

    updateSideBarNavItem: (action) => {
      let id;
      if (action.type==='MENU_OPEN') {
        id = action.id;
        set((state)=>({sideBarNavItem:{...state,isOpen:[id]}}))
      } else if(action.type==='SET_MENU') {
        set((state)=>({sideBarNavItem:{...state,opened:action.opened}}))
      }
    },

    appMenuBarAction:{module:"", open:false, sideBarMenu:""},

    updateAppMenuBarAction : (action) => {
      set({appMenuBarAction:action})
    },

    isErrored:false,

    errorMessage:"",

    isLoading:false,

    updateIsLoading: val => {
      set({isLoading:val})
    },

   

    toggleErrorDisplay: () => 
      set({isErrored:false}),
    
});
