//require('dotenv').config();
//import dotenv from 'dotenv';

const api_url_remote =  process.env.NODE_ENV==="production" ? "https://guvaiapi.nagabhushanareddy.com/"  : null;


//"https://guava-api-a794e7aa2801.herokuapp.com/"
//console.log(process.env);
//console.log('api_url_remote', api_url_remote);


export const API_BASE_URL =  api_url_remote || "http://localhost:9006/";


export const ROOT_ID = 123;



//export const ROOT_ID = 123; 
//"apiUser":  'pudino', 
//"apiKey":  'SDFJLSD123123YIREWWEMNBVV',

export const API_BODY_BASE = {

    "apiUser":  'pudino', 
    "apiKey":   'SDFJLSD123123YIREWWEMNBVV',

}

export const urls = {

    //Common User authentication
    "logout": API_BASE_URL + "user/logout",
    "login": API_BASE_URL + 'user/validateUser',
    "guestToken": API_BASE_URL + "admin/generateGuestToken",
    "apiKey": API_BASE_URL + "admin/generate-api-key",
    "verifyGuestOTP":API_BASE_URL+"otp/generateGuestOTP",
    "verifyAccount": API_BASE_URL + "otp/generateOTP",
    "validateOTPToken": API_BASE_URL + "otp/validateOTPToken",
    "validateGuestOTP":API_BASE_URL+"otp/validateGuestOTP",
    "resetPwd": API_BASE_URL + "user/resetPassword",
    "changePwd":API_BASE_URL+"user/changePassword",
    "validateToken": API_BASE_URL + "user/isValidated",
    "fetchAppRolesFolder": API_BASE_URL + "appRoles/fetchRolesFolder",
    "fetchRoles": API_BASE_URL + "appRoles/fetchRoles",
    "createRole": API_BASE_URL + "appRoles/createRole",
    "updateRole": API_BASE_URL + "appRoles/updateRole",
    "assignUserAppRole": API_BASE_URL + "appRoles/assignUserAppRole",
    "deleteUserAppRole":API_BASE_URL +"appRoles/deleteUserAppRole",
    "fetchUsersAppRole": API_BASE_URL + "appRoles/fetchUserAppRole",
    "fetchUserAppRoleByUser":API_BASE_URL +"appRoles/fetchUserAppRoleByUser",
    "getProfile": API_BASE_URL + "user/profile",
    "getuserList": API_BASE_URL + "user/userList",
    "generateOTP": API_BASE_URL + "otp/generateOTP",
    "verifyOtpSetAccDetails": API_BASE_URL + "wrapper/verifyOtpSetAccDetails",
    "getUserProfile": API_BASE_URL + "user/getProfile",
    "setUserProfile": API_BASE_URL + "user/profile",
    "createUser":API_BASE_URL +"user/createUser",
    
    "fetchProjectFolder": API_BASE_URL + "catalog/fetchProjectFolder",
    "fetchMetadataHeader":API_BASE_URL + "catalog/fetchMetadataHeader",

    //Admin
    "getProfile": API_BASE_URL + "user/profile",
    "getUsers": API_BASE_URL + "admin/users",

    //User

    //Application Management
    "fetchAllApplMgmtAppl": API_BASE_URL + "appl-mgmt/applications/param1",
    "applMgmtFetchApplicationByName": API_BASE_URL + "appl-mgmt/applications/sample",
    "postApplMgmtApplication": API_BASE_URL + "appl-mgmt/data-factory/param1/application",
    "putApplMgmtApplication": API_BASE_URL + "appl-mgmt/application/param1",
    "deleteApplMgmtApplication": API_BASE_URL + "appl-mgmt/application/param1",
    "getApplMgmtDataHubFactory": API_BASE_URL + "appl-mgmt/data-factories",
    "postNewDataFactory": API_BASE_URL + "appl-mgmt/data-factory/param1",
    "putNewDataFactory": API_BASE_URL + "appl-mgmt/data-factory",
    "getApplMgmtApplModule": API_BASE_URL + "appl-mgmt/application/param1/modules",
    "postApplMgmtApplModule": API_BASE_URL + "appl-mgmt/application/param1/modules",
    "putApplMgmtApplModule": API_BASE_URL + "appl-mgmt/module/param1",
    "deleteApplMgmtApplModule": API_BASE_URL + "appl-mgmt/module/param1",
    "getApplMgmtContact": API_BASE_URL + "appl-mgmt/application/param1/contact",
    "postApplMgmtContact": API_BASE_URL + "appl-mgmt/application/param1/contact",
    "putApplMgmtContact": API_BASE_URL + "appl-mgmt/contact/param1",
    "deleteApplMgmtContact": API_BASE_URL + "appl-mgmt/contact/param1",

    //Catalog
    "postCatalogSubjectRepo":API_BASE_URL + "catalog/subject-repo/param1",
    "getCatalogSubjectRepo":API_BASE_URL + "catalog/subject-repo/param1",
    "updateCatalogSubjectRepo":API_BASE_URL + "catalog/subject-repo/param1",
    "deleteCatalogSubjectRepo":API_BASE_URL + "catalog/subject-repo/param1",
    "postMetadataSubjectRepo":API_BASE_URL + "catalog/metadata-repo/param1",
    "getMetadataSubjectRepo":API_BASE_URL + "catalog/metadata-repo/param1",
    "updataMetadataSubjectRepo":API_BASE_URL + "catalog/metadata-repo/param1",
    "deleteMetadataSubjectRepo":API_BASE_URL + "catalog/metadata-repo/param1",


    "fetchMetadataSubjectConn":API_BASE_URL + "catalog/subject-connection",
    "fetchSubjectConnDataset":API_BASE_URL + "catalog/subject-connection-dataset",
    "fetchMetadataFolder":API_BASE_URL + "catalog/metadata-folders",
    "fetchMetadataSubjectTable":API_BASE_URL + "catalog/metadata-subject-table",
    "getImportMetatableTables": API_BASE_URL + "catalog/import-metadata/param1/tables",
    "postImportMetatableTables": API_BASE_URL + "catalog/import-metadata/param1",
    "postUploadDataset": API_BASE_URL + "catalog/upload-metadata/param1",

    "getDataset": API_BASE_URL + "catalog/dataset/param1",
    "postDataset": API_BASE_URL + "catalog/dataset/param1",
    "updateDataset": API_BASE_URL + "catalog/dataset/param1",
    "deleteDataset": API_BASE_URL + "catalog/dataset/param1",
    "getDatasetSchema": API_BASE_URL + "catalog/dataset-schema/param1",
    "postDatasetSchema": API_BASE_URL + "catalog/dataset-schema/param1",
    "updateDatasetSchema": API_BASE_URL + "catalog/dataset-schema/param1",
    "deleteDatasetSchema": API_BASE_URL + "catalog/dataset-schema/param1",

    "createCatalogTable": API_BASE_URL + "catalog/createTable",
    "getCatalogTableSchema": API_BASE_URL + "catalog/catalogTableSchema",

    "postSubjectAppls":API_BASE_URL + "catalog/param1/application",
    "getSubjectAppls":API_BASE_URL + "catalog/application/param1",
    "deleteSubjectAppl":API_BASE_URL + "catalog/application/param1",   
    "getCatalogAppFolders": API_BASE_URL + "catalog/catalogAppFolders",
    "getCatalogTableList": API_BASE_URL + "catalog/catalogTableList",

    //"getCatalogTableSchema": API_BASE_URL + "catalog/catalogTableSchema",
    //"createCatalogTable": API_BASE_URL + "catalog/createTable",
   // "updatedCatalogTable": API_BASE_URL + "catalog/updateTable", --old
    "createBulkCatalogTable":API_BASE_URL+"catalog/createBulkCatalogTable",
    "createCatalogTableSchema": API_BASE_URL + "catalog/createTableSchema",
    "updateCatalogTableSchema": API_BASE_URL + "catalog/updateTableSchema",
    "createCatalogAppFolder": API_BASE_URL + "catalog/createCatalogAppFolder",
    "updateCatalogAppFolder": API_BASE_URL + "catalog/updateCatalogAppFolder",
    "deleteCatalogAppFolder" : API_BASE_URL + "catalog/deleteCatalogAppFolder",
    "copyTableContent" : API_BASE_URL + "catalog/copyTableContent",
    "copyFolderContent" : API_BASE_URL + "catalog/copyFolderContent",
    "fetchConnectionTreeHierachy" : API_BASE_URL + "connection/fetchTreeViewHierachy",
    
    //ermodel
    "postERModelSubjectRepo":API_BASE_URL + "ermodel/subject-repo/param1",
    "getERModelSubjectRepo":API_BASE_URL + "ermodel/subject-repo",
    "updateERModelSubjectRepo":API_BASE_URL + "ermodel/subject-repo/param1",
    "deleteERModelSubjectRepo":API_BASE_URL + "ermodel/subject-repo/param1",
    "postERModelHdr":API_BASE_URL + "ermodel/model/param1",
    "getERModelHdr":API_BASE_URL + "ermodel/models",
    "updateERModelHdr":API_BASE_URL + "ermodel/model/param1",
    "deleteERModelHdr":API_BASE_URL + "ermodel/model/param1",
    "getERModelEntities":API_BASE_URL + "ermodel/entities/param1",
    "postERModelEntity":API_BASE_URL + "ermodel/entity/param1",
    "deleteERModelEntity":API_BASE_URL + "ermodel/entity/param1",
    "updateERModelEntity":API_BASE_URL + "ermodel/entity/param1",
    "getERModelEntityRelation": API_BASE_URL + "ermodel/entity-relation/param1",
    "postERModelEntityRelation":API_BASE_URL + "ermodel/entity-relation/param1",
    "deleteERModelEntityRelation":API_BASE_URL + "ermodel/entity-relation/param1",
    "updateERModelEntityRelation":API_BASE_URL + "ermodel/entity-relation/param1",
    "getERModelEntityCSS":API_BASE_URL + "ermodel/entity-css/param1",
    "postERModelEntityCSS":API_BASE_URL + "ermodel/entity-css/param1",
    "deleteERModelEntityCSS":API_BASE_URL + "ermodel/entity-css/param1",
    "updateERModelEntityCSS":API_BASE_URL + "ermodel/entity-css/param1",
    "getERModelStarEntities":API_BASE_URL + "ermodel/star/entities/param1",
    "postERModelStarEntity":API_BASE_URL + "ermodel/star/entity/param1",
    "deleteERModelStarEntity":API_BASE_URL + "ermodel/star/entity/param1",
    
    //Connections
    "fetchConnection" : API_BASE_URL + "metadata/catalog/connection",
    "createDBConnection" : API_BASE_URL + "connection/database/createConnection",
    "updateDBConnection" : API_BASE_URL + "connection/database/updateConnection",
    "deleteDBConnection" : API_BASE_URL + "connection/database/deleteConnection",
    "createSchemaFromFile" :  API_BASE_URL + "catalog/createSchemaFromFile",
    "importMetadataTableListFromDB" :  API_BASE_URL + "catalog/importMetadataTableListFromDB",
    "importMetadataFromDB" :  API_BASE_URL + "catalog/importMetadataFromDB",
    "autoUpdateDatasetSchema" : API_BASE_URL + "catalog/autoUpdateDatasetSchema",
    "metadataCheckOut" :  API_BASE_URL + "catalog/checkOut",
    "metadataCheckIn" :  API_BASE_URL + "catalog/checkIn",
    "metadataCheckOutUndo" :  API_BASE_URL + "catalog/checkOutUndo",
    "getCheckoutStatus" :  API_BASE_URL + "version/findCheckOutByObj",
    "fetchVersionHistory" :  API_BASE_URL + "version/fetchVersionHistory",
    "fetchGlossaryFolderTree" :  API_BASE_URL + "business/fetchGlossaryFolderTree",
    "fetchBusinessGlossary" :  API_BASE_URL + "business/fetchBusinessGlossary",
    "createBusinessGlossary" :  API_BASE_URL + "business/createBusinessGlossary",
    "updateBusinessGlossary" :  API_BASE_URL + "business/updateBusinessGlossary",


    //pipeline

    "getUDPSubjectRepo": API_BASE_URL + "pipeline/subject-repo",
    "postUDPSubjectRepo": API_BASE_URL + "pipeline/subject-repo/param1",
    "deleteUDPSubjectRepo": API_BASE_URL + "pipeline/subject-repo/param1",
    "updateUDPSubjectRepo": API_BASE_URL + "pipeline/subject-repo/param1",
    "getUDPHeaderALL": API_BASE_URL + "pipeline/header",
    "getUDPHeader": API_BASE_URL + "pipeline/header/param1",
    "postUDPHeader": API_BASE_URL + "pipeline/header/param1",
    "updateUDHeader": API_BASE_URL + "pipeline/header/param1",
    "deleteUDPHeader": API_BASE_URL + "pipeline/header/param1",
    "getUDPEntity": API_BASE_URL + "pipeline/param1/entity",
    "postUDPEntity": API_BASE_URL + "pipeline/param1/entity",
    "updateUDEntity": API_BASE_URL + "pipeline/entity/param1",
    "deleteUDPEntity": API_BASE_URL + "pipeline/entity/param1",
    "getUDPSource": API_BASE_URL + "pipeline/param1/source",
    "getUDPTarget": API_BASE_URL + "pipeline/param1/target",
    "updateUDPTarget": API_BASE_URL + "pipeline/target/param1",
    "getUDPSQL":  API_BASE_URL + "pipeline/sql/param1",
    "postUDPSQL":  API_BASE_URL + "pipeline/param1/sql",
    "updateUDPSQL":  API_BASE_URL + "pipeline/sql/param1",
    "getUDPSQLTree":  API_BASE_URL + "pipeline/sql-tree/param1",
    "postUDPMapping": API_BASE_URL + "pipeline/mapping/param1",
    "putUDPMapping": API_BASE_URL + "pipeline/mapping/param1",
    "getUDPMapping": API_BASE_URL + "pipeline/mapping/param1",
    "getPipelineDatasetConn": API_BASE_URL + "pipeline/dataset-conn/param1",


    //Delete after verification
    "createDataPipeline" :  API_BASE_URL + "pipeline/createDataPipeline",
    "fetchProjectTreeFolder" :  API_BASE_URL + "pipeline/fetchProjectTreeFolder",
    "fetchDataPipelineHeaderByID":  API_BASE_URL + "pipeline/fetchDataPipelineHeaderByID",
    "fetchDataPipelineHeaderByName":  API_BASE_URL + "pipeline/fetchDataPipelineHeaderByName",
    //Delete after verification


    ///business
    "getBusinessGlossary": API_BASE_URL + "business/glossary/param1",
    "postBusinessGlossary": API_BASE_URL + "business/glossary/param1",
    "putBusinessGlossary": API_BASE_URL + "business/glossary/param1",
    "getCodeSubjects": API_BASE_URL + "business/param1/subjects",
    "postCodeSubjects":API_BASE_URL + "business/param1/subjects",
    "putCodeSubjects": API_BASE_URL + "business/param1/subjects/param2",
    "deleteCodeSubjects": API_BASE_URL + "business/param1/subjects/param2",
    "getCodeTypeList": API_BASE_URL + "business/sdt/code-types/param1",
    "getCodeValues": API_BASE_URL + "business/sdt/codes",
    "getCodeValuesBySubject": API_BASE_URL + "business/sdt/codes/param1",
    "postCodeValues": API_BASE_URL + "business/sdt/codes",
    "putCodeValues": API_BASE_URL + "business/sdt/codes",
    "deleteCodeValues": API_BASE_URL + "business/sdt/codes",
    "getSDTCodeTypeList": API_BASE_URL + "business/sdt/code-types",
    "getLkpTableList":API_BASE_URL + "business/lookup-table/param1",
    "postLookupData":API_BASE_URL + "business/lookup-table/param1",
    "getLookupData":API_BASE_URL + "business/lookup-table/param1",
    "deleteLookupData":API_BASE_URL + "business/lookup-table",
    "updateLookupData":API_BASE_URL + "business/lookup-table",

    


    //Delete
    "fetchEnterpriseCodesetFolderTree" :  API_BASE_URL + "business/fetchEnterpriseCodesetFolderTree",
    "fetchCodeValues" :  API_BASE_URL + "business/fetchCodeValues",
    "fetchSourceDataTranslation" :  API_BASE_URL + "business/fetchSourceDataTranslation",
}
