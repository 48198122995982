import React from "react";
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField  from '@mui/material/TextField';
import Button from '@mui/material/Button';
import  Divider  from "@mui/material/Divider";

import { useStore } from "../../../store/useStore";


const NewDataPipeline = props => {

    const {treeViewMenusAction, updateTreeViewMenusAction, handleCancelAddERModel} = props;

    const {postUDPHeader,
    } = useStore((state)=>({
        postUDPHeader: state.postUDPHeader,
        })
    );

    const [newName, setNewName] = React.useState("")

    const handleOnChangeRename = event => {
        setNewName(event.target.value)
    };


    const handleSave = () => {
        //updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null })
        //setFolderAction(true)
        let subject_repo_id;

        if (treeViewMenusAction.clickedTree.title ==='datapipeline'){
            subject_repo_id = null
        } else {
            subject_repo_id = treeViewMenusAction.clickedTree.id.split("-")[0]
        }

        postUDPHeader( {dp_name:newName},
                                {param1:subject_repo_id}
                                )
        .then(res=>{
            handleCancelAddERModel()
            updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null })
        });

        
    };

    const handleCancel = () => {
       
        setNewName("")
        handleCancelAddERModel()
        updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null })
    };

    return (
        <Box>
            <Divider />
            <Box display={"flex"}
                sx={{margin:"10px"}}
            >
                
                <ListItemIcon sx={{marginTop:"10px", marginLeft:"10px"}}>
                    <PostAddOutlinedIcon />
                </ListItemIcon>

            
                <TextField 
                    id="outlined-basic" 
                    label="New Metadata Repository" 
                    variant="outlined" 
                    value={newName}
                    onChange={handleOnChangeRename}
                    size="medium"
                />
                <Button 
                    size="small"
                    variant="contained"
                    onClick={handleSave}
                    sx={{margin:"10px"}}
                >
                    Save
                </Button>
                <Button 
                    size="small"
                    variant="contained"
                    onClick={handleCancel}
                    sx={{margin:"10px"}}
                >
                    Cancel
                </Button>
            </Box>
            <Divider />
        </Box>
        
    )
}

export default NewDataPipeline;