import React from "react";
import SvgIcon from '@mui/material/SvgIcon';

const Logo = () => {
    return (

        <SvgIcon>
            <svg  viewBox="0 0 24 24">
                <path 
                    fill="#d32f2f"
                    d="M7.2,11.2C8.97,11.2 10.4,12.63 10.4,14.4C10.4,16.17 8.97,17.6 7.2,17.6C5.43,17.6 4,16.17 4,14.4C4,12.63 5.43,11.2 7.2,11.2M14.8,16A2,2 0 0,1 16.8,18A2,2 0 0,1 14.8,20A2,2 0 0,1 12.8,18A2,2 0 0,1 14.8,16M15.2,4A4.8,4.8 0 0,1 20,8.8C20,11.45 17.85,13.6 15.2,13.6A4.8,4.8 0 0,1 10.4,8.8C10.4,6.15 12.55,4 15.2,4Z" 
                />
            </svg>
        </SvgIcon>
    )
};

export default Logo;
  