import { lazy } from 'react';

// project imports
import Loadable from '../component/mui/Loadable';
const LoginComponent = Loadable(lazy(() => import('../component/user/Login')));



// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
   path:"/",
   element: <LoginComponent />
};

export default AuthenticationRoutes;