let now = Date.now();

export const businessGlossary =   [
              {
                "key": "glossary_id", "displayName": "Glossary Id", "order": 1
                ,"type": "datetime", "error": "", "value":"", "required": true,
                "display":false ,hidden:false,disabled:true
              },
              {
                "key": "glossary_domain", "displayName": "Glossary Domain", "order": 2
                ,"type": "datetime", "error": "", "value":"", "required": true,
                "display":true ,hidden:false,disabled:false
              },
              {
                "key": "glossary_source", "displayName": "Glossary Source", "order": 3
                ,"type": "datetime", "error": "", "value":"", "required": true,
                "display":true ,hidden:false,disabled:false
              },
              {
                "key": "glossary_name", "displayName": "Glossary Name", "order": 4
                ,"type": "datetime", "error": "", "value":"", "required": true,
                "display":true ,hidden:false,disabled:false
              },
              {
                "key": "glossary_stnd_desc", "displayName": "Glossary Standard Desc", "order": 5
                ,"type": "string", "error": "", "value":"", "required": true,
                "display":true ,hidden:false,disabled:false
              },
              {
                "key": "glossary_custom_desc", "displayName": "Glossary Custom Desc", "order": 6
                ,"type": "string", "error": "", "value":"", "required": true,
                "display":true ,hidden:false,disabled:false
              },
              {
                "key": "created_ts", "displayName": "Created Datatime", "order": 7
                ,"type": "datetime", "error": "", "value":now, "required": true,
                "display":true ,hidden:false,disabled:false
              },
              {
                "key": "updated_ts", "displayName": "Updated DateTime", "order": 8
                ,"type": "datetime", "error": "", "value":now, "required": true,
                "display":true ,hidden:false,disabled:false
              },
              {
                "key": "created_by", "displayName": "Created By", "order": 9
                ,"type": "string", "error": "", "value":"", "required": true,
                "display":true ,hidden:false,disabled:false
              },
              {
                "key": "updated_by", "displayName": "Updated By", "order": 10
                ,"type": "string", "error": "", "value":"", "required": true,
                "display":true ,hidden:false,disabled:false
              },
          ];