let now = Date.now();

export const treeViewFolderForm = [

    {
        "key": "app_folder_name", "displayName": "App Folder Name", "order": 2
        ,"type": "string", "error": "", "value":"", "required": false,
        "display":true,"hidden":false
    },
    {
        "key": "app_folder_id", "displayName": "App Folder Id", "order": 1
        ,"type": "int", "error": "", "value":"", "required": false,
        "display":false,"hidden":true
    },
  
    {
        "key": "parent_folder_id", "displayName": "Parent Folder Id", "order": 3
        ,"type": "int", "error": "", "value":0, "required": false,
        "display":false ,"hidden":true
    },
   {
        "key": "app_module_id", "displayName": "App Module Id", "order": 4
        ,"type": "int", "error": "", "value":1, "required": false,
        "display":false,"hidden":true 
    },
    {
        "key": "delete_ind", "displayName": "Delete Ind", "order": 5
        ,"type": "string", "error": "", "value":"N", "required": false,
        "display":false ,"hidden":true
    },
    {
        "key": "created_ts", "displayName": "Created Ts", "order": 6
        ,"type": "datetime", "error": "", "value":now, "required": false,
        "display":false ,"hidden":true
    },
    {
        "key": "updated_ts", "displayName": "Updated Ts", "order": 7
        ,"type": "datetime", "error": "", "value":now, "required": false,
        "display":false ,"hidden":true
    },
    {
        "key": "created_by", "displayName": "Created By", "order": 8
        ,"type": "string", "error": "", "value":'system', "required": false,
        "display":false ,"hidden":true
    },
    {
        "key": "updated_by", "displayName": "Updated By", "order": 9
        ,"type": "string", "error": "", "value":"system", "required": false,
        "display":false ,"hidden":true
    },
    
];