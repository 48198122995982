import {APIService} from "../service";
import {IconFileDatabase,IconDatabase} from '@tabler/icons-react';
import {Table,EROnetoZeroMany} from "../icons/SvgIcons"
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import util from "../util/util";


const  getERModelSubject =   (set) =>  { 

     APIService.fetchApi("GET", {},"getERModelSubjectRepo", {},set)
    .then(subjectsData=>{
        if (!subjectsData.error ) {

            APIService.fetchApi("GET", {},"getERModelHdr", {},set)
            .then(erModels=>{
                if (!erModels.error) {
                    const parentParamsER = {idKey:'subject_repo_id'
                            ,titleKey:'subject_repo_name'
                            ,type:'collapse'
                            ,icon:IconFileDatabase
                            ,url:'url'
                            ,parentIdkey:'parent_repo_id'
                            ,breadCrumb: false
                            ,contextMenu: true
                            ,treeType:"ermodel"
                            ,rows:subjectsData.result.data
                        }

                    const childParamsER = {idKey:'ermodel_id'
                            ,titleKey:'ermodel_name'
                            ,type:'item'
                            ,icon:AccountTreeOutlinedIcon
                            ,url:false
                            ,parentIdkey:'subject_repo_id'
                            ,breadCrumb: true
                            ,treeType:"ermodel"
                            ,childRows:erModels.result.data
                        } 

                    APIService.fetchApi("POST", {},"fetchMetadataFolder",{},set)
                    .then(res => {

                        if (!res.error) {
                            APIService.fetchApi("POST", {},"fetchMetadataSubjectTable",{},set)
                            .then(childRows => {
                                if (!childRows.error) {
                                    const parentParamsMetada = {idKey:'subject_repo_id'
                                                ,titleKey:'subject_repo_name'
                                                ,type:'collapse'
                                                ,icon:IconFileDatabase
                                                ,url:'url'
                                                ,parentIdkey:'parent_repo_id'
                                                ,breadCrumb: false
                                                ,contextMenu: false
                                                ,treeType:"metadata"
                                                ,rows:res.result.data
                                            }

                                    const childParamsMetadata = {idKey:'dataset_id'
                                                    ,titleKey:'dataset_name'
                                                    ,type:'item'
                                                    ,icon:Table
                                                    ,url:false
                                                    ,parentIdkey:'parent_repo_id'
                                                    ,breadCrumb: true
                                                    ,treeType:"metadata"
                                                    ,childRows:childRows.result.data
                                                } 

                                    set({
                                        sideBarERModelMenus:{items:[ 
                                                                { 
                                                                    id: 'metadata',
                                                                    title: '',
                                                                    type: 'group',
                                                                    children: [
                                                                        {
                                                                            id: 'metadata',
                                                                            title: 'Metadata',
                                                                            type: 'collapse',
                                                                            icon: IconDatabase,
                                                                            open:true,
                                                                            contextMenu:false,
                                                                            treeType:"metadata",
                                                                            children:[ ...util.treeHierarchy(parentParamsMetada,childParamsMetadata)]
                                                                        },
                                                                    ]
                                                                },
                                                                { 
                                                                    id: 'ermodels',
                                                                    title: '',
                                                                    type: 'group',
                                                                    children: [
                                                                        {
                                                                            id: 'ermodel',
                                                                            title: 'ER Model',
                                                                            type: 'collapse',
                                                                            icon: IconDatabase,
                                                                            contextMenu:true,
                                                                            treeType:"ermodel",
                                                                            children:[...util.treeHierarchy(parentParamsER,childParamsER)]
                                                                        },
                                                                    ]
                                                                },
                                                            ]
                                                        }
                                            })
                                    
                                    
                                    
                                } 
                            
                            })
                            
                        } 
                    })
                } 
            })
            
        } 
    })

}

const ermodel = (set,get) => ({
    sideBarERModelMenus:{items:[ 
                                    { 
                                        id: 'metadata',
                                        title: '',
                                        type: 'group',
                                        children: [
                                            {
                                                id: 'metadata',
                                                title: 'Metadata',
                                                type: 'collapse',
                                                icon: IconDatabase,
                                                open:true,
                                                contextMenu:false,
                                                children:[ ]
                                            },
                                        ]
                                    },
                                    { 
                                        id: 'ermodels',
                                        title: '',
                                        type: 'group',
                                        children: [
                                            {
                                                id: 'ermodel',
                                                title: 'ER Model',
                                                type: 'collapse',
                                                icon: IconDatabase,
                                                contextMenu:true,
                                                children:[]
                                            },
                                        ]
                                    },
                                ]
    },

    metadataERTemp:[],
    subjectsERModelTemp:[],

    currentModelSelect:{},

    updateCurrentModelSelect: (selected) => {
        set({currentModelSelect:selected})
    },

    generateERModelSideBarMenu: () => {

            getERModelSubject(set);
            
    },


    postERModelSubjectRepo: (input, params) => APIService.fetchApi('POST', input, 'postERModelSubjectRepo' ,params,set)
            .then(res=>{
                
                  set ({
                      errorMessage: res.errorMessage,
                      isErrored:res.error,
                      })
                  if (!res.error) {
                    
                      set((state) => ({ ...state.generateERModelSideBarMenu()
                                      }
                      ))
                  }
                  return res.error
                  }
            ),

    updateERModelSubjectRepo: (input, params) => APIService.fetchApi('PUT', input, 'updateERModelSubjectRepo' ,params,set)
                .then(res=>{
                    set ({//subjectApplsData: res.result.data,
                        errorMessage: res.errorMessage,
                        isErrored:res.error,
                        })
                    if (!res.error) {
                        set((state) => ({ ...state.generateERModelSideBarMenu()
                                        }
                        ))
                    }
                    return res.error
                    }
                ),


    deleteERModelSubjectRepo: ( params) => APIService.fetchApi('DELETE', {}, 'deleteERModelSubjectRepo' ,params,set)
                .then(res=>{
                        set ({//subjectApplsData: res.result.data,
                            errorMessage: res.errorMessage,
                            isErrored:res.error,
                            })
                        if (!res.error) {
                            set((state) => ({ ...state.generateERModelSideBarMenu()
                                            }
                            ))
                        }
                        return res.error
                    }
                ),
    
    postERModelHdr: (input, params) => APIService.fetchApi('POST', input, 'postERModelHdr' ,params,set)
            .then(res=>{
                
                  set ({
                      errorMessage: res.errorMessage,
                      isErrored:res.error,
                      })
                  if (!res.error) {
                    
                      set((state) => ({ ...state.generateERModelSideBarMenu()
                                      }
                      ))
                  }
                  return res.error
                  }
            ),

    updateERModelHdr: (input, params) => APIService.fetchApi('POST', input, 'updateERModelHdr' ,params,set)
            .then(res=>{
                
                  set ({
                      errorMessage: res.errorMessage,
                      isErrored:res.error,
                      })
                  if (!res.error) {
                    
                      set((state) => ({ ...state.generateERModelSideBarMenu()
                                      }
                      ))
                  }
                  return res.error
                  }
            ),

    deleteERModelHdr: (input, params) => APIService.fetchApi('POST', input, 'deleteERModelHdr' ,params,set)
            .then(res=>{
                
                  set ({
                      errorMessage: res.errorMessage,
                      isErrored:res.error,
                      })
                  if (!res.error) {
                    
                      set((state) => ({ ...state.generateERModelSideBarMenu()
                                      }
                      ))
                  }
                  return res.error
                  }
            ),

    getERModelDatasetSchema: (param) => APIService.fetchApi('GET', {}, 'getDatasetSchema',param,set)
            .then(datasetSchmaData=>{
                        set ({
                                 errorMessage: datasetSchmaData.errorMessage,
                                 isErrored:datasetSchmaData.error,
                                 })
                    return datasetSchmaData.result.data
                }
            ),   

    getERModelEntities: (params) => APIService.fetchApi('GET', {}, 'getERModelEntities' ,params,set)
            .then(res=>{
                
                set ({
                      errorMessage: res.errorMessage,
                      isErrored:res.error,
                      }) 
                return res
                  }
            ),

    postERModelEntity: (input, params) => APIService.fetchApi('POST', input, 'postERModelEntity' ,params,set)
            .then(res=>{
                
                set ({
                    errorMessage: res.errorMessage,
                    isErrored:res.error,
                    })
                return res
            }),   

    deleteERModelEntity: ( params) => APIService.fetchApi('DELETE', {}, 'deleteERModelEntity' ,params,set)
            .then(res=>{

                  set ({
                      errorMessage: res.errorMessage,
                      isErrored:res.error,
                      })
                  return res.error
                  }
            ),      

    getERModelEntityRelation: (params) => APIService.fetchApi('GET', {}, 'getERModelEntityRelation' ,params,set)
                            .then(res=>{
                                set ({
                                    errorMessage: res.errorMessage,
                                    isErrored:res.error,
                                    })
                                return res
                                }
                ),
    
    
    postERModelEntityRelation: (input, params) => APIService.fetchApi('POST', input, 'postERModelEntityRelation' ,params,set)
            .then(res=>{                
                set ({
                    errorMessage: res.errorMessage,
                    isErrored:res.error,
                    })
                return res
            }),   

    deleteERModelEntityRelation: ( params) => APIService.fetchApi('DELETE', {}, 'deleteERModelEntityRelation' ,params,set)
            .then(res=>{
                  set ({
                      errorMessage: res.errorMessage,
                      isErrored:res.error,
                      })
                  return res.error
                  }
            ),   

    getERModelEntityCSS: (params) => APIService.fetchApi('GET', {}, 'getERModelEntityCSS' ,params,set)
            .then(res=>{
                set ({
                    errorMessage: res.errorMessage,
                    isErrored:res.error,
                    })
                return res
                }
            ),

    postERModelEntityCSS: (input, params) => APIService.fetchApi('POST', input, 'postERModelEntityCSS' ,params,set)
            .then(res=>{                
                set ({
                    errorMessage: res.errorMessage,
                    isErrored:res.error,
                    })
                return res
            }),   

    postERModelDetail : (ermodel_id) => {
        //let abc = get().modelEntities
        //console.log(get().modelEntities)
        //console.log(get().entityRelationConnUIParams)
        //console.log(get().entityRelationConnections)
        const apiData = []
        //console.log('get().entityRelationConnUIParams',get().entityRelationConnUIParams);

        get().entityRelationConnUIParams.forEach(element => {
            
            const data = {
                ermodel_id: ermodel_id,
                entity_id: element.entity_id,
                dataset_id: element.dataset_id,
                dataset_name: element.entityName,
                gui_css: JSON.stringify({
                    ...element
                })
            };

            apiData.push(data)

        });
        
        get().postERModelEntityCSS({data:apiData}, {param1:ermodel_id})
        

    },
    
    getSavedERModelDetails: async (erModel_id) =>{
        //console.log('in getSavedERModelDetails');

        set({
            entityRelationConnUIParams: [],
            entityRelationConnections: [],
            modelEntities: []
        })
       
        await get().getERModelEntities({param1:erModel_id}).then(ent=>{

            if (ent.result.data.length>0) {

                get().getERModelEntityRelation({param1:erModel_id}).then(rel=>{

                    get().getERModelEntityCSS({param1:erModel_id}).then( async css=>{

                            const modelEntities = [];
                            const entityCSSProp = [];
                            const entityRelations = [];

                            await rel.result.data.forEach(element => {
                                entityRelations.push({entityFrom:element.entity_name_from
                                    ,entityAttributeFrom:element.entity_attribute_from    
                                    ,dataset_id_from: element.entity_id_from
                                    ,entityTo:element.entity_name_to
                                    ,entityAttributeTo:element.entity_attribute_to
                                    ,dataset_id_to:element.entity_id_to
                                    ,relationShipType:element.relation_cd
                                
                                })
                            });

                            

                            await ent.result.data.forEach( async (row, index) => {

                                let cssProp;
                                const entityCSS = css.result.data.filter(
                                    rowCss => rowCss.dataset_id === row.dataset_id)
                                    
                                if (entityCSS.length>0) {
                                    cssProp= JSON.parse(entityCSS[0].gui_css)
                                    cssProp["entity_id"] = row['entity_id']
                                    /* cssProp = JSON.parse(css.result.data.filter(
                                    rowCss => JSON.parse(rowCss.gui_css).dataset_id === row.dataset_id)[0].gui_css
                                    ); */

                                    
                                } else {
                                    cssProp = {width:0,
                                                x:index*10 + 250,
                                                y:index + 240, 
                                                computedRightValues:{ x:index + 0, y:index + 0},
                                                computedLeftValues:{x:index + 0, y:index + 0},
                                                dataset_id:row.dataset_id,
                                                entityName: "",
                                                "entity_id" : row['entity_id'],

                                    }
                                
                                }

                                entityCSSProp.push(cssProp);
                                

                                await get().getERModelDatasetSchema({param1:row.dataset_id})
                                    .then(dataset=>{

                                        
                                       
                                        
                                        const entityParams = {
                                            entityName:dataset[0].dataset_name,
                                            entity_id:row['entity_id'],
                                            dataset_id:row.dataset_id,
                                            data:dataset,
                                            width:cssProp.width,
                                            x:cssProp.x ,
                                            y:cssProp.y ,
                                            height:0,
                                            computedRightValues:cssProp.computedRightValues,
                                            computedLeftValues: cssProp.computedRightValues,
                                        };
                                        
                                       
                                        modelEntities.push(entityParams);
                                       
                                }) 

                                 set({
                                    entityRelationConnUIParams: entityCSSProp,
                                    entityRelationConnections: entityRelations,
                                    modelEntities: modelEntities
                                }) 
                            })

                        
                   
                        
                    })
    
                
                })

        }
            
        })
        
        return true
    },


    getERModelStarEntities:  (params) => APIService.fetchApi('GET', {}
                            , 'getERModelStarEntities' ,params,set
                            ).then(async res=>{
                               set({starSchemaEntities:[]})
                               
                                if (!res.error) {
                                    const entities = []
                                    //set({starEntitiesCoordinates:[]})
                                    await res.result.data.map(row=>{
                                        
                                        get().getERModelDatasetSchema({param1:row.dataset_id})
                                        .then(schema=>{
                                            
                                            const entityValues = {
                                                ermodel_id: row.ermodel_id,
                                                entity_id: row.entity_id,
                                                entity_fact_ind: row.entity_fact_ind,
                                                entity_type: row.entity_fact_ind,
                                                dataset_id: row.dataset_id,
                                                dataset_name: schema[0].dataset_name,
                                                attribute:schema,
                                            } ;

                                            entities.push(entityValues);
                                            if (entities.length === res.result.data.length ) {
                                                
                                                set({starSchemaEntities:entities})
                                            }
                                            
                                        })
                                    })

                                    
                                    
                                } else {
                                    set ({
                                        errorMessage: res.errorMessage,
                                        isErrored:res.error,
                                        }) 
                                    
                                }
                            }
                                
    ),

    postERModelStarEntity: (input, params) => APIService.fetchApi('POST', input
                                                , 'postERModelStarEntity' ,params,set
                                            ).then(res=>{
                                        
                                                set ({
                                                    errorMessage: res.errorMessage,
                                                    isErrored:res.error,
                                                    })
                                                return res
                                            }
    ),   

    deleteERModelStarEntity: ( params) => APIService.fetchApi('DELETE', {}
                                            , 'deleteERModelStarEntity' ,params,set
                                            ).then(res=>{

                                                
                                                set ({
                                                    errorMessage: res.errorMessage,
                                                    isErrored:res.error,
                                                    })
                                               
                                               
                                                
                                                return res.error
                                                }

    ),   

});

export default ermodel;



