
let now = Date.now();



export const tableForm = [

  
    {
        "key": "table_name", "displayName": "Table Name", "order": 2,
        "type": "string", "error": "", "value": "", "required": true,
        "display":true,"hidden":false
    },

    {
        "key": "table_schema", "displayName": "Table Schema", "order": 3,
        "type": "string", "error": "", "value": "", "required": true,
        "display":true,"hidden":false
    },
    {
        "key": "table_source_appl", "displayName": "Source Application", "order": 4,
        "type": "string", "error": "", "value": "", "required": true,
        "display":true,"hidden":false
    },
    {
        "key": "table_status", "displayName": "Table Status", "order": 5,
        "type": "string", "error": "", "value": "", "required": true,
        "display":true,"hidden":false
    },
    {
        "key": "table_origin_db", "displayName": "Origination Database", "order": 6,
        "type": "string", "error": "", "value": "","required": true,
        "display":true,"hidden":false
    },

    {
        "key": "table_target_db", "displayName": "Target Database", "order": 7,
        "type": "string", "error": "", "value": "", "required": true,
        "display":true,"hidden":false
    },
    {
        "key": "table_target_appl", "displayName": "Target Application", "order": 8,
        "type": "string", "error": "", "value": "active", "required": true,
        "display":true,"hidden":false
    },
    {
        "key": "status", "displayName": "Status", "order": 9,
        "type": "string", "error": "", "value":"", "required": true,
        "display":true,"hidden":false
     },
     {
        "key": "bussiness_def", "displayName": "Business Definition", "order": 10,
        "type": "string", "error": "", "value":"", "required": true,
        "display":true,"hidden":false
     },
     {
        "key": "comments", "displayName": "Comments", "order": 11,
        "type": "string", "error": "", "value":"", "required": true,
        "display":true,"hidden":false
     },
     {
        "key": "curr_ind", "displayName": "Current Ind", "order": 12,
        "type": "string", "error": "", "value":"y", "required": true,
        "display":true,"hidden":true
     },
     {
        "key": "created_ts", "displayName": "Created DateTime", "order": 13,
        "type": "string", "error": "", "value":now, "required": true,
        "display":true,"hidden":true
     },
     {
        "key": "updated_ts", "displayName": "Updated DateTime", "order": 14,
        "type": "string", "error": "", "value":now, "required": true,
        "display":true,"hidden":true
     },
     {
        "key": "created_by", "displayName": "Created By", "order": 15,
        "type": "string", "error": "", "value":"dummy", "required": true,
        "display":true,"hidden":true
     },
     {
        "key": "updated_by", "displayName": "Updated By", "order": 16,
        "type": "string", "error": "", "value":"dummy", "required": true,
        "display":true,"hidden":true
     },
     {
        "key": "project_folder_id", "displayName": "project_folder_id", "order": 17,
        "type": "string", "error": "", "value":"", "required": true,
        "display":false,"hidden":true
     },
     {
        "key": "table_id", "displayName": "Table_Id", "order": 1,
        "type": "number", "error": "", "value":"", "required": true,
        "display":false,"hidden":true
    },
     

]
