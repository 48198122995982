import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
//import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Collapse, List, ListItemButton
    , ListItemIcon, ListItemText, Typography,Box,IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// project imports
import NavItem from '../NavItem';


// assetss
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import {useStore} from "../../../../store/useStore";

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level }) => {

    const theme = useTheme();

    const { customBorderRadius,
        updateTreeViewMenusAction,
        updateAdminMenuAction,
        adminMenuActions,
    }  = useStore(
        (state) => (
        { 
            customBorderRadius: state.customBorderRadius ,
            updateTreeViewMenusAction : state.updateTreeViewMenusAction,
            updateAdminMenuAction: state.updateAdminMenuAction,
            adminMenuActions: state.adminMenuActions,
        })
    );

    
    //const [open, setOpen] = useState(menu?.open ? true: false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);

    const { pathname } = useLocation();

    const handleClick = () => {
        const currentState = open;
 
        const openedItems = [...adminMenuActions.isOpen.filter(item=>item !== menu.id) 
                            , currentState ? null : menu.id 
                            ]

        updateAdminMenuAction({ type: 'MENU_OPEN', id: openedItems }); 
        updateTreeViewMenusAction({anchorEl:null, clickedTree:menu})
        setOpen(!open);
        setSelected(!selected ? menu.id : null);
    };


    const handleCheckOpen = () => {
        //updateTreeViewMenusAction({anchorEl:null, clickedTree:menu})
        setOpen(true);
        setSelected( menu.id );
    }


    const handlemenu = (event) =>{
        //event.preventDefault();
        //setAnchorEl(event.currentTarget);
        
        updateTreeViewMenusAction({anchorEl:event.currentTarget, clickedTree:menu})
    }

    const handleAnchorEl = () =>{
        setAnchorEl(null);
    }


    const checkOpenForParent = (child, id) => {
        child.forEach((item) => {
            if (item.url === pathname) {
                setOpen(true);
                setSelected(id);
            }
        });
    };

    // menu collapse for sub-levels
    useEffect(() => {
       
        //setOpen(menu?.open ? true:  false);
        setSelected(null);
        if (adminMenuActions.isOpen.findIndex((id) => id === menu.id) > -1) {
            //handleClick()
            handleCheckOpen();
        } 

        if (menu.children) {
            menu.children.forEach((item) => {
                if (item.children?.length) {
                    checkOpenForParent(item.children, menu.id);
                }
                if (item.url === pathname) {
                    setSelected(menu.id);
                    setOpen(true);
                }
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, menu.children]);

    // menu collapse & item
    const menus = menu.children?.map((item) => {
        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={item.id} menu={item} level={level + 1} />;
            case 'item':
                return <NavItem key={item.id} item={item} level={level + 1} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const Icon = menu.icon;

    const moreIconRequired  = () => {
        if ('contextMenu' in menu) {
            return menu.contextMenu
        } else {
            return true
        }
    }

    const menuIcon = menu.icon ? (
        <Icon strokeWidth={1.5}  style={{ marginTop: 'auto', marginBottom: 'auto' }} />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: selected === menu.id ? 8 : 6,
                height: selected === menu.id ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );


    const handleDragover = (event) =>{
        event.preventDefault();
    }

    const handleOnDrop = event =>{
        console.log('dropped');
    }

    return (
        <>  
            <Box sx={{display:"flex"}}>
                <ListItemButton
                    sx={{
                        borderRadius: `${customBorderRadius}px`,
                        mb: 0.5,
                        alignItems: 'flex-start',
                        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                        py: level > 1 ? 0.5 : 1.25, //1:1.25
                        pl: `${level * 24}px`
                    }}
                    
                    selected={selected === menu.id }
                    onClick={handleClick}
                >
                    {open ? (
                        <IconChevronUp stroke={.5}  
                            style={{ marginTop: 'auto', marginBottom: 'auto',opacity:1, width:"20px",
                            marginRight:"5px"
                            }} 
                        />
                    
                    ) : (
                        <IconChevronDown stroke={.5}  
                            style={{ marginTop: 'auto'
                                , marginBottom: 'auto'
                                ,opacity:1, width:"20px",
                                marginRight:"5px"
                            }} 
                        />
                    )}
                    <ListItemIcon 
                        sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}
                        onClick={()=>{console.log('icon');}}
                    >
                        {menuIcon}
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant={selected === menu.id ? 'h5' : 'body1'} color="inherit" sx={{ my: 'auto' }}
                                onDragOver={handleDragover}
                                onDrop={handleOnDrop}
                            >
                                {menu.title}
                            </Typography>
                        }
                        secondary={
                            menu.caption && (
                                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                    {menu.caption}
                                </Typography>
                            )
                        }
                    />

                    {/* Place start of menu item*/}

                    {/* open ? (
                        <IconChevronUp stroke={.5}  
                            style={{ marginTop: 'auto', marginBottom: 'auto',opacity:.5, width:"20px" }} 
                        />
                    
                    ) : (
                        <IconChevronDown stroke={.5}  
                            style={{ marginTop: 'auto', marginBottom: 'auto',opacity:.5, width:"20px"  }} 
                        />
                    ) */}

                </ListItemButton>
                {  moreIconRequired() &&
                    (<MoreVertIcon  
                            sx={{opacity:.038
                                ,marginTop:level > 1 ? "5px" : "12px"
                                ,'&:hover':{opacity:.8}
                                ,cursor:"pointer"
                            }}
                            onClick = {handlemenu}
                    />)
                }
 
            </Box>
            <Collapse in={open} timeout="auto" unmountOnExit>
                
                <List
                    component="div"
                    disablePadding
                    sx={{
                        position: 'relative',
                        '&:after': {
                            content: "''",
                            position: 'absolute',
                            left: `${(24 * level) + 10 }px`, // ${(32 * level) ((level==1 ? 0: level-1) * 6) )
                            top: 0,
                            height: '100%',
                            width: '1px',
                            opacity: 1,
                            background: theme.palette.primary.light
                        }
                    }}
                >
                    {menus}
                </List>

            </Collapse>
        </>
    );
};

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number
};

export default NavCollapse;