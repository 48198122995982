import { regularExpData } from "../constants/appConstants";
import AuthService from "../service/AuthService";
import React from 'react';
import { EmailOutlined, Phone, CompassCalibrationOutlined } from '@mui/icons-material';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import { ListItemAvatar } from "@mui/material";
//import  XLSX  from 'xlsx';
import { read, utils, writeFile } from 'xlsx';

import { func } from "prop-types";
import { loginForm } from "../schema";

//1Mb
const fileSizeLimit = 1 * 1000 * 1000;

const logBool = true;

const util = {}


let accDetails = [
    {
        "key": "uName", "displayName": "Email", "order": 1,
        "type": "string", "error": "", "disabled": true,
        "value": "", "icon": <EmailOutlined />
    },
    { "key": "phoneNo", "displayName": "Phone No", "order": 2, "type": "string", "error": "", "value": "", "icon": <Phone /> },
    {
        "key": "otp", "displayName": "OTP", "order": 3, "type": "number",
        "error": "", "value": "", "hidden": true, "icon": <KeyboardIcon />,
        "helperText": "Email verification is mandatory, please click on 'Send OTP' to verify"
    }
]


let rootProfileData = {
    "keys": ["orgAbbreviation", "orgName", "phoneNumber2",
        "dateOfInc", "contactPerson", "address",
        "state", "country", "pinCode"],
    "displayNames": [
        { "key": "orgAbbreviation", "displayName": "Abbreviation", "order": 2, "type": "string", "disabled": true, "error": "" },
        { "key": "orgName", "displayName": "Org Name", "order": 1, "type": "string", "error": "" },
        { "key": "phoneNumber2", "displayName": "Alternate Phone No", "order": 3, "type": "number", "error": "" },
        { "key": "dateOfInc", "displayName": "Date Of Inc", "order": 4, "type": "date", "error": "" },
        { "key": "contactPerson", "displayName": "Contact Person", "order": 5, "type": "string", "error": "" },
        { "key": "state", "displayName": "State", "order": 6, "type": "string", "error": "" },
        { "key": "country", "displayName": "Country", "order": 7, "type": "string", "error": "" },
        { "key": "pinCode", "displayName": "PinCode", "order": 8, "type": "string", "error": "" },
        { "key": "address", "displayName": "Address", "order": 9, "type": "string", "multiline": true, "rowsMax": 4, "rows": 2, "error": "" },

    ]
}

util.setFormData = (obj, formType) => {
    if (formType == "profile") {
        let profileData = [...rootProfileData.displayNames];
        profileData.map(item => {
            if (obj[item.key]) item.value = obj[item.key]

        })
        return profileData;
    }
}

util.fetchSteps = async (arr) => {

    let result = arr.map(x => x.title);
    return result;

}

util.findByKey = async (arr, key) => {
    return await arr.find(item => {
        return item.key === key
    })
}
util.mergeArr = (template, arr) => {
    let result = template.map(x => Object.assign(x, arr.find(y => y.key === x.key)));
    return result;
}

util.sortArr = (arr, key) => {
    return arr.sort((a, b) => parseFloat(a[key]) - parseFloat(b[key]));
}



util.sorterFunc = (order) => {

    const descendingComparator = (a, b) => {
        if (b < a) {
          return -1;
        }
        if (b > a) {
          return 1;
        }
        return 0;
      }

    return order === 'desc'
          ? (a, b) => descendingComparator(a, b)
          : (a, b) => -descendingComparator(a, b);
    
}

util.sorterFuncObjects = (order, orderBy) => {

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
        return 0;
      }
    
   
    return order === 'desc'
          ? (a, b) => descendingComparator(a, b, orderBy)
          : (a, b) => -descendingComparator(a, b, orderBy);
    
}


  
  


util.fetchRootProfile = (profileData) => {
    let profileKeys = rootProfileData.keys;
    let result = Object.keys(profileData)
        .filter(key => profileKeys.includes(key))
        .reduce((obj, key) => {
            obj.push({ "key": key, "value": profileData[key] })
            return obj;
        }, []);
    return result;
}


util.fetchAccDetails = () => {

}

util.fetchFormData = (type) => {
    if (type === "accDetails") {
        let formData = [...accDetails];
        let userInfo = AuthService.getUserInfo();
        if (userInfo) {
            formData.map(item => {
                if (item.key === "uName") item.value = userInfo.uName.email;
                if (item.key === "phoneNo") item.value = userInfo.uName.phoneNo;
            })
        }
        return formData
    }
}

util.mergeProfileTemplate = (profileData) => {
    let profileForm = rootProfileData.displayNames;
    let x = util.mergeArr(profileForm, profileData);
    return x;

}
util.chunkArray = (chunk_size, arr) => {

    return arr.map(function (e, i) {
        return i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null;
    }).filter(function (e) { return e; });

}

util.validateFile = (file, fileSizeLimit, fileTypes) => {
    let resJson = { "status": true, "msg": "" }
    if (file == null) {
        resJson["status"] = false;
        resJson["msg"] = "Please upload file"
    }
    if (file) {
        let fileMsg = "";

        if (file.size > fileSizeLimit) fileMsg += "File cannot exceed 1 MB"
        if (fileTypes.indexOf(file.type) === -1) fileMsg += " File must be of csv format";
        if (fileMsg.length) {
            resJson["status"] = false;
            resJson["msg"] = fileMsg

        }
    }
    return resJson

}

util.validataCSVData = (obj, dataRules) => {

    let data = obj.data;
    let errors = obj.errors;
    Object.keys(data).forEach(key => {


        if (data[key] != undefined && data[key].toString().length > 0) {
            data[key] = data[key].trim()
        }
        let value = data[key];


        let fieldObj = regularExpData.find(obj => obj.name.toLowerCase() === key.toLowerCase())
        if (fieldObj && fieldObj.regExp) {
            if (value != undefined && value.toString().length > 0) {
                {
                    let status = fieldObj.regExp.test(value) ? true : false;
                    if (!status) errors.push(fieldObj.message)
                }

            }
            else {

                if (dataRules) {
                    if (dataRules.required && dataRules.required.length) {
                        if (dataRules.required.indexOf(key) > -1) {
                            if (value == undefined || (value != undefined && value.toString().trim().length === 0)) {
                                errors.push(key + " required")
                            }
                        }
                    }
                    //def values check
                }


            }
        }

    });

    return obj;
}

util.validateForm = (obj, errors) => {

    Object.keys(obj).forEach(key => {
        let value = obj[key];
        let fieldObj = regularExpData.find(obj => obj.name.toLowerCase() === key.toLowerCase())
        if (fieldObj && fieldObj.regExp) {
            let status = fieldObj.regExp.test(value) ? true : false;
            status ? errors[key] = '' : errors[key] = fieldObj.message;

        }
    });

    return errors;
};

util.verifyPassword = (obj) => {
    let passwordObj = obj.find(obj => obj.key.toLowerCase() === "password");
    let confirmPasswordObj = obj.find(obj => obj.key.toLowerCase() === "confirmpassword");
    if (passwordObj && confirmPasswordObj && passwordObj.value !== confirmPasswordObj.value) {
        obj.find(item => {
            if (item.key.toLowerCase() === "confirmpassword")
                item.error = "Reconfirm the password"
        })


    }
    return obj
};


util.validateObj = async (obj) => {
    let value = obj["value"];
    let fieldObj = regularExpData.find(item => item.name.toLowerCase() === obj["key"].toLowerCase())
    if (fieldObj && fieldObj.regExp) {
        let status = fieldObj.regExp.test(value) ? true : false;
        status ? obj['error'] = '' : obj['error'] = fieldObj.message;
    }
    return obj;
};

util.validateFormData = async (obj) => {
    obj.map(item => {
        if (item.value != undefined && item.value != null && item.type !== "number" && item.value.toString().length) {
            item.value = (typeof (item["value"]) == "string") ? item["value"].trim() : item["value"]

        }
        let value = item["value"];

        if (item.type == "number") {
            if (isNaN(item.value)) item.error = item.displayName + "required";
            else item.value = parseInt(value)
        }
        // if (item.type === "number")
        //item.value = parseInt(value);

        item.error = ""
        if (item.required) {

            if (item.value == undefined || (item.value != undefined && item.value.toString().length === 0)) {
                item["error"] = item.displayName + " required";
            }
        }



        let fieldObj = regularExpData.find(obj => obj.name.toLowerCase() === item["key"].toLowerCase())

        if (fieldObj && fieldObj.regExp) {

            if (value != undefined && value.toString().length > 0) {
                let status = fieldObj.regExp.test(value) ? true : false;

                if (status) {
                    item["error"] = "";
                    if (item.validatorFunc) {
                        let status1 = item.validatorFunc(value);
                        if (!status1) (item.validatorMsg ? item["error"] = item.validatorMsg : item["error"] = "Invalid data")

                    }

                }
                else
                    item['error'] = fieldObj.message;
            }

        }




    })

    obj = await util.verifyPassword(obj);

    return obj;
};


util.filterFormData = async (obj) => {
    let apiFormData = [...obj];
    let formData = {};
    apiFormData.map(item => {
        if (item.value != undefined && item.value != null) {

            if (item.type == "number" && isNaN(item.value)) {
            }

            else {
                let key = item["key"];
                let value = item["value"];
                formData[key] = value;
            }


        }

    })
    return formData
};


util.tableHeader = (obj) => {

    let columnObj = [...obj];

    let columnHeader = [];

    columnHeader = columnObj.filter(itm => itm.display===true).map(item => {
       return {
        key: item.key,
        label: item.displayName,
        minWidth: 50,
        maxWidth: 200,
        align: 'left',
        format: value => value.toString(),
      }
    });

    return columnHeader;

};

util.returnArrayObject = (arrayObject) =>{
    let mergedArr = [];
    console.log(arrayObject);
    arrayObject.forEach((value) => {
        mergedArr.push({ ...value })
    })
    return mergedArr;
};




util.convert_xlsx_to_json = async (e,file) => {

        
    return await new Promise((resolve, reject) => {
        //var files = e.target.files, f = files[0];
        var f = file;

        var reader = new FileReader();
    
        reader.onload = (e) => {
            var data = new Uint8Array(e.target.result);
            var workbook = read(data, {type: 'array'});
        
            const sheet_name_list = workbook.SheetNames;
        
            const xlData = utils.sheet_to_json(workbook.Sheets[sheet_name_list[0]],{ defval: "" });

            resolve( xlData);
    
        };

        reader.onerror = error => reject(error);
    
        reader.readAsArrayBuffer(f);

    })

};

util.rowsToTreeView = (obj1) => {
    
    var map = {};

    for(var i = 0; i < obj1.length; i++){

        var obj = obj1[i];

        if(!(obj.Id in map)){
            map[obj.Id] = obj;
            map[obj.Id].children = [];
        };
        
        if(typeof map[obj.Id].Name == 'undefined'){
            map[obj.Id].Id = obj.Id;
            map[obj.Id].Name = obj.Name;
            map[obj.Id].attr = obj.attr;
            map[obj.Id].Parent= obj.Parent;
        };
        
        var parent = obj.Parent || '-';
       
        if(!(parent in map)){
            map[parent] = {};
            map[parent].children = [];
        };
        
        map[parent].children.push(map[obj.Id]);
    };
    
    return map['-'] ;
};

util.userLoginID = async () => {

    let userId='';

    try {
        userId = await AuthService.getUserInfo().user_login;
    }catch (error) {
        userId = '';
    };

    return userId;
}


util.debugOutput = (value) => {
    if (logBool) {
        console.log(value);
    }
}

util.treeItemChildren = (childParam,parent_value,contextMenu,treeType)=> {

    const {idKey,titleKey,type,icon,url,parentIdkey,breadCrumb,childRows} = childParam;

    const menuItems = childRows.filter(row=>row[parentIdkey] === parent_value)
        .sort(util.sorterFuncObjects('asc',titleKey)).map(row=>{
        return {id:`${row[idKey]}-${row[titleKey]}`
        ,title:row[titleKey]
        ,type: type
        ,icon:icon
        ,url:url
        ,contextMenu:contextMenu
        ,treeType:treeType
        ,breadcrumbs: breadCrumb
        }
    })

    return menuItems;
}

util.treeHierarchy =  (parentParams,childParams) => {
    //idKey,titleKey,type,icon,url,parentIdkey,breadCrumb,rows

    const {idKey,titleKey,type,icon,url
        ,parentIdkey,breadCrumb,contextMenu,treeType,rows
    } = parentParams;
                     
    var map = {};

    const menuitem = rows.sort(util.sorterFuncObjects('asc',titleKey)).map(obj => {
        
        if(!(obj[idKey] in map)){

            let navItem = {id:`${obj[idKey]}-${obj[titleKey]}`
                        ,title:obj[titleKey]
                        ,type: type
                        ,icon:icon
                        ,url:url
                        ,contextMenu:contextMenu
                        ,treeType:treeType
                        ,breadcrumbs: breadCrumb
                        }
            map[obj[idKey]] = { ...navItem}
            map[obj[idKey]].children = [...util.treeItemChildren(childParams,obj[idKey],contextMenu,treeType)]
                  //childParams.length > 0 ? [...util.treeItemChildren(childParams,obj[idKey],contextMenu,treeType)] : [] ;
        };

                
        if(typeof map[obj[idKey]].title == 'undefined'){

            map[obj[idKey]].id =`${obj[idKey]}-${obj[titleKey]}`;
            map[obj[idKey]].title = obj[titleKey];
            map[obj[idKey]].type = type ;
            map[obj[idKey]].icon = icon;
            map[obj[idKey]].url = url;
            map[obj[idKey]].contextMenu = contextMenu;
            map[obj[idKey]].treeType = treeType;
            map[obj[idKey]].breadcrumbs = breadCrumb;
        };
        
        var parent = obj[parentIdkey] || '-';

        if(!(parent in map)){
            map[parent] = {};
            map[parent].children = [];
        };
            
        map[parent].children.push(map[obj[idKey]]);
    
    })

    return map['-'].children[0].children
    
};

util.intializeSchemaModel = schemaModel =>{
    let mergedArr = [];
    schemaModel.forEach((value) => {
      mergedArr.push({ ...value })
    })   
    return mergedArr;
}


export default util;





/* const rowsToTree = () => {
                                        
                                            var map = {};
                                            const menuitem = res.result.data.map(obj => {
                                            
                                                if(!(obj.app_folder_id in map)){
                                    
                                                    let navItem = {id:`${obj.app_folder_id}-${obj.app_folder_name}`
                                                                ,title:obj.app_folder_name
                                                                ,type:'collapse'
                                                                ,icon:IconFileDatabase
                                                                }
                                                    map[obj.app_folder_id] = { ...navItem}
                                                    map[obj.app_folder_id].children = [];
                                                };
                                    
                                                        
                                                if(typeof map[obj.app_folder_id].title == 'undefined'){
                                    
                                                    map[obj.app_folder_id].id =`${obj.app_folder_id}-${obj.app_folder_name}`
                                                    map[obj.app_folder_id].title = obj.app_folder_name
                                                    map[obj.app_folder_id].type = 'collapse'
                                                    map[obj.app_folder_id].icon = IconFileDatabase
                                                };
                                                
                                                var parent = obj.parent_folder_id || '-';
                                        
                                               
                                                if(!(parent in map)){
                                                    map[parent] = {};
                                                    map[parent].children = [];
                                                };
                                                    
                                                map[parent].children.push(map[obj.app_folder_id]);
                                            
                                            })
                                    
                                    
                                            const menus = {items:[
                                                { 
                                                id: 'catalog',
                                                title: '',
                                                type: 'group',
                                                children: [
                                                    {
                                                        id: 'metadata',
                                                        title: 'Metadata',
                                                        type: 'collapse',
                                                        icon: IconDatabase,
                                                        children:[ ...map['-'].children[0].children
                                                        ]
                                                    },
                                                ]},
                                            ]}

                        
                                    
                                            //updatesideBarMetadataMenus(menus)
                                    
                                            //return map['-'] ;
                                            return menus
                                        
                                        }; */


                                        //const menus = rowsToTree()
