import React from "react";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField  from '@mui/material/TextField';
import Button from '@mui/material/Button';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import  Divider  from "@mui/material/Divider";

import { useStore } from "../../../store/useStore";

const DeleteFolder = props => {

    const {treeViewMenusAction, updateTreeViewMenusAction, handleCanceDelete} = props;

    const {deleteCodeSubjects,
        getCodeSubjects,
        getSDTSubjects,
    } = useStore((state)=>({
        deleteCodeSubjects: state.deleteCodeSubjects,
        getCodeSubjects: state.getCodeSubjects,
        getSDTSubjects: state.getSDTSubjects,
        })
    );

    const handleSave = () => {

        const subject_repo_id = treeViewMenusAction.clickedTree.id.split("-")[0]
        const moduleType = treeViewMenusAction.clickedTree.treeType;

        deleteCodeSubjects({param1:moduleType, param2:subject_repo_id})
        .then(res=>{
            if (moduleType==='code'){
                getCodeSubjects();
            } else if(moduleType==='sdt') {
                getSDTSubjects();
            }
            handleCanceDelete();
            updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null });
            
        });

        updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null })
    }

    const handleRenameCancel = () => {
       
        handleCanceDelete()
        updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null })
    }

    return (
        <Box>
            <Divider  sx={{backgroundColor:"red"}} />
            <Box display={"flex"}
                sx={{margin:"10px"}}
            >
                
                <ListItemIcon sx={{marginTop:"13px", marginLeft:"10px"}}>
                    <DeleteForeverOutlinedIcon />
                </ListItemIcon>

                <Button 
                    size="small"
                    variant="contained"
                    onClick={handleSave}
                    sx={{margin:"10px"}}
                >
                    Ok
                </Button>

                <Button 
                    size="small"
                    variant="contained"
                    onClick={handleRenameCancel}
                    sx={{margin:"10px"}}
                >
                    Cancel
                </Button>
                
            </Box>
            <Divider />
        </Box>
    )
}

export default DeleteFolder;