import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';
import { Avatar, ButtonBase,ListItemIcon,ListItemButton } from '@mui/material';
import { IconMenu2,IconAdjustmentsHorizontal,IconGripVertical } from '@tabler/icons-react';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';



// project imports
import SubMenus from '../SubMenus';
import FolderActions from '../FolderActions';
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import MenuCard from './MenuCard';
import { drawerWidth } from '../../constants/uiConstants';

import { useStore } from '../../store/useStore';
import { NoEncryption } from '@mui/icons-material';

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const [filterKeyWord, setfilterKeyWord] = React.useState('');

    const { customBorderRadius,
        leftDrawerOpened,
        updateAdminMenuAction,
        updateAppSideBarToggle,
        appSiderBarWidth,
        updateAppSideBarWidth,
        sideBarToggleButtonVisibility,
        sideBarMenus,
     }  = useStore(
        (state) => (
                {   
                    customBorderRadius: state.customBorderRadius ,
                    leftDrawerOpened: state.appSideBarToggle,
                    updateAdminMenuAction :state.updateAdminMenuAction,
                    updateAppSideBarToggle: state.updateAppSideBarToggle,
                    appSiderBarWidth: state.appSiderBarWidth,
                    updateAppSideBarWidth: state.updateAppSideBarWidth,
                    sideBarToggleButtonVisibility:state.sideBarToggleButtonVisibility,
                    sideBarMenus:state.sideBarMenus,
                })
     );

     const applyFilter = event => {
        event.preventDefault();
     };

    const handleLeftDrawerToggle = () => {
        //updateAppSideBarToggle(!leftDrawerOpened);
       
        updateAppSideBarWidth(appSiderBarWidth===50 ? 275 :50);
        
    };



    const drawer = (
        <>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                <Box sx={{ display: 'flex', p: 2, mx: 'auto' }}>
                    <LogoSection />
                </Box>
            </Box>
            <BrowserView>
                 <PerfectScrollbar
                    component="div"
                    style={{
                        height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                          
                    }}
                > 
                    {appSiderBarWidth > 50 ? ( <MenuList />) : ''}

                    <Box sx={{position: 'fixed'
                            ,zIndex: 500
                            ,width:appSiderBarWidth - 15
                            ,bottom:0
                            ,left:0
                            ,backgroundColor:'white'
                            ,display:'flex'
                            ,marginLeft:"5px"
                            //,flexDirection:'row-reverse'
                            ,visibility:sideBarToggleButtonVisibility
                        }}
                    >
     
                        {appSiderBarWidth<100 && 
                            (<ButtonBase sx={{ borderRadius: customBorderRadius}}>
                                <Avatar
                                    variant="rounded"
                                    sx={{
                                        ...theme.typography.commonAvatar,
                                        ...theme.typography.mediumAvatar,
                                        transition: 'all .2s ease-in-out',
                                        background: theme.palette.secondary.light,
                                        color: theme.palette.secondary.dark,
                                        '&:hover': {
                                            background: theme.palette.secondary.dark,
                                            color: theme.palette.secondary.light
                                        }
                                    }}
                                    onClick={handleLeftDrawerToggle}
                                    color="inherit"
                                >
                                    <IconAdjustmentsHorizontal stroke={1.5} />
                                </Avatar>
                            </ButtonBase>)
                        }

                        { appSiderBarWidth>100 &&  
                        
                            <Avatar
                                            variant="rounded"
                                            sx={{
                                                ...theme.typography.commonAvatar,
                                                ...theme.typography.mediumAvatar,
                                                transition: 'all .2s ease-in-out',
                                                background: theme.palette.secondary.light,
                                                color: theme.palette.secondary.dark,
                                                '&:hover': {
                                                    background: theme.palette.secondary.dark,
                                                    color: theme.palette.secondary.light
                                                }
                                            }}
                                            onClick={handleLeftDrawerToggle}
                                            color="inherit"
                                        >
                                            <IconAdjustmentsHorizontal stroke={1.5} />
                                        </Avatar>   
                            }
                           {/*  (<TextField 
                                sx={{width:appSiderBarWidth - 15, backgroundColor:"#eef2f6"}}
                                variant="filled"
                                id="standard-basic"
                                label=""
                                value={filterKeyWord}
                                onChange={event => applyFilter(event)}
                                placeholder="search"
                                InputProps={{
                                    startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                ...theme.typography.commonAvatar,
                                                ...theme.typography.mediumAvatar,
                                                transition: 'all .2s ease-in-out',
                                                background: theme.palette.secondary.light,
                                                color: theme.palette.secondary.dark,
                                                '&:hover': {
                                                    background: theme.palette.secondary.dark,
                                                    color: theme.palette.secondary.light
                                                }
                                            }}
                                            onClick={handleLeftDrawerToggle}
                                            color="inherit"
                                        >
                                            <IconAdjustmentsHorizontal stroke={1.5} />
                                        </Avatar>   
                                    )
                                }}
                            />) */
                        }

                    </Box>

                    {/*appSiderBarWidth > 50  ? ( <MenuCard />) :''*/}

                 </PerfectScrollbar>
            </BrowserView>

            <SubMenus />
            <FolderActions />

        </>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }
                                ,overflow:"auto"
                                ,scroll:"auto"
                                , width: matchUpMd ? appSiderBarWidth : 'auto',
                                 '*::-webkit-scrollbar': {
                                    width: '0.8em',
                                  },
                                  '*::-webkit-scrollbar-track': {
                                    //-webkit-box-shadow': 'inset 0 0 0px white',
                                    //boxShadow:'inset 0 0 0px white'
                                    backgroundColor: 'rgba(90, 90, 90)',
                                  },
                                  '*::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'rgba(0,0,0,.1)',
                                    outline: '1px solid slategrey',
                                  }, 
                                }} 
            aria-label="mailbox folders"
        >
            <Drawer
                container={container}
                variant={matchUpMd ? 'persistent' : 'temporary'}
                anchor="left"
                open={drawerOpen}
                onClose={drawerToggle}
                
                sx={{
                    '& .MuiDrawer-paper': {
                        width: appSiderBarWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none',
                        [theme.breakpoints.up('md')]: {
                            top: '88px'
                        },
                        '&::-webkit-scrollbar-track':{
                            //boxShadow:"inset 0 0 6px rgba(0,0,0,0.00)",
                            //webkitBoxShadow:"inset 0 0 6px rgba(0,0,0,0.00)",  
                            backgroundColor:'#f1f1f1'
                            
                        }, 
                         
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>


           
        </Box>
    );
};

Sidebar.propTypes = {
    drawerOpen: PropTypes.bool,
    drawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default Sidebar;