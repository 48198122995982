
import { userForm,viewUserForm, loginForm, changePasswordForm
    ,accountDetails, emailAuthDetails, phoneAuthDetails  } from "./user";
import { tableForm,columnForm, treeViewFolderForm } from "./catalog";
//import { connectionTable } from "./connection/connection";
import { connectionAdhocForm } from "./connection/connectionAdhocForm";
import { businessGlossary } from "./business/businessGlossary";


const fetchFormByType = (type) => {


    if (type === "userForm") {
        let mergedArr = [];
        userForm.forEach((value) => {
            mergedArr.push({ ...value })
        })
        return mergedArr;
    }
    else if (type == "accountDetails") {
        let mergedArr = [];
        accountDetails.forEach((value) => {
            mergedArr.push({ ...value })
        })
        return mergedArr;
    }

    else if (type == "emailAuthDetails") {
        let mergedArr = [];
        emailAuthDetails.forEach((value) => {
            mergedArr.push({ ...value })
        })
        return mergedArr;
    }

    else if (type == "phoneAuthDetails") {
        let mergedArr = [];
        phoneAuthDetails.forEach((value) => {
            mergedArr.push({ ...value })
        })
        return mergedArr;
    }

    else if (type === "viewUserForm") {
        let mergedArr = [];
        viewUserForm.forEach((value) => {
            mergedArr.push({ ...value })
        })
        return mergedArr;
    }


    if (type === "tableForm") {
    
        let mergedArr = [];
        tableForm.forEach((value) => {
            mergedArr.push({ ...value })
        })
        
        return mergedArr;
    }

    if (type === "columnForm") {
    
        let mergedArr = [];
        columnForm.forEach((value) => {
            mergedArr.push({ ...value })
        })
        
        return mergedArr;
    }

    if (type === "treeViewFolder") {
    
        let mergedArr = [];
        treeViewFolderForm.forEach((value) => {
            mergedArr.push({ ...value })
        })
        
        return mergedArr;
    }

    /* if (type === "connection") {
    
        let mergedArr = [];
        connectionTable.forEach((value) => {
            mergedArr.push({ ...value })
        })
        
        return mergedArr;
    } */

    if (type === "connectionAdhocForm") {
    
        let mergedArr = [];
        connectionAdhocForm.forEach((value) => {
            mergedArr.push({ ...value })
        })
        
        return mergedArr;
    };

    if (type === "businessGlossary") {
        
        
        let mergedArr = [];
        businessGlossary.forEach((value) => {
            mergedArr.push({ ...value })
        })
        
        return mergedArr;
    };

    
}


export { loginForm, changePasswordForm,fetchFormByType,userForm
    ,tableForm,columnForm,treeViewFolderForm
    
}
