import React from "react";
import Delete from "./Delete";
import Rename from "./Rename";

import { useStore } from "../../store/useStore";

const FolderActions = () =>{

    const {folderMenuAction
        ,updateFolderMenuAction
        ,updateSubjectFolderList 
        , } = useStore((state)=>(
                {
                    folderMenuAction: state.folderMenuAction,
                    updateFolderMenuAction: state.updateFolderMenuAction,
                    updateSubjectFolderList:state.updateSubjectFolderList,
                }
    ))


    const folderAction = () =>{
        let returnVal = (<Rename 
                            open={folderMenuAction.dialog} 
                            handleFolderActionClose={updateFolderMenuAction} 
                            updateSubjectFolderList={updateSubjectFolderList}
                            name={folderMenuAction.treeData?.title}
                        />
                        ) ;

        if (folderMenuAction.content.actionType==='delete') {
            returnVal= (<Delete 
                            open={folderMenuAction.dialog} 
                            handleFolderActionClose={updateFolderMenuAction}
                        />);
        } else if(folderMenuAction.content.actionType==='rename') {
            returnVal = (<Rename 
                open={folderMenuAction.dialog} 
                handleFolderActionClose={updateFolderMenuAction} 
                updateSubjectFolderList={updateSubjectFolderList}
                name={folderMenuAction.treeData?.title}
            />
            )
        }

        return returnVal;
    }

    return (
        <>
            {folderAction()}
        </>
    )

}

export default FolderActions;