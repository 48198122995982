import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import  Alert  from '@mui/material/Alert';

const  Delete = props => {

    const { open,handleFolderActionClose } = props;

    const handleClose= () => {
        
        handleFolderActionClose({type:null, dialog:false, content:{}});
    };

    return (
        
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                
                    <Alert severity="error">
                    {"Delete Action"}
                    </Alert>
                </DialogTitle>
                <DialogContent>
                    
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want delete the folder
                        </DialogContentText>
                </DialogContent>
            
                <DialogActions>
                <Button onClick={handleClose} autoFocus>
                    Yes
                </Button>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
                </DialogActions>
            </Dialog>
        
        </>

    );
};


export default Delete;