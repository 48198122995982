import APIService from "./APIService";
const ANONY_USER = { "status": false, "user": null }
const VALID_USER = { "status": true, "user": null }



class AuthService {

    checkToken() {
        if (this.getToken() == null) {
            return ANONY_USER;
        }
        return VALID_USER;
    }
    checkUserInfo() {
        if (this.getUserInfo() == null) {
            return ANONY_USER;
        }
        return VALID_USER;
    }

    getUserRole() {
        if (this.getUserInfo() !== null && this.getUserInfo() !== undefined) {
            let userInfo = this.getUserInfo();
           

        }
        return undefined;
    }

    getUserId() {
        if (this.getUserInfo() !== null && this.getUserInfo() !== undefined) {
            let userInfo = this.getUserInfo();
            if (userInfo.user_id) return userInfo.user_id;

        }
        return undefined;
    }

    getUserInfo() {
        if (localStorage.getItem("userInfo") !== undefined &&
            localStorage.getItem("userInfo") !== null &&
            localStorage.getItem("userInfo") !== "undefined") {
            return JSON.parse(localStorage.getItem("userInfo"));

        }
        return null;
    }

    getUserLoginId() {
        if (this.getUserInfo() !== null && this.getUserInfo() !== undefined) {
            let userInfo = this.getUserInfo();
            if (userInfo.user_login) 
            return userInfo.user_login;
        }
        return undefined;
    }

    getUserAppRoleInfo() {

        if (localStorage.getItem("userAppRoles") !== undefined &&
            localStorage.getItem("userAppRoles") !== null &&
            localStorage.getItem("userAppRoles") !== "undefined") {
            return JSON.parse(localStorage.getItem("userAppRoles"));

        }
        return [];
    }

    checkUserAppRoleModule() {
        if (this.getUserInfo() !== null && this.getUserInfo() !== undefined) {
            let userInfo = this.getUserInfo();
            if (userInfo.user_login) 
            return userInfo.user_login;
        }
        return undefined;
    }


    setUserInfo(phoneNo) {
        if (this.getUserInfo() != null) {
            let obj = JSON.parse(localStorage.getItem("userInfo"));
            //set any parameter if any
            localStorage.setItem("userInfo", JSON.stringify(obj));
        }
    }

    getToken() {
        return localStorage.getItem("token");
    }

    getAPIKey() {
        return localStorage.getItem("api_key");
    }

    isTokenAlive() {
        
        if (this.getToken() == null || this.getUserInfo() == null) return false;

        let params = {}
        params["token"] = this.getToken();
        
        let userInfo = this.getUserInfo();
        params["userId"] = userInfo.user_id;
     
        params["writerRole"] = "self";
        params["regenerate"] = true;

        return APIService.apiCall("POST", params, "validateToken")
            .then(response => {
                if (response.status === 200) return response;
                else {

                    localStorage.clear();
                    window.location.href = '/';
                }
            })
            .then(response => response.json())
            .then(response => {
                //console.log("response .. "+JSON.stringify(response))

                if (response && response.status === "success" && response.data && response.data.token) {
                    localStorage.setItem("token", response.data.token)
                    //console.log("return true here")
                    return true;
                }
                return false;

            });

    }
}

export default new AuthService();