import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import PropTypes from 'prop-types';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';


import { useStore } from '../../../store/useStore';


const menus = [
    
        {   id:'newDataFactory',
            title: 'New Data Factory',
            icon: AddBusinessIcon,
            actionType: 'postNew'
        },
    ]


const  SubMenus = () => {

    const { treeViewSubMenu
            ,treeViewMenusAction
            ,updateTreeViewMenusAction
            ,updateFolderMenuAction
            ,updateDataFactoryMenuOpen
            } = useStore((state)=>({
        treeViewSubMenu:state.treeViewSubMenu,
        treeViewMenusAction:state.treeViewMenusAction,
        updateTreeViewMenusAction : state.updateTreeViewMenusAction,
        updateFolderMenuAction: state.updateFolderMenuAction,
        updateDataFactoryMenuOpen:state.updateDataFactoryMenuOpen,
        })
    )

    const open = Boolean(treeViewMenusAction.anchorEl);

    const [folderAction, setFolderAction]= React.useState(false) 
    
    const handleFolderAction = (actionData) => {
        
        //updateFolderMenuAction({type:actionData.actionType, dialog:true, content:actionData, treeData:treeViewMenusAction.clickedTree})
        updateTreeViewMenusAction({anchorEl:null,clickedTree:null })
        setFolderAction(true)
        updateDataFactoryMenuOpen(true)

    }

    const handleClose = () => {
        updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null, })
        
    };

    return (
        <React.Fragment>

            <Menu
                anchorEl={treeViewMenusAction.anchorEl}
                id="sub-menus"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                    elevation: 0,
                    sx: { minWidth:"100px",
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        border: '2px solid #d1c4e9',
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            borderTop: '1px solid #d1c4e9',
                            borderLeft: '1px solid #d1c4e9',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {menus.map(element => {
                    let Icon = element.icon
                    return (<MenuItem key={element.id}
                                onClick={()=>handleFolderAction(element)}>
                            <ListItemIcon key={element.id}>
                                <Icon fontSize="small" color="secondary"/>
                            </ListItemIcon>
                            {element.title}
                            </MenuItem>
                        )
                })
                }
{/*                 <Divider />
           
                <MenuItem onClick={handleClose}>
                <ListItemIcon>
                    <PersonAdd fontSize="small" />
                </ListItemIcon>
                    Add another account
                </MenuItem> */}
                
            </Menu>

        </React.Fragment>
    );
};

export default SubMenus;
