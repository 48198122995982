import PropTypes from 'prop-types';
import { forwardRef, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
//import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon
        , ListItemText, Typography, useMediaQuery,Box } from '@mui/material';

// project imports
import {useStore} from "../../../../store/useStore";

//  import { MENU_OPEN, SET_MENU } from 'store/actions';



// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
    const theme = useTheme();


    const { customBorderRadius,
            adminMenuActions,
            updateAdminMenuAction,
            updateTreeViewMenusAction,
            updateAddElementToER,
            updateSideBarNavItem,
            sideBarNavItem,
         }  = useStore(
            (state) => (
                    { 
                        customBorderRadius: state.customBorderRadius ,
                        adminMenuActions: state.adminMenuActions,
                        updateAdminMenuAction :state.updateAdminMenuAction,
                        updateTreeViewMenusAction: state.updateTreeViewMenusAction,
                        updateAddElementToER: state.updateAddElementToER,
                        updateSideBarNavItem: state.updateSideBarNavItem,
                        sideBarNavItem: state.sideBarNavItem,
                    })
    );

    

    //const customization = useSelector((state) => state.customization);
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));

    const Icon = item.icon;
    const itemIcon = item?.icon ? (
        <Icon stroke={1.5} />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: sideBarNavItem.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
                height: sideBarNavItem.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = {
        component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />)
    };

    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    if(!item.url){
        listItemProps={};
    }



    const itemHandler = (id) => {
        updateSideBarNavItem({ type: 'MENU_OPEN', id: id }); 
        //updateAdminMenuAction({ type: 'MENU_OPEN', id:id });
        if (matchesSM) updateAdminMenuAction({ type: 'SET_MENU', opened: false });
        updateTreeViewMenusAction({anchorEl:null, clickedTree:item})
    };

    const handleDragStart = (event,item) => {
        event.dataTransfer.setData("Text", event.target.id);
        updateAddElementToER( {...item, clientX:event.clientX, clientY:event.clientY})
    }

    const handleDragOver = (event) => {
        //console.log('dragging',event.clientX,event.clientY);
       // console.log(event.preventDefault()); 
       
    }

    const handleDragLeave = (event,item) => {
        //console.log('event',event);
        //console.log('item',item)
        //updateAddElementToER( {...item, clientX:event.clientX, clientY:event.clientY})
    }

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            updateAdminMenuAction({ type: 'MENU_OPEN', id: item.id });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Box sx={{display:"flex"}}>
        <ListItemButton 
           
            {...listItemProps}
            disabled={item.disabled}
            sx={{
                borderRadius: `${customBorderRadius}px`,
                mb: 0.5,
                alignItems: 'flex-start',
                backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                py: level > 1 ? 1 : 1.25,
                pl: `${level * 24}px`
            }}
            selected={sideBarNavItem.isOpen.findIndex((id) => id === item.id) > -1}
            onClick={() => itemHandler(item.id)}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant={sideBarNavItem.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'} color="inherit"
                        draggable={true} 
                        onDragStart={event=>{handleDragStart(event,item)}}
                        onDragOver={handleDragOver}
                    >
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                            {item.caption}
                        </Typography>
                    )
                }
            />
            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
        {item?.menu && (<MoreVertIcon  sx={{opacity:.038
                                    ,marginTop:level > 1 ? "5px" : "12px"
                                    ,'&:hover':{opacity:.8}
                                    ,cursor:"pointer"
                                }}
                        onClick = {()=>{}}
                        
                        />)
        }
        </Box>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;