import { Link } from 'react-router-dom';
//import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ButtonBase } from '@mui/material';

import {IconTopologyStar} from '@tabler/icons-react';


//import config from 'config';
//import Logo from 'ui-component/Logo';
//import { MENU_OPEN } from 'store/actions';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    //const defaultId = useSelector((state) => state.customization.defaultId);
    //const dispatch = useDispatch();
    return (
        <ButtonBase disableRipple 
            onClick={() => alert('todo need to write code by naga')} 
            component={Link} to={'/dashboard'}
        >
            <IconTopologyStar />
        </ButtonBase>
    );
};

export default LogoSection;