import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import TableChartIcon from '@mui/icons-material/TableChart';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import BuildIcon from '@mui/icons-material/Build';
import TimelineIcon from '@mui/icons-material/Timeline';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ComputerIcon from '@mui/icons-material/Computer';
import BlurLinearIcon from '@mui/icons-material/BlurLinear';
import GrainIcon from '@mui/icons-material/Grain';

export const subMenu1 = [
    {
        name: "Dashboard",
        icon: DashboardIcon,
        path: "/dashboard"
    },
    {
        name: "Profile",
        icon: AccountBoxIcon,
        path: "/profile"
    },
    {
        name: "Change Password",
        icon: AccountBoxIcon,
        path: "/changePassword"
    },
    {
        name: "Admin",
        icon: SupervisorAccountIcon,
        path: "/admin"
    },
    {
        name: "Catalog",
        icon: TableChartIcon,
        path: "/catalog"
    },
    {
        name: "Unified Data Pipeline",
        icon: TimelineIcon,
        path: "/dataPipelines"
    },
    {
        name: "Lineage",
        icon: LinearScaleIcon,
        path: "/lineage"
    },
    {
        name: "Application Management",
        icon: GrainIcon,
        path: "/projectMgmt"
    },
    {
        name: "Settings",
        icon: BuildIcon,
        path: "/appSettings"
    },
    {
        name: "Business Center",
        icon: BusinessCenterIcon,
        path: "/business"
    },
    {
        name: "Automation",
        icon: ComputerIcon,
        path: "/automation"
    },
    {
        name: "Job Configuration",
        icon: BlurLinearIcon,
        path: "/jobConfig"
    },

]

export const subMenu2 = [
    // {
    //     name: "Settings",
    //     icon: SettingsIcon,
    //     path: "/settings"

    // },
    // {
    //     name: "Logout",
    //     icon: ExitToAppIcon,
    //     path: "/logout"

    // }
]
