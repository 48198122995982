let now = Date.now();

export const columnForm = [

  {
    "key": "table_name", "displayName": "Table Name", "order": 3
    ,"type": "string", "error": "", "value":"", "required": true,
    "display":true,"hidden":false,disabled:false
  },
  {
    "key": "column_name", "displayName": "Column Name", "order": 4
    ,"type": "string", "error": "", "value":"", "required": true,
    "display":true ,"hidden":false,disabled:false
  },
  {
    "key": "data_type", "displayName": "Data Type", "order": 5
    ,"type": "string", "error": "", "value":"", "required": true,
    "display":true ,"hidden":false,disabled:false
  },
  {
    "key": "data_length", "displayName": "Data Length", "order": 6
    ,"type": "number", "error": "", "value":"", "required": false,
    "display":true ,"hidden":false,disabled:false
  },
  {
    "key": "data_precision", "displayName": "Data Precision", "order": 7
    ,"type": "number", "error": "", "value":"", "required": false,
    "display":true ,"hidden":false,disabled:false
  },
  {
    "key": "data_scale", "displayName": "Data Scale", "order": 8
    ,"type": "number", "error": "", "value":"", "required": false,
    "display":true ,"hidden":false,disabled:false
  },
  {
    "key": "data_nullable", "displayName": "Data Nullable", "order": 9
    ,"type": "string", "error": "", "value":"", "required": false,
    "display":true ,"hidden":false,disabled:false
  },
  {
    "key": "primary_key_ind", "displayName": "Primary Key Ind", "order": 10
    ,"type": "string", "error": "", "value":"", "required": false,
    "display":true ,"hidden":false,disabled:false
  },
  {
    "key": "foreign_key_ind", "displayName": "Foreign Key Ind", "order": 11
    ,"type": "string", "error": "", "value":"", "required": false,
    "display":true ,"hidden":false,disabled:false
  },
  {
    "key": "natural_key_ind", "displayName": "Natural Key Ind", "order": 12
    ,"type": "string", "error": "", "value":"", "required": false,
    "display":true ,"hidden":false,disabled:false
  },
  {
    "key": "column_order", "displayName": "Column Order", "order": 13
    ,"type": "number", "error": "", "value":"", "required": false,
    "display":true ,"hidden":false
  },
  {
    "key": "status", "displayName": "Status", "order": 14
    ,"type": "string", "error": "", "value":"", "required": false,
    "display":true ,"hidden":false
  },
  {
    "key": "column_source_appl", "displayName": "Column Source Appl", "order": 15
    ,"type": "string", "error": "", "value":"", "required": false,
    "display":true ,"hidden":false
  },
  {
    "key": "column_target_appl", "displayName": "Column Target Appl", "order": 16
    ,"type": "string", "error": "", "value":"", "required": false,
    "display":true ,"hidden":false
  },
  {
    "key": "business_def", "displayName": "Business Def", "order": 17
    ,"type": "string", "error": "", "value":"", "required": false,
    "display":true ,"hidden":false
  },
  {
    "key": "comments", "displayName": "Comments", "order": 18
    ,"type": "string", "error": "", "value":"", "required": false,
    "display":true ,"hidden":false
  },
  {
    "key": "curr_ind", "displayName": "Curr Ind", "order": 19
    ,"type": "string", "error": "", "value":"y", "required": false,
    "display":true,"hidden":true  
  },
  {
    "key": "created_ts", "displayName": "Created Ts", "order": 20
    ,"type": "datetime", "error": "", "value":now, "required": false,
    "display":true,"hidden":true  
  },
  {
    "key": "updated_ts", "displayName": "Updated Ts", "order": 21
    ,"type": "datetime", "error": "", "value":now, "required": false,
    "display":true,"hidden":true  
  },
  {
    "key": "created_by", "displayName": "Created By", "order": 22
    ,"type": "string", "error": "", "value":"system", "required": false,
    "display":true,"hidden":true  
  },
  {
    "key": "updated_by", "displayName": "Updated By", "order": 23
    ,"type": "string", "error": "", "value":"system", "required": true,
    "display":true,"hidden":true  
  },
  {
    "key": "column_id", "displayName": "Column Id", "order": 1
    ,"type": "int", "error": "", "value":"", "required": false,
    "display":false,"hidden":true  
  },
  {
    "key": "table_id", "displayName": "Table Id", "order": 2
    ,"type": "int", "error": "", "value":"", "required": true,
    "display":false,"hidden":true 
  },

]