import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import  Alert  from '@mui/material/Alert';
import  AlertTitle from '@mui/material/AlertTitle';


import { useStore } from '../../store/useStore';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Alerts = () => {

    const { isErrored, toggleErrorDisplay,errorMessage } = useStore((state)=>(
            {
                isErrored: state.isErrored
                ,toggleErrorDisplay: state.toggleErrorDisplay
                ,errorMessage: state.errorMessage
            })
    )

    const handleClose = () => {
        toggleErrorDisplay();
    };

    return (

        (
            <Dialog
                sx={{ '& .MuiDialog-paper':{minWidth:"500px", minHeight:"200px"}}}
                open={isErrored}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                    </Alert>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description"
                    >
                            {errorMessage}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        )
    );
};


export default Alerts;