import { lazy } from 'react';

// project imports
import MainLayout from '../component/MainLayout';
import Loadable from '../component/mui/Loadable';
import ProtectedRoute from './ProtectedRoute';



const Dashboard = Loadable(lazy(() => import('../component/Dashboard')));
const Admin = Loadable(lazy(() => import('../component/Admin/User')));
const Roles = Loadable(lazy(() => import('../component/Admin/Roles')));
const Metadata = Loadable(lazy(()=>import('../component/catalog/Metadata')));
const Connection = Loadable(lazy(()=>import('../component/catalog/Connection')));
const ERModel = Loadable(lazy(()=>import('../component/catalog/ERModel')));
const UnifiedDataPipeline = Loadable(lazy(()=>import('../component/UnifiedDataPipeline')));
const JobFlow = Loadable(lazy(()=>import('../component/JobFlow')));
const Applications = Loadable(lazy(()=>import('../component/ApplManagement')));
const Glossary = Loadable(lazy(()=>import('../component/Business/Glossary')));
const CodeStandardization = Loadable(lazy(()=>import('../component/Business/CodeStandardization')));
const LookupData = Loadable(lazy(()=>import('../component/Business/LookupData')));
const Automation = Loadable(lazy(()=>import('../component/Automation')));
const Settings = Loadable(lazy(()=>import('../component/Settings')));

//const LoginComponent = Loadable(lazy(() => import('../component/user/Login')));

/*
// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
 */


const MainRoutes = {
    path: '/gui',
    element: <MainLayout />,
    children: [
        {
            path: '/gui/dashboard',
            element: (<ProtectedRoute> <Dashboard /> </ProtectedRoute>)
        },
        {
            path: '/gui/admin/users',
            element:(<ProtectedRoute> <Admin /> </ProtectedRoute>),
        },
        {
            path: '/gui/admin/roles',
            element:(<ProtectedRoute> <Roles /> </ProtectedRoute>),
        },
        /* {
            path: '/gui/admin/roles',
            element:(<ProtectedRoute> <Roles /> </ProtectedRoute>),
            children: [
                {
                    path: '/gui/admin/active',
                    element:(<ProtectedRoute> <Admin /> </ProtectedRoute>) 
                },
                {
                    path: '/gui/admin/roles',
                    element: (<ProtectedRoute> <Roles /> </ProtectedRoute>)
                }
            ]
        }, */
        {
            path: '/gui/catalog/metadata',
            element: (<ProtectedRoute> <Metadata /> </ProtectedRoute>)
        },
        {
            path: '/gui/catalog/conn',
            element: (<ProtectedRoute> <Connection /> </ProtectedRoute>)
        },
        {
            path: '/gui/catalog/ermodel',
            element: (<ProtectedRoute> <ERModel /> </ProtectedRoute>)
        },
        {
            path: '/gui/datapipeline',
            element: (<ProtectedRoute> <UnifiedDataPipeline /> </ProtectedRoute>)
        },
        {
            path: '/gui/jobConfig',
            element: (<ProtectedRoute> <JobFlow /> </ProtectedRoute>)
        },
        {
            path: '/gui/applications',
            element: (<ProtectedRoute> <Applications /> </ProtectedRoute>)
        },
        {
            path: '/gui/business/code',
            element: (<ProtectedRoute> <CodeStandardization /> </ProtectedRoute>)
        },
        {
            path: '/gui/business/lookup-data',
            element: (<ProtectedRoute> <LookupData /> </ProtectedRoute>)
        },
        {
            path: '/gui/business/glossary',
            element: (<ProtectedRoute> <Glossary /> </ProtectedRoute>)
        },
        {
            path: '/gui/automation',
            element: (<ProtectedRoute> <Automation /> </ProtectedRoute>)
        },
        {
            path: '/gui/settings',
            element: (<ProtectedRoute> <Settings /> </ProtectedRoute>)
        },
        
    ]
};

export default MainRoutes;