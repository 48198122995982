import * as React from 'react';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';


import { useStore } from '../../../../store/useStore';
import Rename from "./Rename"
import NewFolder from './NewFolder';
import NewERModel from './NewERModel';
import DeleteFolder from './DeleteFolder';



const  FolderMenus = () => {

    const { treeViewSubMenu
            ,treeViewMenusAction
            ,updateTreeViewMenusAction
            ,updateFolderMenuAction
            ,updateDataFactoryMenuOpen
            } = useStore((state)=>({
        treeViewSubMenu:state.treeViewSubMenu,
        treeViewMenusAction:state.treeViewMenusAction,
        updateTreeViewMenusAction : state.updateTreeViewMenusAction,
        updateFolderMenuAction: state.updateFolderMenuAction,
        updateDataFactoryMenuOpen:state.updateDataFactoryMenuOpen,
        })
    )

    const open = Boolean(treeViewMenusAction.anchorEl);
    const [rename, setRename] = React.useState(false);
    const [newFolder, setNewFolder] = React.useState(false);
    const [newERModel, setNewERModel] = React.useState(false);
    const [deleteFolder, setDeleteFolder] = React.useState(false)
    

    const handleFolderAction = (actionData) => {
        
        //updateFolderMenuAction({type:actionData.actionType, dialog:true, content:actionData, treeData:treeViewMenusAction.clickedTree})
        updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null })
        updateDataFactoryMenuOpen(true)
    }


    const handleDeleteRepo = () => {
        setDeleteFolder(true)
    };

    const handleRenameRepo = () => {
        //updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null })
        //setFolderAction(true)
        setRename(true)
    };

    const handleAddFolderRepo = () => {
        setNewFolder(true);
    };

    const handleAddERModel = () => {
        setNewERModel(true);
    };

    const handleCancelAddERModel = () =>{
        setNewERModel(false);
    }

    const handleCancelRename = () =>{
        setRename(false);
    }

    const handleClose = () => {
        updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null, })
        setRename(false);
        setDeleteFolder(false);
        setNewERModel(false);
        setNewFolder(false);

    };

    return (
        <React.Fragment>

            <Menu
                anchorEl={treeViewMenusAction.anchorEl}
                id="sub-menus"
                open={open}
                onClose={handleClose}
                slotProps={{
                    elevation: 0,
                    sx: { minWidth:"100px",
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        border: '2px solid #d1c4e9',
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            borderTop: '1px solid #d1c4e9',
                            borderLeft: '1px solid #d1c4e9',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >

                {!newFolder &&
                    <MenuItem 
                        onClick={handleAddFolderRepo}
                    >
                        <ListItemIcon>
                            <CreateNewFolderOutlinedIcon />
                        </ListItemIcon>
                        New Subject Folder
                    </MenuItem>
                }

                {
                    newFolder && 
                    <NewFolder
                        treeViewMenusAction={treeViewMenusAction}
                        updateTreeViewMenusAction={updateTreeViewMenusAction} 
                        handleCancelNewFolder={()=>setNewFolder(false)}
                    />
                }

                
                { (treeViewMenusAction.clickedTree.id !=='ermodel' && !rename ) &&
                    (<MenuItem 
                        onClick={handleRenameRepo}
                    >
                        <ListItemIcon>
                            <DriveFileRenameOutlineOutlinedIcon />
                        </ListItemIcon>
                        Rename
                    </MenuItem>)
                }

                { (treeViewMenusAction.clickedTree.id !=='ermodel' && rename ) &&
                    <Rename
                        treeViewMenusAction={treeViewMenusAction}
                        updateTreeViewMenusAction={updateTreeViewMenusAction} 
                        handleCancelRename={handleCancelRename}
                    />
                }
                            
                
                 
                 { (treeViewMenusAction.clickedTree.id !=='ermodel' && !deleteFolder)   &&
                        <MenuItem 
                            onClick={handleDeleteRepo}
                        >
                            <ListItemIcon>
                                <DeleteForeverOutlinedIcon />
                            </ListItemIcon>
                            Delete
                        </MenuItem>
                }

                {(treeViewMenusAction.clickedTree.id !=='ermodel' && deleteFolder)   &&
                    <DeleteFolder 
                        treeViewMenusAction={treeViewMenusAction}
                        updateTreeViewMenusAction={updateTreeViewMenusAction} 
                        handleCanceDelete={()=>{setDeleteFolder(false)}}
                    />
                }


                {(treeViewMenusAction.clickedTree.id !=='ermodel' && !newERModel)   &&
                        <MenuItem 
                            onClick={handleAddERModel}
                        >
                            <ListItemIcon>
                                <AccountTreeOutlinedIcon />
                            </ListItemIcon>
                            New ER Model
                        </MenuItem>
                }

                {(treeViewMenusAction.clickedTree.id !=='ermodel' && newERModel)   &&
                    <NewERModel 
                        treeViewMenusAction={treeViewMenusAction}
                        updateTreeViewMenusAction={updateTreeViewMenusAction} 
                        handleCancelAddERModel={handleCancelAddERModel}
                    />
                }

            </Menu>

        </React.Fragment>
    );
};

export default FolderMenus;
