import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

const  Delete = props => {

    const { open, handleFolderActionClose,updateSubjectFolderList,name } = props;

    const handleClose = () => {        
        handleFolderActionClose({type:null, dialog:false, content:{},treeData:{}});
    };

    const [folderName, setFolderName] = React.useState(name);

    const handleChange = (event) => {
        setFolderName(event.target.value)
    }

    const handleSave = () =>{
        updateSubjectFolderList();
        handleFolderActionClose({type:null, dialog:false, content:{},treeData:{}});
    }

    return (
        
        <>
            <Dialog
                open={open }
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">                             
                    {"Rename"}
                </DialogTitle>
                <DialogContent>
                    
                    <TextField 
                        id="standard-basic"
                        label=""
                        defaultValue={name}
                        onChange={handleChange} 
                    >  {folderName} </TextField>


                </DialogContent>
            
                <DialogActions>
                <Button onClick={handleSave} autoFocus>
                    Save
                </Button>
                <Button onClick={handleClose}>
                    Cancel
                </Button>
                </DialogActions>
            </Dialog>
        
        </>

    );
};


export default Delete;