

import { AuthService } from "../../service";


let userId = AuthService.getUserId();

export const userForm = [

    // {
    //     "key": "user_id", "displayName": "User Id", "order": 1,
    //     "type": "string", "error": "", "value": "", "required": true,
    //     "display":false
    // },

    {
        "key": "first_name", "displayName": "First Name", "order": 1,
        "type": "string", "error": "", "value": "", "required": true,
        "display": true
    },

    {
        "key": "middle_name", "displayName": "Middle Name", "order": 1,
        "type": "string", "error": "", "value": "", "required": false,
        "display": true
    },

    {
        "key": "last_name", "displayName": "Last Name", "order": 2,
        "type": "string", "error": "", "value": "", "required": true,
        "display": true
    },
    {
        "key": "email", "displayName": "Email", "order": 3,
        "type": "string", "error": "", "value": "", "required": true,
        "display": true
    },
    {
        "key": "user_login", "displayName": "User Name", "order": 4,
        "type": "string", "error": "", "value": "", "required": true,
        "display": true
    },
    {
        "key": "user_pwd", "displayName": "Password", "order": 5,
        "type": "password", "error": "", "value": "", "required": true,
        "display": false
       
    },

   
    //not required
    // {
    //     "key": "curr_ind", "displayName": "Current Ind", "order": 4,
    //     "type": "string", "error": "", "value": "", "required": false,
    //     "display":false
    // },
    {
        "key": "user_status", "displayName": "User Status", "order": 4,
        "type": "hidden", "error": "", "value": "active", "required": true,
        "display": false, "hidden": true
    },
    {
        "key": "created_by", "displayName": "Created By", "order": 4,
        "type": "hidden", "error": "", "value": parseInt(userId), "required": false,
        "display": true, "hidden": true
    },
    {
         "key": "updated_by", "displayName": "Updated By", "order": 4,
         "type": "hidden", "error": "", "value": "", "required": false,
         "display":true, "hidden": true
    },
    // {
    //     "key": "created_ts", "displayName": "Created Date", "order": 4,
    //     "type": "string", "error": "", "value": "", "required": false,
    //     "display":true
    // },
    // {
    //     "key": "updated_ts", "displayName": "Updated Date", "order": 4,
    //     "type": "string", "error": "", "value": "", "required": false,
    //     "display":true
    // },


]


export const viewUserForm = [

    {
        "key": "first_name", "displayName": "First Name", "order": 1,
        "type": "string", "error": "", "value": "", "required": true,
        "display": true
    },

    {
        "key": "middle_name", "displayName": "Middle Name", "order": 2,
        "type": "string", "error": "", "value": "", "required": false,
        "display": false
    },

    {
        "key": "last_name", "displayName": "Last Name", "order": 3,
        "type": "string", "error": "", "value": "", "required": false,
        "display": true
    },
    {
        "key": "email", "displayName": "Email", "order": 4,
        "type": "string", "error": "", "value": "", "required": true,
        "display": true
    },
    {
        "key": "user_login", "displayName": "User Login Id", "order": 5,
        "type": "string", "error": "", "value": "", "required": true,
        "display": true
    },
    {
        "key": "created_by", "displayName": "Created By", "order": 6,
        "type": "hidden", "error": "", "value": parseInt(userId), "required": false,
        "display": true, "hidden": true
    },
    // {
    //     "key": "user_status", "displayName": "User Status", "order": 7,
    //     "type": "hidden", "error": "", "value": "active", "required": true,
    //     "display": false, "hidden": true
    // }
]