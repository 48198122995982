import { useMediaQuery } from 'react-responsive'
import React, { useEffect } from 'react';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

//import AppRoutes from "./routes/AppRoutes"
import AppRoutes from './routes';
//import theme from "./styles/customTheme"
import theme from './component/themes';

import {useStore} from "./store/useStore";





const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
}
const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  return isTablet ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}
// const Default = ({ children }) => {
//   const isNotMobile = useMediaQuery({ minWidth: 768 })
//   return isNotMobile ? children : null
// }

const Responsive = (props) => {

  const {customization} = useStore(
    (state) => ({
      customization:state.adminMenuActions,
    })
  )


  const [themeState, setThemeState] = React.useState(true);
  if (localStorage.getItem("theme") == null || localStorage.getItem("theme") == undefined) {
    localStorage.setItem("theme", "light");
    setThemeState(true);
  }
  const handleThemeToggle = () => {
    (localStorage.getItem("theme") == "dark") ? localStorage.setItem("theme", "light") : localStorage.setItem("theme", "dark");
    (localStorage.getItem("theme") == "dark") ? setThemeState(true) : setThemeState(false);
  };



  return (
    <div>
      <Desktop>
        <StyledEngineProvider injectFirst>
          {/*<ThemeProvider theme={theme(localStorage.getItem("theme"))}>*/}
          <ThemeProvider theme={theme(customization)}>          
            <AppRoutes type="Desktop" toggleTheme={handleThemeToggle} />
          </ThemeProvider>
        </StyledEngineProvider>

      </Desktop>
      <Tablet>
        Tablet view not supported
      </Tablet>
      <Mobile>
        Mobile view not supported
      </Mobile>

    </div>
  );

}



export default Responsive