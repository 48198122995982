//import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import Typography  from '@mui/material/Typography';


// project imports
import Breadcrumbs from '../mui/extended/Breadcrumbs';
import Header from '../Header';
import Sidebar from '../Sidebar';
//import Customization from '../Customization';
import navigation from '../../constants/menuItems';
import { drawerWidth } from '../../constants/uiConstants';
import Slider from './Slider';
import Alerts from '../Alerts';
import Loader from '../mui/Loader';

// assets
import { IconChevronRight } from '@tabler/icons-react';

import {useStore} from "../../store/useStore";

// styles
const Main = styled('main', { 
    shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px'
        }
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px'
        }
    })
}));


const UnderConstruction = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100dvw',
          position: 'fixed',
          bottom: 34,
          //top:24,
        }}
      >
       
          <ToggleButton value>
            <AutoAwesomeRoundedIcon sx={{ fontSize: '20px', mr: 1 }} />
            <Typography variant="title" color={'#2196f3'}> 
                Site Under Construction, Redesign Work In Progress
            </Typography> 
          </ToggleButton>
  
      </Box>
    );
  }

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));   
    

    const { customBorderRadius,
        leftDrawerOpened,
        updateAdminMenuAction,
        updateAppSideBarToggle,
        appSiderBarWidth,
        menuSideOpen,
        isLoading,
     }  = useStore(
        (state) => (
                { 
                    customBorderRadius: state.customBorderRadius ,
                    leftDrawerOpened: state.appSideBarToggle,
                    updateAdminMenuAction :state.updateAdminMenuAction,
                    updateAppSideBarToggle: state.updateAppSideBarToggle,
                    appSiderBarWidth: state.appSiderBarWidth,
                    menuSideOpen: state.appMenuBarAction.open,
                    isLoading: state.isLoading,
                })
    );

    //const leftDrawerOpened = useSelector((state) => state.customization.opened);
    //const dispatch = useDispatch(); 

    const handleLeftDrawerToggle = () => {
        //updateAdminMenuAction({ type: 'SET_MENU', opened: !leftDrawerOpened });
       // updateAppSideBarToggle(!leftDrawerOpened);
    };

    const handleLeftDrawerToggle1 = () => {
        //updateAdminMenuAction({ type: 'SET_MENU', opened: !leftDrawerOpened });
        
    };

    const handleMouseDown = () => {
        alert('mouse down')
    }

    return (
        <Box sx={{ display: 'flex',
                    '*::-webkit-scrollbar': {
                        width: '0.8em',
                    },
                    '*::-webkit-scrollbar-track': {
                        //-webkit-box-shadow': 'inset 0 0 0px white',
                        //boxShadow:'inset 0 0 0px white'
                        backgroundColor: "white",
                    },
                    '*::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(0,0,0,.1)',
                        outline: '1px solid slategrey',
                    }, 
                 }}
        >
            <CssBaseline />
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            {/* <Sidebar drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} /> */}
            
            
            {menuSideOpen &&  (<React.Fragment>
                                    <Sidebar 
                                        drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} 
                                        drawerToggle={handleLeftDrawerToggle1} 
                                    />
                                    {appSiderBarWidth !== 50 &&  <Slider />}
                                </React.Fragment>)
            }

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened} sx={{padding:".5px"}}>

           
                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign />
                <Outlet />
            </Main>
            <Alerts />

            {isLoading && <Loader />}
            {/*<Customization />*/}
           
        </Box>
    );
};

export default MainLayout;