import {APIService} from "../service";
//import {IconFileDatabase,IconDatabase} from '@tabler/icons-react';
import {NoteBookIcon,} from "../icons/SvgIcons"
import util from "../util/util";

export const jobFlow = (set,get) => ({
    sideBarJobFlowMenus:{ items:[
                { 
                id: 'jobs',
                title: '',
                type: 'group',
                children: [
                    {
                        id: 'job',
                        title: 'Jobs',
                        type: 'collapse',
                        icon: NoteBookIcon,
                        children:[]
                    },
                ]},
            ] },

});