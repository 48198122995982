import React from "react";
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField  from '@mui/material/TextField';
import Button from '@mui/material/Button';
import  Divider  from "@mui/material/Divider";

import { useStore } from "../../../../store/useStore";

const Rename = props => {

    const {treeViewMenusAction, updateTreeViewMenusAction, handleCancelRename} = props;

    const {updateERModelSubjectRepo,
    } = useStore((state)=>({
        updateERModelSubjectRepo: state.updateERModelSubjectRepo,
        })
    );

    const [newName, setNewName] = React.useState(treeViewMenusAction.clickedTree.title)

    const handleOnChangeRename = event => {
        setNewName(event.target.value)
    }


    const handleRenameSave = () => {
        //updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null })
        //setFolderAction(true)

        const subject_repo_id = treeViewMenusAction.clickedTree.id.split("-")[0]


        updateERModelSubjectRepo( {subject_repo_name:newName},
                                {param1:subject_repo_id}
                                )
        .then(res=>{
            handleCancelRename()
            updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null })
        });

        updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null })
    }

    const handleRenameCancel = () => {
       
        setNewName("")
        handleCancelRename()
        updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null })
    }

    return (
        <Box>
        <Divider />
        <Box display={"flex"}
            sx={{margin:"10px"}}
        >
            
            <ListItemIcon sx={{marginTop:"10px"}}>
                <DriveFileRenameOutlineOutlinedIcon />
            </ListItemIcon>

           
            <TextField 
                id="outlined-basic" 
                label="Rename" 
                variant="outlined" 
                value={newName}
                onChange={handleOnChangeRename}
                size="medium"
            />
            <Button 
                size="small"
                variant="contained"
                onClick={handleRenameSave}
                sx={{margin:"10px"}}
            >
                Save
            </Button>
            <Button 
                size="small"
                variant="contained"
                onClick={handleRenameCancel}
                sx={{margin:"10px"}}
            >
                Cancel
            </Button>
        </Box>
        <Divider />
        </Box>
        
    )
}

export default Rename;