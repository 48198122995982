export const dag = (set, get) => ({
    addElementToER:null,
    updateAddElementToER:(entity)=>{
        set({addElementToER:entity})
    },
    modelEntities:[],
    entityDropped:[],
    updateEntityDropped:(entities)=>{
        set({modelEntities:entities})
    },
    updateEntitytXY: (entities) =>{
        set({modelEntities:entities})
    },
    entityRelationConnUIParams:[],
    updateEntityRelationConnUIParams:(entityParams)=>{
        set({entityRelationConnUIParams:entityParams})
        //get().postERModelDetail(entityParams.ermodel_id)
    },
    entityRelationConnFrom:{eleFromRef:{width:0,x:0,y:0,height:0,computedRightValues:{x:0,y:0},computedLeftValues:{x:0,y:0}}, dragging:false},
    entityRelationConnTo:{eleToRef:{width:0,x:0,y:0,height:0,computedRightValues:{x:0,y:0},computedLeftValues:{x:0,y:0}}, dragging: false},
    //eleFromRef:{width:0,x:0,y:0,height:0},eleToRef:{width:0,x:0,y:0,height:0}
    updateEntityRelationConn:(relConn) => {

        if (relConn?.eleFromRef) {
            set({entityRelationConnFrom:relConn})
        } else {
            set({entityRelationConnTo:relConn})
        }
        //set({entityRelationConnUIParams:relConn})
        //set((state)=>({entityRelationConn:{...state.entityRelationConn, relConn}}))
    },
    //
    
        /* {entityFrom:null,entityAttributeFrom:null
                                ,entityTo:null,entityAttributeTo:null
                                ,relationShipType:null
                                }, */
    entityOnDragStart:null,
    entityOnDrop:null,
    updateEntityOnDragStart: (entity) => {
        set({entityOnDragStart:entity})
    },
    updateEntityOnDrop: (entity) => {
        set({entityOnDrop:entity})
    },
    entityRelationConnections: [],
    updateEntityRelationConnections: (items) => {
        set({entityRelationConnections:items})
    },

    starSchemaEntities:[],

    updateStarSchemaEntities: (entities) =>{
        set({starSchemaEntities:entities})
    },

    starEntitiesCoordinates: [],

    updatestarEntitiesCoordinates: (entitiesCoordinates) =>{
        const oldVal = get().starEntitiesCoordinates;
        set({starEntitiesCoordinates:[...oldVal,entitiesCoordinates]})
    },
    resetstarEntitiesCoordinates: () => {
        set({starEntitiesCoordinates:[]})
    },





});