// assets
import { IconKey,IconDashboard ,IconTypography
    , IconPalette, IconShadow, IconWindmill} from '@tabler/icons-react';


// constant
const icons = {
    IconKey,
    IconDashboard,
    IconTypography, IconPalette, IconShadow, IconWindmill
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

const roles = {
    id: 'Roles',
    title: 'Roles',
    caption: 'Product Roles',
    type: 'group',
    children: [
        {
            id: 'authentication',
            title: 'Authentication',
            type: 'collapse',
            icon: icons.IconKey,
            children: [
                {
                    id: 'login3',
                    title: 'Login',
                    type: 'item',
                    url: '/pages/login/login3',
                    target: true
                },
                {
                    id: 'register3',
                    title: 'Register',
                    type: 'item',
                    url: '/pages/register/register3',
                    target: true
                }
            ]
        }
    ]
};

const utilities = {
    id: 'utilities',
    title: 'Utilities',
    type: 'group',
    children: [
        {
            id: 'util-typography',
            title: 'Typography',
            type: 'item',
            url: '/utils/util-typography',
            icon: icons.IconTypography,
            breadcrumbs: false
        },
        {
            id: 'util-color',
            title: 'Color',
            type: 'item',
            url: '/utils/util-color',
            icon: icons.IconPalette,
            breadcrumbs: false
        },
        {
            id: 'util-shadow',
            title: 'Shadow',
            type: 'item',
            url: '/utils/util-shadow',
            icon: icons.IconShadow,
            breadcrumbs: false
        },
        {
            id: 'icons',
            title: 'Icons',
            type: 'collapse',
            icon: icons.IconWindmill,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'Tabler Icons',
                    type: 'item',
                    url: '/icons/tabler-icons',
                    breadcrumbs: false
                },
                {
                    id: 'material-icons',
                    title: 'Material Icons',
                    type: 'collapse',
                    icon: icons.IconWindmill,
                    //type: 'item',
                    //url: '/icons/material-icons',
                    //breadcrumbs: false
                    children: [
                        {
                            id: 'tabler-icons1',
                            title: 'Tabler Icons1',
                            type: 'item',
                            url: '/icons/tabler-icons1',
                            breadcrumbs: false
                        },
                        {
                            id: 'tabler-icons2',
                            title: 'Tabler Icons2',
                            type: 'item',
                            url: '/icons/tabler-icons2',
                            breadcrumbs: false
                        }
                    ]
                }
            ]
        }
    ]
};

const admin = [dashboard,roles,utilities]

export default admin;