import React from 'react';
import { AuthService, APIService } from "../service"
import { Route, useNavigate } from 'react-router-dom';


const ProtectedRoute = ({children}) => {

    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);

    const checkValidity = async () => {

        let res = await AuthService.isTokenAlive();

        setIsAuthenticated(res)

        if (!res){
           navigate("/")
        } 

    }

    const reRouteToLogin = () => {
          navigate("/")
    }

     React.useEffect(()=>{
        checkValidity()
    }, []) 

    return (

        (isAuthenticated ?  
             (children) : null
        )
    ) 

}

export default ProtectedRoute;