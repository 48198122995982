import {create} from 'zustand';
import {userStore} from './userStore';
import {commonStore} from './commonStore';
import { subMenuItems } from './subMenuItems';
import { catalog } from './catalog';
import { dag } from './dag';
import { datapipeline } from './datapipeline';
import { jobFlow } from './jobFlow';
import applManagement from './applManagament';
import ermodel from './ermodel';
import business from './business';
import { resetStore } from './resetStore';

export const useStore = create((...a)=>({
    ...userStore(...a),
    ...commonStore(...a),
    ...subMenuItems(...a),
    ...catalog(...a),
    ...dag(...a),
    ...datapipeline(...a),
    ...jobFlow(...a),
    ...applManagement(...a),
    ...ermodel(...a),
    ...business(...a),
    ...resetStore(...a),
}))

