import React from "react";

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { styled, useTheme } from '@mui/material/styles';

import { useStore } from "../../../store/useStore";

const SliderDiv = styled('div')(({theme}) =>({
    ...{
        width: '15px'
        ,height:'100%'
        ,userSelect:"none"
        ,WebkitUserSelect:"none"
        //zIndex:zIndex,
    }
}));

const Dragger = styled('DragIndicatorIcon',{
    shouldForwardProp : (prop) => prop != 'currentWidth' })(({theme,currentWidth}) => ({
    ...{
        position:'fixed',
        //top:'50%',
        bottom:0,
        left:`${currentWidth-5}px`,
        cursor: 'ew-resize',
        WebkitUserSelect:"none",
        userSelect:"none",
        msUserSelect:"none",
    }
}));


const Slider = () => {

    const theme = useTheme();

    const {
        appSiderBarWidth,
        updateAppSideBarWidth,
        updateSideBarToggleButtonVisibility,
     }  = useStore(
        (state) => (
                { 
                    appSiderBarWidth: state.appSiderBarWidth,
                    updateAppSideBarWidth: state.updateAppSideBarWidth,
                    updateSideBarToggleButtonVisibility : state.updateSideBarToggleButtonVisibility
                })
    );

    //const [hideDrawerWidth, setHideDrawerWidth] = React.useState(appSiderBarWidth);

    const [resizeState, setResizeState] = React.useState({
        isDragging: false,
        origin: {x:0, y:0}, 
        translation: {x:0, y:0},
        changedWidth:appSiderBarWidth,   
        zIndex:0
    });

    const prevStateValues = React.useRef(resizeState)


    const handleMouseDown = React.useCallback(({clientX, clientY}) => {

        setResizeState(resizeState => ({
          ...resizeState, 
          isDragging: true,
          origin: {x: clientX, y: clientY},
          zIndex:1201
        }));

    
    }, [resizeState.origin]);
    
        
    const handleMouseMove = React.useCallback(({clientX, clientY}) => {
           
        const translation = {x: clientX - prevStateValues.current.origin.x 
                + prevStateValues.current.translation.x,
            y: clientY - prevStateValues.current.origin.y 
                    + prevStateValues.current.translation.y
            };

        const widthDelta =   prevStateValues.current.changedWidth + (clientX - prevStateValues.current.changedWidth); 


        setResizeState(resizeState => ({
        ...resizeState,
        translation,  
        changedWidth:widthDelta,
        }));
    

        updateAppSideBarWidth(widthDelta);
        updateSideBarToggleButtonVisibility('hidden');

        if (widthDelta<=150) {
            handleMouseUp()  
            //updateAppSideBarWidth(50);
        }
        

        //setHideDrawerWidth(widthDelta)


    }, []);


    const handleMouseUp = React.useCallback(() => {  
        

        setResizeState(resizeState => ({
            ...resizeState,
            isDragging: false,  
            zIndex:0,
        }));

        updateSideBarToggleButtonVisibility('visible');
    
    }, []);

    React.useEffect( () => {

    
        prevStateValues.current = resizeState;

        if (resizeState.isDragging) {
          
          window.addEventListener('mousemove', handleMouseMove);
          window.addEventListener('mouseup', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
          
          setResizeState(resizeState => ({
            ...resizeState,
            changedWidth:prevStateValues.current.changedWidth,
          }));

          //setHideDrawerWidth(prevStateValues.current.changedWidth)
    
        };
    }, [resizeState.isDragging, handleMouseMove, handleMouseUp]);

    return (
        <>
            <div            
                        id="dragger" 
                        
                        onMouseDown={handleMouseDown}
                        style ={{
                                    width: '10px',
                                    //...(resizeState.isDragging && { width:"50px"}),
                                    //...(!resizeState.isDragging && { width:"10px"}),
                                    height:"max",
                                    cursor: 'ew-resize',
                                    padding: '4px 0 0',
                                    //borderTop: '1px solid #bdbdbd',
                                    //position: 'fixed',    
                                    top:0,
                                    left: appSiderBarWidth,
                                    bottom: 0,
                                    //zIndex: 500,
                                    WebkitUserSelect:"none",
                                    userSelect:"none",
                                    msUserSelect:"none",
                                    //backgroundColor: '#e0e0e0',   
                                    '&:hover':{
                                        backgroundColor: '#e0e0e0',    
                                    },  
                                    //backgroundColor:theme.palette.grey[100],
                                    backgroundColor:"transparent",
                                    //transform: `translate(${resizeState.translation.x}px)`,
                                   
                                    //...(resizeState.isDragging && { zIndex:1500,}),
                                    ...(!resizeState.isDragging && { zIndex:2000,}),
                                    //...(resizeState.isDragging && { opacity:0,}),
                                    //opacity:0,
                                }}             
             >
                
               {/*  <DragIndicatorIcon sx={{position:'relative',
                                        top:'50%',
                                        width:"5px",
                                        //,left:`${appSiderBarWidth-5}px`
                                        //cursor: 'ew-resize',
                                        //userSelect:"none",
                                        //WebkitUserSelect:"none",
                                        opacity:1,
                                        '&:hover':{opacity:1},
                                        //,width:'15px'
                                        //,zIndex:resizeState.zIndex
                                        //zIndex:1500,
                                        }}
                   
                />  */}
            </div>        
        </>
    )
}


export default Slider;