import React from "react";
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField  from '@mui/material/TextField';
import Button from '@mui/material/Button';
import  Divider  from "@mui/material/Divider";

import { useStore } from "../../../store/useStore";

const Rename = props => {

    const {treeViewMenusAction, updateTreeViewMenusAction, handleCancelRename} = props;

    const {putCodeSubjects,
        getCodeSubjects,
        getSDTSubjects,
    } = useStore((state)=>({
        putCodeSubjects: state.putCodeSubjects,
        getCodeSubjects: state.getCodeSubjects,
        getSDTSubjects: state.getSDTSubjects,
        })
    );

    const [newName, setNewName] = React.useState(treeViewMenusAction.clickedTree.title)

    const handleOnChangeRename = event => {
        setNewName(event.target.value)
    }


    const handleRenameSave = () => {
        //updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null })
        //setFolderAction(true)

        const subject_repo_id = treeViewMenusAction.clickedTree.id.split("-")[0];
        const moduleType = treeViewMenusAction.clickedTree.treeType;

        console.log(subject_repo_id);
        putCodeSubjects({param1:moduleType, param2:subject_repo_id},
                        {subj_name:newName},
                        )
        .then(res=>{
            
            updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null })
            if (!res.error) {
                if (moduleType==='code'){
                    getCodeSubjects();
                } else if(moduleType==='sdt') {
                    getSDTSubjects();
                }
                
            }
            
        });
        handleCancelRename();
        updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null })
    }

    const handleRenameCancel = () => {
       
        setNewName("")
        handleCancelRename()
        updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null })
    }

    return (
        <Box>
        <Divider />
        <Box display={"flex"}
            sx={{margin:"10px"}}
        >
            
            <ListItemIcon sx={{marginTop:"10px"}}>
                <DriveFileRenameOutlineOutlinedIcon />
            </ListItemIcon>

           
            <TextField 
                id="outlined-basic" 
                label="Rename" 
                variant="outlined" 
                value={newName}
                onChange={handleOnChangeRename}
                size="medium"
            />
            <Button 
                size="small"
                variant="contained"
                onClick={handleRenameSave}
                sx={{margin:"10px"}}
            >
                Save
            </Button>
            <Button 
                size="small"
                variant="contained"
                onClick={handleRenameCancel}
                sx={{margin:"10px"}}
            >
                Cancel
            </Button>
        </Box>
        <Divider />
        </Box>
        
    )
}

export default Rename;