import React, { useState } from 'react';
import {
  Container,
  Box,
  TextField,
  IconButton,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@mui/material';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/system';
//import axios from 'axios';

const ChatContainer = styled(Paper)(({ theme }) => ({
  width: '100%',
  height: '80vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
}));

const MessageList = styled(List)(({ theme }) => ({
  overflowY: 'auto',
  flexGrow: 1,
  marginBottom: theme.spacing(2),
}));

const MessageItem = styled(ListItem)(({ theme, fromUser }) => ({
  justifyContent: fromUser ? 'flex-end' : 'flex-start',
  textAlign: fromUser ? 'right' : 'left',
}));

const MessageBubble = styled(Paper)(({ theme, fromUser }) => ({
  padding: theme.spacing(1, 2),
  maxWidth: '75%',
  borderRadius: fromUser ? '15px 15px 0 15px' : '15px 15px 15px 0',
  backgroundColor: fromUser ? theme.palette.primary.main : theme.palette.grey[300],
  color: fromUser ? theme.palette.primary.contrastText : theme.palette.text.primary,
}));

const ChatInputBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.background.paper,
}));

const AIChatbox = props => {

    const {handleChatClose} = props;

    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSendMessage = async () => {
        if (inputValue.trim() === '') return;

        const userMessage = { text: inputValue, fromUser: true };
        setMessages((prevMessages) => [...prevMessages, userMessage]);

        setInputValue('');

        try {
    /*         const response = await axios.post(
                'https://api.openai.com/v1/completions',
                {
                model: 'text-davinci-003',
                prompt: inputValue,
                max_tokens: 100,
                },
                {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer YOUR_OPENAI_API_KEY`,
                }
                }
            );

            const botMessage = { text: response.data.choices[0].text.trim(), fromUser: false }; */
            const botMessage =  { text: `I'm writing code to integrate with OpenAI, I'm scheduled to complete the integration by a week.
                                        Thanks for being patience.- AI Chatbox.`
                                        , fromUser: false };
            setMessages((prevMessages) => [...prevMessages, botMessage]);
        } catch (error) {
        alert('Error making API request:', error);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
        handleSendMessage();
        }
    };

    return (
        <Container maxWidth="md" sx={{ mt: 1
                //, width:"500px", resize:"horizontal", overflow:"auto" 
                }}
        >
            <ChatContainer elevation={3} 
                    sx={{width:"500px", resize:"horizontal", overflow:"auto" 
                        , border:"1px solid"
                    }}
            >
                <Box 
                    sx={{display:"flex",
                        alignContent:"center",
                        width:"100%",
                        flexDirection:"row-reverse"
                    }}
                >   
                    <Tooltip title={"Close"}>
                        <IconButton onClick={handleChatClose}>
                            <CancelOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    
                </Box>
                <MessageList>
                {messages.map((message, index) => (
                    <MessageItem key={index} fromUser={message.fromUser}>
                    <MessageBubble fromUser={message.fromUser}>
                        <Typography>{message.text}</Typography>
                    </MessageBubble>
                    </MessageItem>
                ))}
                </MessageList>
                <ChatInputBox>
                    <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Type your message..."
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                    />
                    <IconButton color="primary" onClick={handleSendMessage}>
                        <SendIcon />
                    </IconButton>
                </ChatInputBox>
            </ChatContainer>
        </Container>
    );
};

export default AIChatbox;
