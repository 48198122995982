import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from '@mui/icons-material/Settings';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import TableChartIcon from '@mui/icons-material/TableChart';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import BuildIcon from '@mui/icons-material/Build';
import TimelineIcon from '@mui/icons-material/Timeline';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ComputerIcon from '@mui/icons-material/Computer';
import BlurLinearIcon from '@mui/icons-material/BlurLinear';
import GrainIcon from '@mui/icons-material/Grain';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';
import CableOutlinedIcon from '@mui/icons-material/CableOutlined';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { SourceRepository,DataPipelineIcon } from '../icons/SvgIcons';

export const appModules = [
    {
        id: "dashboard",
        title:"Dashboard",
        icon: DashboardIcon,
        type:"item",
        url: "/gui/dashboard"
    },
    {
        id: "admin",
        title:"Admin",
        icon: SupervisorAccountIcon,
        type:"item",
        url: "/gui/admin/users"
    },
    {
        id: "applicationMgmt",
        title:"Application Management",
        icon: GrainIcon,
        type:"item",
        url: "/gui/applications"
    },
    /* {
        id: "adminroles",
        title:"AdminRoles",
        icon: SupervisorAccountIcon,
        type:"item",
        url: "/gui/admin/roles"
    }, */
    {
        id: "catalog",
        title:"Catalog",
        icon: NewspaperIcon,
        //url: "/catalog",
        type:"collapse",
        children:[{
                id: "metadata",
                title:"Metadata Management",
                icon: TableChartIcon,
                type:"item",
                url: "/gui/catalog/metadata"
            },
            {
                id: "entitymodel",
                title:"Entity Relationship Modeling",
                icon: LanOutlinedIcon,
                type:"item",
                url: "/gui/catalog/ermodel"
            },
            {
                id: "connection",
                title:"Connection",
                icon: SourceRepository,
                type:"item",
                url: "/gui/catalog/conn"
            },
        ]
    },
     {
        id: "pipeline",
        title: "Data Pipeline",
        icon: TimelineIcon,
        type:"item",
        url: "/gui/datapipeline"
    },
  /*  {
        id: "lineage",
        title: "Lineage",
        icon: LinearScaleIcon,
        type:"item",
        url: "/gui/lineage"
    }, */
   

    {
        id: "business",
        title: "Business",
        icon: BusinessCenterIcon,
        type:"collapse",
        //url: "/gui/business",
        children:[{
                id: "codes",
                title:"Code Data Standardization",
                icon: TableChartIcon,
                type:"item",
                url: "/gui/business/code"
            },
            {
                id: "refLkpTbls",
                title:"Lookup Data Management",
                icon: LanOutlinedIcon,
                type:"item",
                url: "/gui/business/lookup-data"
            },
            {
                id: "glossary",
                title:"Glossary",
                icon: SourceRepository,
                type:"item",
                url: "/gui/business/glossary"
            },
        ]
    },

    {
        id: "job",
        title: "Job Configuration",
        icon: BlurLinearIcon,
        type:"item",
        url: "/gui/jobConfig"
    },
    {
        id: "settings",
        title:"Settings",
        icon: BuildIcon,
        type:"item",
        url: "/gui/settings"
    },
    {
        id: "automation",
        title:"Automation",
        icon: ComputerIcon,
        type:"item",
        url: "/gui/automation"
    },
]