import React from "react";
import { API_BODY_BASE, urls, API_BASE_URL } from "../constants/urlConstants";
import AuthService from "./AuthService";





class APIService {

    createBodyContent(content) {
        let bodyContent = { ...API_BODY_BASE };

        let userId = AuthService.getUserId();
        let userRole = AuthService.getUserRole();
        let userParams = {};
        userParams["accessorUserId"] = userId;
        userParams["accessorRole"] = userRole;
        userParams["writerRole"] = "self";


        let newReq = Object.assign(content, bodyContent);
        bodyContent = Object.assign(newReq, userParams);

        return bodyContent
    }



    async apiFormUpload(methodType, content, paramData, type) {
        let methodURL = urls[type]
        // let request = { ...API_BODY_BASE };
        // request.req = { ...request.req, ...paramData }

        
        content.append('req', JSON.stringify(paramData));

        return fetch(methodURL, {
            method: methodType,
            body: content,
            headers: {
                // "Content-Type": "application/x-www-form-urlencoded",
                'Authorization': 'Bearer ' + AuthService.getToken()
            },
            credentials: 'include'
        })

    }


    async apiCall(methodType, content, type, params={}) {


        let methodURLTemp = urls[type];
        const pathString =[]
        methodURLTemp.split("/").forEach(item=>{
            if(params[item]){
                pathString.push( params[item])
            } else  {
                pathString.push(item)
            }
               
        })

        const methodURL =  await pathString.join("/")

        let bodyContent = this.createBodyContent(content);

        //console.log(methodURL)
        
        //X-API-KEY:


        if (methodType === "POST" || methodType === "PUT"  || methodType === "DELETE")
            return fetch(methodURL, {
                method: methodType,
                body: JSON.stringify(bodyContent),
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + AuthService.getToken(),
                    'X-API-KEY':AuthService.getAPIKey()
                },
                credentials: 'include',
            })
        else if (methodType === "GET") {
            return fetch(methodURL, {
                method: methodType,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'Bearer ' + AuthService.getToken(),
                    'X-API-KEY':AuthService.getAPIKey()
                },
                credentials: 'include',
            })

        }


    }


    fetchApi (type, input, APIUrl, params={},set, key=null) {

        set({isLoading: true})
        
        let apiResult =  this.apiCall(type,input,APIUrl,params)
                        .then(res => res.json())
                        .then(res=> {
                            set({isLoading: false})
                            
                            if (res.status==='success') {
                                //
                                let ser = {result:res,error:false,errorMessage:""} 
                                
                                if (key) {
                                    set({[key]:res.data,error:false,errorMessage:""})
                                }
                               
                                return ser
                            } else {
                                let ser = {result:{},error:true,errorMessage:res.errors }
                                if (key) {
                                    set({[key]:[],error:true,errorMessage:res.errors})
                                }
                                
                                return ser
                            }
                            
                        })
                        .catch(error=>{
                            let ser = {result:{},error:true,errorMessage:error }
                            return ser
                        })

         return apiResult
     
     }

}

export default new APIService();