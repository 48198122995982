import React from "react";
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import TextField  from '@mui/material/TextField';
import Button from '@mui/material/Button';
import  Divider  from "@mui/material/Divider";

import { useStore } from "../../../../store/useStore";

const NewFolder = props => {

    const {treeViewMenusAction, updateTreeViewMenusAction, handleCancelNewFolder} = props;

    const {postCatalogSubjectRepo,
    } = useStore((state)=>({
        postCatalogSubjectRepo:state.postCatalogSubjectRepo,
        })
    );

    const [newName, setNewName] = React.useState("");

    const handleOnChangeRename = event => {
        setNewName(event.target.value)
    };


    const handleSave = () => {
        //updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null })
        //setFolderAction(true)
        let subject_repo_id;

        if (treeViewMenusAction.clickedTree.title ==='Metadata'){
            subject_repo_id = 1
        } else {
            subject_repo_id = treeViewMenusAction.clickedTree.id.split("-")[0]
        }
        postCatalogSubjectRepo({subject_repo_name:newName},
                                {param1:subject_repo_id}
                             ).then(res=>{
                                handleCancelNewFolder()
                                updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null })
                             })

        
    };

    const handleCancel = () => {
       
        setNewName("")
        handleCancelNewFolder()
        updateTreeViewMenusAction({...treeViewMenusAction,anchorEl:null })
    };

    return (
        <Box>
        <Divider />
        <Box display={"flex"}
            sx={{margin:"10px"}}
        >
            
            <ListItemIcon sx={{marginTop:"10px", marginLeft:"10px"}}>
                <CreateNewFolderOutlinedIcon />
            </ListItemIcon>

           
            <TextField 
                id="outlined-basic" 
                label="New Folder" 
                variant="outlined" 
                value={newName}
                onChange={handleOnChangeRename}
                size="medium"
            />
            <Button 
                size="small"
                variant="contained"
                onClick={handleSave}
                sx={{margin:"10px"}}
            >
                Save
            </Button>
            <Button 
                size="small"
                variant="contained"
                onClick={handleCancel}
                sx={{margin:"10px"}}
            >
                Cancel
            </Button>
        </Box>
        <Divider />
        </Box>
    )
}

export default NewFolder;