let accountDetails = [
    {
        "key": "uName", "displayName": "Email", "order": 1,
        "type": "string", "error": "", "disabled": true,
        "value": "", "iconKey": "email"
    },
    {
        "key": "phoneNo", "displayName": "Phone No", "order": 2, "type": "string",
        "error": "", "value": "", iconKey: "phone"
    },
    {
        "key": "otp", "displayName": "OTP", "order": 3, "type": "number",
        "error": "", "value": "", "showOnEdit": true,
        iconKey: "otp",
        "helperText": "Email verification is mandatory, please click on 'Send OTP' to verify"
    }
]


let emailAuthDetails = [
    {
        "key": "email", "displayName": "Email", "order": 1,
        "type": "string", "error": "",
        "value": "", "iconKey": "email"
    },

    {
        "key": "otp", "displayName": "OTP", "order": 3, "type": "number",
        "error": "", "value": "", "showOnEdit": true,
        iconKey: "otp",
        "helperText": "Email verification is mandatory, please click on 'Send OTP' to verify"
    }
]

let phoneAuthDetails = [

    {
        "key": "phoneNo", "displayName": "Phone No", "order": 2, "type": "string",
        "error": "", "value": "", iconKey: "phone"
    },
    {
        "key": "otp", "displayName": "OTP", "order": 3, "type": "number",
        "error": "", "value": "", "showOnEdit": true,
        iconKey: "otp",
        "helperText": "Email verification is mandatory, please click on 'Send OTP' to verify"
    }
]

export { accountDetails, emailAuthDetails, phoneAuthDetails }