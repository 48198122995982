import {APIService} from "../service";
import {IconFileDatabase,IconDatabase} from '@tabler/icons-react';  
import {Table,BusinessRulesIcon,BusinessRuleLogicIcon
    ,DataPipelineIcon,FileCodeIcon
    ,PipelineIcon
                                } from "../icons/SvgIcons"
import util from "../util/util";


export const datapipeline = (set,get) => ({
    sideBarDataPipelineMenus:{},

    updateSideBarMenus : (metadata, datapipeline) =>  {
        
        set({sideBarDataPipelineMenus: {items:[
                { 
                    id: 'pipeline',
                    title: '',
                    type: 'group',
                    children: [
                        {
                            id: 'datapipeline',
                            title: 'Unified Data Pipeline',
                            type: 'collapse',
                            contextMenu:true,
                            open:true,
                            icon: DataPipelineIcon,
                            children:[...datapipeline]
                        },
                    ]
                },
                { 
                id: 'metadata',
                title: '',
                type: 'group',
                children: [
                    {
                        id: 'metadata',
                        title: 'Metadata',
                        type: 'collapse',
                        icon: IconDatabase,
                        contextMenu:false,
                        open:true,
                        children:[...metadata]
                    },
                ]},
                
                { 
                    id: 'rules',
                    title: '',
                    type: 'group',
                    children: [
                        {
                            id: 'birules',
                            title: 'Businee Rules',
                            type: 'collapse',
                            icon: BusinessRulesIcon,
                            contextMenu:false,
                            open:true,
                            children:[
                                {
                                    id: 'common',
                                    title: 'Common',
                                    type: 'item',
                                    url: false,
                                    icon: BusinessRuleLogicIcon,
                                    breadcrumbs: false
                                },
                            ]
                        },
                    ]
                },
            ]} 
        })
    },

    getUDPSubjectRepo: () => APIService.fetchApi("GET", {},"getUDPSubjectRepo", {},set)
        .then(subjectsData => {
           
            
            if (!subjectsData.error) {
                const parentParamsER = {
                    idKey:'subject_repo_id'
                    ,titleKey:'subject_repo_name'
                    ,type:'collapse'
                    ,icon:FileCodeIcon
                    ,url:'url'
                    ,parentIdkey:'parent_repo_id'
                    ,breadCrumb: false
                    ,contextMenu: true
                    ,treeType:"datapipeline"
                    ,rows:subjectsData.result.data
                }

                return parentParamsER
            } else {
                return subjectsData.error
            }

        }),
    
    getUDPHeaderAll: () =>APIService.fetchApi("GET", {},"getUDPHeaderALL", {},set)
    .then(udpHeader => {
        if (!udpHeader.error) {
            const childParamsER = {
                idKey:'udp_id'
                ,titleKey:'dp_name'
                ,type:'item'
                ,icon:PipelineIcon
                ,url:false
                ,parentIdkey:'subject_repo_id'
                ,breadCrumb: true
                ,treeType:"datapipeline"
                ,childRows:udpHeader.result.data
            } 
            return childParamsER
        }else {
            return udpHeader.error
        }
    }),

    getMetadataRepo:() => APIService.fetchApi("POST", {},"fetchMetadataFolder", {}, set)
    .then(res => {
                if (!res.error) {
                    const parentParamsMetada = {
                        idKey:'subject_repo_id'
                        ,titleKey:'subject_repo_name'
                        ,type:'collapse'
                        ,icon:IconFileDatabase
                        ,url:'url'
                        ,parentIdkey:'parent_repo_id'
                        ,breadCrumb: false
                        ,contextMenu: false
                        ,treeType:"metadata"
                        ,rows:res.result.data
                    }

                    return parentParamsMetada

                } else {
                    return res.error
                }             
    }),
    
    getMEtadataSchemas: () => APIService.fetchApi("POST", {},"fetchMetadataSubjectTable", {}, set)
    .then(childRows => {
        if (!childRows.error) {
            const childParamsMetadata = {
                idKey:'dataset_id'
                ,titleKey:'dataset_name'
                ,type:'item'
                ,icon:Table
                ,url:false
                ,parentIdkey:'parent_repo_id'
                ,breadCrumb: true
                ,treeType:"metadata"
                ,childRows:childRows.result.data
            }
            return childParamsMetadata
        } else {
            return childRows.error
        }
        
    }),


    dpUpdatesideBarDataPipelineMenus:()=> {
        get().updateSideBarMenus([],[]);
        //console.log(get().getUDPSubjectRepo());
        get().getUDPSubjectRepo().then(udpRepo=>{
            if (udpRepo) {
                get().getUDPHeaderAll().then(udpHeaders=>{
                    if (udpHeaders) {
                       get().getMetadataRepo().then(metadataRepo=>{
                            if (metadataRepo) {
                               get().getMEtadataSchemas().then(metadata=>{
                                    if (metadata) {
                                        const metaDataTree= util.treeHierarchy(metadataRepo,metadata);
                                        const updTree= util.treeHierarchy(udpRepo,udpHeaders)
                                        //console.log(metaDataTree,updTree);
                                        get().updateSideBarMenus(metaDataTree,updTree);
                                    }
                               }) 
                            }
                       })
                    }
                })
            }
        })
    },

    currentUDPPipelineSelected :{},

    updatedCurrentPipelineSelected: (udpSelected) => {
        set({currentUDPPipelineSelected:udpSelected});
    },

    postUDPSubjectRepoNew: (repo) => APIService.fetchApi('POST', repo 
                                                        , 'getUDPSubjectRepo'
                                                        ,{},set
                        ).then(apiResult=>{
                            if (!apiResult.error) {
                                get().dpUpdatesideBarDataPipelineMenus();
                            }  else {
                                return  apiResult.error
                            }  
                            
                        }
    ),

    postUDPSubjectRepoSub: (repo,param) => APIService.fetchApi('POST', repo 
                                                        , 'postUDPSubjectRepo'
                                                        ,param,set
                        ).then(apiResult=>{
                            if (!apiResult.error) {
                                get().dpUpdatesideBarDataPipelineMenus();
                            }  else {
                                return  apiResult.error
                            }  
                            
                        }
    ),
    updateUDPSubjectRepo: (param) => APIService.fetchApi('PUT', {} 
                                                    ,'updateUDPSubjectRepo'
                                                    ,param,set
                        ).then(apiResult=>{
                            if (!apiResult.error) {
                                get().dpUpdatesideBarDataPipelineMenus();
                            }  else {
                                return  apiResult.error
                            }  
                            
                        }
    ),

    deleteUDPSubjectRepo: (param) => APIService.fetchApi('DELETE', {} 
                                                    ,'deleteUDPSubjectRepo'
                                                    ,param,set
                        ).then(apiResult=>{
                            if (!apiResult.error) {
                                get().dpUpdatesideBarDataPipelineMenus();
                            }  else {
                                return  apiResult.error
                            }  
                            
                        }
    ),
    
    udpHeader:[],
    getUDPHeader: (param) => APIService.fetchApi('GET', {} ,'getUDPHeader',param,set
                        ).then(apiResult=>{
                            if (!apiResult.error) {
                                
                                set({udpHeader:apiResult.result.data
                                    ,errorMessage: apiResult.errorMessage
                                    ,isErrored:apiResult.error
                                })
                            }  else {
                                return  apiResult.error
                            }  
                            
                        }
    ),
    
    postUDPHeader: (udfHeader,param) => APIService.fetchApi('POST', udfHeader ,'getUDPHeader',param,set
                ).then(apiResult=>{
                    if (!apiResult.error) {
                        get().dpUpdatesideBarDataPipelineMenus();
                        set({udpHeader:apiResult.result.data
                            ,errorMessage: apiResult.errorMessage
                            ,isErrored:apiResult.error
                        })
                    }  else {
                        return  apiResult
                    }  
                    
                }
    ),

    updateUDHeader: (udfHeader,param) => APIService.fetchApi('PUT', udfHeader ,'updateUDHeader',param,set
                ).then(apiResult=>{
                    if (!apiResult.error) {
                        get().dpUpdatesideBarDataPipelineMenus();
                        set({udpHeader:apiResult.result.data
                            ,errorMessage: apiResult.errorMessage
                            ,isErrored:apiResult.error
                        })
                    }  else {
                        return  apiResult.error
                    }  
                    
                }
    ),

    deleteUDPHeader: (param) => APIService.fetchApi('DELETE', {} ,'deleteUDPHeader',param,set
                    ).then(apiResult=>{
                        if (!apiResult.error) {
                            get().dpUpdatesideBarDataPipelineMenus();
                            set({udpHeader:apiResult.result.data
                                ,errorMessage: apiResult.errorMessage
                                ,isErrored:apiResult.error
                            })
                        }  else {
                            return  apiResult.error
                        }  
                        
                    }
    ),

    udpEntities:[],
    getUDPEntity: (param) => APIService.fetchApi('GET', {} ,'getUDPEntity',param,set
                        ).then(apiResult=>{
                            if (!apiResult.error) {
                                set({udpEntities:apiResult.result.data
                                    ,errorMessage: apiResult.errorMessage
                                    ,isErrored:apiResult.error
                                })
                                
                            }  else {
                                return  apiResult.error
                            }  
                            
                        }
    ),
    
    postUDPEntity: (udfHeader,param) => APIService.fetchApi('POST', udfHeader ,'postUDPEntity',param,set
                ).then(apiResult=>{
                    if (!apiResult.error) {
                        get().getUDPEntity(param);
                        /* set({udpEntities:apiResult.result.data
                            ,errorMessage: apiResult.errorMessage
                            ,isErrored:apiResult.error
                        }) */
                    }  else {
                        set({errorMessage: apiResult.errorMessage
                            ,isErrored:apiResult.error
                        })
                        return  apiResult.error
                    }  
                    
                }
    ),


    deleteUDPEntity: (udpId,param) => APIService.fetchApi('DELETE', {} ,'deleteUDPEntity',param,set
                    ).then(apiResult=>{
                        if (!apiResult.error) {
                            get().getUDPEntity({param1:udpId});                            
                        }  else {
                            set({errorMessage: apiResult.errorMessage
                                ,isErrored:apiResult.error
                            })
                            return  apiResult.error
                        }  
                        
                    }
    ),
    
    udpTarget:[],
    getUDPTarget: () => 

            APIService.fetchApi('GET', {} ,'getUDPTarget',{param1: get().currentUDPPipelineSelected.udpId},set
                            ).then(apiResult=>{
                               
                                    set({udpTarget:apiResult.result.data
                                        ,errorMessage: apiResult.errorMessage
                                        ,isErrored:apiResult.error
                                    })
                                  
                                    return apiResult
                                
                            }
    ),
    
    getUDPSource: () => APIService.fetchApi('GET', {} ,'getUDPSource',{param1: get().currentUDPPipelineSelected.udpId},set
                    ).then(apiResult=>{
                       
                            set({
                                errorMessage: apiResult.errorMessage,
                                isErrored:apiResult.error,
                            })
                          
                            return apiResult
                        
                    }
    ),


    udpSQL:[],
    getUDPSQL: () => APIService.fetchApi('GET', {} ,'getUDPSQL'
                                            ,{param1: get().currentUDPPipelineSelected.udpId},set
                                ).then(apiResult=>{
                                
                                        set({udpSQL:apiResult.result.data
                                            ,errorMessage: apiResult.errorMessage
                                            ,isErrored:apiResult.error
                                        })
                                    
                                        return apiResult
                                    
                                }
    ),

    postUDPSQL: (apiData) => APIService.fetchApi('POST', apiData ,'postUDPSQL'
                                            ,{param1: get().currentUDPPipelineSelected.udpId},set
                                ).then(apiResult=>{
                                
                                        set({udpSQL:apiResult.result.data
                                            ,errorMessage: apiResult.errorMessage
                                            ,isErrored:apiResult.error
                                        })
                                    
                                        return apiResult
                                    
                                }
    ),

    updateUDPSQL: (apiData, param) => APIService.fetchApi('PUT', apiData ,'updateUDPSQL'
                                            ,param,set
                                ).then(apiResult=>{
                                
                                        set({udpSQL:apiResult.result.data
                                            ,errorMessage: apiResult.errorMessage
                                            ,isErrored:apiResult.error
                                        })
                                    
                                        return apiResult
                                    
                                }
    ),
    
    getUDPSQLTree: () => APIService.fetchApi('GET', {} ,'getUDPSQLTree'
                                            ,{param1: get().currentUDPPipelineSelected.udpId},set
                                ).then(apiResult=>{
                                
                                        set({//udpSQL:apiResult.result.data,
                                             errorMessage: apiResult.errorMessage,
                                            isErrored:apiResult.error,
                                        })
                                    
                                        return apiResult
                                    
                                }
    ),
    udpMapping:[],
    postUDPMapping: (param) => APIService.fetchApi('POST', {} ,'postUDPMapping'
                                            ,{param1: get().currentUDPPipelineSelected.udpId},set
                                ).then(apiResult=>{
                                
                                        set({udpMapping:apiResult.result.data
                                            ,errorMessage: apiResult.errorMessage
                                            ,isErrored:apiResult.error
                                        })
                                    
                                        return apiResult
                                    
                                }
    ),
    
    getUDPMapping: () => APIService.fetchApi('GET', {} ,'getUDPMapping'
                                            ,{param1: get().currentUDPPipelineSelected.udpId},set
                                ).then(apiResult=>{
                                        set({udpMapping:apiResult.result.data
                                            ,errorMessage: apiResult.errorMessage
                                            ,isErrored:apiResult.error
                                        })
                                    
                                        return apiResult
                                    
                                }
    ),

    putUDPMapping: (apiData) => APIService.fetchApi('PUT', apiData ,'putUDPMapping'
                                            ,{param1: get().currentUDPPipelineSelected.udpId},set
                                ).then(apiResult=>{
                                        set({udpMapping:apiResult.result.data
                                            ,errorMessage: apiResult.errorMessage
                                            ,isErrored:apiResult.error
                                        })
                                    
                                        return apiResult
                                    
                                }
    ),

    sourcefieldDroppedUDP:"",

    updateSourcefieldAdded:(val)=>{
        set({sourcefieldDroppedUDP:val})
    },

    sourcesMenuActionsUDP:{
        isOpen: [], // for active default menu
        defaultId: 'default',
        fontFamily: `'Roboto', sans-serif`,
        opened: true,
        borderRadius:12
    },
  
    updatesourcesMenuActionsUDP: (action) => {
        let id;
        if (action.type==='MENU_OPEN') {
          id = action.id;
          set((state)=>({sourcesMenuActionsUDP:{...state,isOpen:[...id,]}}))
        } else if(action.type==='SET_MENU') {
          set((state)=>({sourcesMenuActionsUDP:{...state,opened:action.opened}}))
        }
    },

    sourcesMenuBarActionUDP:{module:"", open:false, sideBarMenu:"default"},

    updateSourcesMenuActionUDP : (action) => {
        set({sourcesMenuBarActionUDP:action})
      },

    sourcestreeViewMenusActionUDP:{anchorEl:null,clickedTree:{}},

    updateSourcesTreeViewMenusActionUDP: (action) => {
          set({sourcestreeViewMenusActionUDP:action})
    },

    pipelingDatasetConnData:[],
    getPipelineDatasetConn: (params)=>APIService.fetchApi('GET', {} 
                                                            ,'getPipelineDatasetConn'
                                                            ,params
                                                            ,set
                                                            ,'pipelingDatasetConnData')
                            .then(apiResult=>{
                                    return  apiResult.error
                                }
                            ),
})