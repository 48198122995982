import React from 'react';
import { Avatar, Chip, ListItemButton, ListItemIcon
    ,ListItemText, Typography, useMediaQuery, List
    ,ListItem, Drawer, Divider, Collapse,Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import { Link,useLocation } from "react-router-dom";

import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';

import { AuthService } from '../../../service';
import { subMenu1, subMenu2 } from "../../../constants/menuConstants"
import {appModules} from '../../../constants/appModules';
import LogoSection from './LogoSection';
import SitemarkIcon from '../SitemarkIcon';
import Logo from '../Logo';

//import NavCollapse from './NavCollapse';
//import NavItem from './NavItem';

import { useStore } from '../../../store/useStore';

const listItem = {
    color: '#ffffff',
    "&:hover": {
        opacity: 0.5
    }
};


const AppSideBar = props => {

    const theme = useTheme();


    const { customBorderRadius,
            //customization,
            appMenuBarAction,
            updateAppMenuBarAction,
            }  = useStore((state) => (
                    { 
                        customBorderRadius: state.customBorderRadius ,
                        //customization: state.adminMenuActions,
                        appMenuBarAction: state.appMenuBarAction,
                        updateAppMenuBarAction :state.updateAppMenuBarAction,

                    })
    );

    const [roles, setRoles] = React.useState(AuthService.getUserAppRoleInfo());


    const NavItem = (item,level) => {

        let listItemProps = {
            component: React.forwardRef((props, ref) => <Link key={item.id+level} ref={ref} {...props} to={item.url}  />)
        };

        const itemHandler = (id) => {
            let sideBarMenu="sideBarAdminMenus"
            if (id==='metadata') {
                sideBarMenu = 'sideBarMetadataMenus'
            } else if (id==='admin') {
                sideBarMenu = 'sideBarAdminMenus'
            } else {
                
            }
            //updateAppMenuBarAction({ module:id, open:true,sideBarMenu:sideBarMenu });
            props.close();
            //if (matchesSM) updateAdminMenuAction({ type: 'SET_MENU', opened: false });
        };
    
        return (
        
            <ListItemButton
                key={item.id}
                {...listItemProps}
                disabled={item.disabled}
                sx={{
                    borderRadius: `${customBorderRadius}px`,
                    mb: 0.5,
                    alignItems: 'flex-start',
                    backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                    py: level > 1 ? 1 : 1.25,
                    pl: `${level * 24}px`
                }}
                selected={appMenuBarAction.module===item.id ? true : false}
                onClick={() => itemHandler(item.id)}
            > 
            <ListItemIcon
                key={`${item.id}-icon`}
                sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}
            >
                {<item.icon />}
            </ListItemIcon>
                <ListItemText
                    key={`${item.id}-text`}
                    primary={
                        <Typography key={`${item.id}-text-primary`}
                            variant={appMenuBarAction.module===item.id  ? 'h5' : 'body1'} color="inherit"
                        >
                            {item.title}
                        </Typography>
                    }
                />
            </ListItemButton> 
            
        );
       
    };


    const NavCollapse = (menu, level, disabled) => {

        const [open, setOpen] = React.useState(false);
        const [selected, setSelected] = React.useState(null);

        const handleClick = () => {
            setOpen(!open);
            setSelected(!selected ? menu.id : null);
        };

        const { pathname } = useLocation();

        const checkOpenForParent = (child, id) => {
            child.forEach((item) => {
                if (item.url === pathname) {
                    setOpen(true);
                    setSelected(id);
                }
            });
        };


        const menus = menu.children?.map((item) => {

            switch (item.type) {
                
                case 'collapse1':
                    return  (<> </>); 
                case 'item':
                    return  (NavItem({...item,disabled:disabled},level + 1) );
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });

        return (
             <React.Fragment key={`${menu.id}-rf`} >
                <ListItemButton
                    key={menu.id}
                    sx={{
                        borderRadius: `${customBorderRadius}px`,
                        mb: 0.5,
                        alignItems: 'flex-start',
                        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                        py: level > 1 ? 1 : 1.25,
                        pl: `${level * 24}px`
                    }}
                    selected={selected === menu.id}
                    onClick={handleClick}
                >
                    <ListItemIcon 
                        key={`${menu.id}-icon`}
                        sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36 }}
                    > 
                        {<menu.icon key={`${menu.id}-iccc`} /> } 
                    </ListItemIcon>
                    <ListItemText
                        key={`${menu.id}-text`}
                        primary={
                            <Typography  key={`${menu.id}-text-primary`}
                                variant={selected === menu.id ? 'h5' : 'body1'} color="inherit" sx={{ my: 'auto' }}
                            >
                                {menu.title}
                            </Typography>
                        }
                    />


                    {open ? (
                        <IconChevronUp key={`${menu.id}-chevup`} stroke={1.5}  style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                    
                    ) : (
                        <IconChevronDown key={`${menu.id}-chevdown`} stroke={1.5}  style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                    )}
                    
                </ListItemButton>
                <Collapse key={`${menu.id}-col`} in={open} timeout="auto" unmountOnExit>
                    <List key={`${menu.id}-list`}
                        component="div"
                        disablePadding
                        sx={{
                            position: 'relative',
                            '&:after': {
                                content: "''",
                                position: 'absolute',
                                left: '32px',
                                top: 0,
                                height: '100%',
                                width: '1px',
                                opacity: 1,
                                background: theme.palette.primary.light
                            }
                        }}
                    >
                        {menus}
                    </List>
                </Collapse>
            </React.Fragment> 
           
        )
        
    }

    const appMenu = appModules.map((item, index) => {

        let access = false

        access = (roles.write[item.title]|| roles.read[item.title]  ||item.title==='Dashboard' );

        switch (item.type) {
                case 'collapse':
                    return  (NavCollapse(item,1,!access) ) ;
                           
                case 'item':
                    return  (NavItem({...item,disabled:!access},1) ) ;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        

    })


    const subMenuList = subMenu2.map((item, index) => { 
        return (
        <ListItem key={index} component={Link} 
             to={item.path} sx={{listItem}}
        >
            <ListItemIcon sx={{listItem}}>{<item.icon />}</ListItemIcon>
            <ListItemText primary={item.name} />
        </ListItem>
        )
    });

    

    
    const menuList = subMenu1.map((item, index) => {

        /* 
        let moduleAccess = [];
        roles.forEach(row=>{
            let read = row.read_access?row.read_access.split(","):[];
            let write = row.write_access?row.write_access.split(","):[];
            moduleAccess = [...moduleAccess,...read,...write];
        }); */

        const access = (roles.write[item.name]
                        || roles.read[item.name]
                        || item.name==='Dashboard'
                        || item.name==='Profile'
                        || item.name==='Change Password'
                    /* || item.name==='Settings'
                        || item.name==='Automation'
                        || item.name==='Job Configuration'
                  
                        || moduleAccess.indexOf('Admin')>-1
                      */
                        );
        

        if (access) {
            return (
                <ListItem key={index} component={Link}
                    to={item.path} sx={{listItem}}>
                    <ListItemIcon  sx={{listItem}}>
                        {<item.icon />}
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                </ListItem>
                )
        } else {
            return (
                <ListItem key={index}  disabled={!access}
                     sx={{listItem}}>
                    <ListItemIcon disabled={!access} sx={{listItem}}>
                        {<item.icon />}
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                </ListItem>
                )
        }
/*
        return (
        <ListItem key={index} component={Link} disabled={!yesOrNo}
            to={item.path} className={classes.listItem}>
            <ListItemIcon disabled={!yesOrNo} className={classes.listItem}>
                {<item.icon />}
            </ListItemIcon>
            <ListItemText primary={item.name} />
        </ListItem>
        )
*/
    })

    const drawer = () => {
        return (
            <div style={{ width:'240px'}}>
                <List>
                    {appMenu}
                </List>
                <Divider sx={{backgroundColor: '#ffffff'}}/>
                <List>
                    {subMenuList}
                </List>

            </div>
        )
    }

    React.useEffect(()=>{
        //console.log('roleseff',AuthService.getUserAppRoleInfo());
        //setRoles(AuthService.getUserAppRoleInfo());
    },[]);

    return(
        <Drawer
            variant="temporary"
            open={props.open}
            onClose={props.close}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
                '& .MuiDrawer-paper': {
                    //background: '#e0e0e0', //theme.palette.primary.light,
                    color: theme.palette.text.primary,
                    borderRight: '5px solid #d1c4e9',
                    borderTop: '1px solid #d1c4e9',
                    [theme.breakpoints.up('md')]: {
                        top: '0px'
                    } 
                }
            }}
        >   
            <Box 
                sx={{display:"flex"
                    , margin:"10px"
                    ,flex:"row"
                    //,alignItems:'normal'
                    ,alignContent:"center"
                    ,marginLeft:"30px"
                }}
            >
                <IconButton
                    color="inherit"
                    href="https://www.guvaidata.com/"
                    aria-label="Site"
                    sx={{ alignSelf: 'center' }}
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                  
                
                <Logo />
                <SitemarkIcon />
                </IconButton>
            </Box>
            
            {/* <div  style={{textAlign:'center',marginTop:'10px'}}>
                
                <Typography>
                    GUVAAI
                </Typography>

            </div> */}
            {drawer()}

        </Drawer>
    )
};


export default AppSideBar;
