import {APIService} from "../service";
//import {IconFileDatabase,IconDatabase} from '@tabler/icons-react';
import {DataWarehouseHub,DataHubFactory} from "../icons/SvgIcons"
import util from "../util/util";


//import SubMenus from "../component/SubMenus";


const applManagament = (set,get) => ({

    dataFactoryMenuOpen:false,

    updateDataFactoryMenuOpen:(open)=>{
        set({dataFactoryMenuOpen:open})
    },

    dashboardApplMenuOption:false,

    updateDashboardApplMenu:menu=>{
        set({dashboardApplMenuOption:menu})
    },

    sideBarApplManagement:{ items:[
        { 
        id: 'applMgmt',
        title: '',
        type: 'group',
        children: [
            {
                id: 'applDataF',
                title: 'Data Hub',
                type: 'collapse',
                icon: DataWarehouseHub,
                contextMenu: true,
                open:true,
                children:[ ]
            
            },
        ]},
    ] },

    getHierList : (dataFactoryData) =>{
        
       
        const heirList = dataFactoryData.result.data.map(row => {
            const node = { "id": String(row.datahub_id),
                        "title": row.datahub_name,
                        "type": 'item',
                        "icon": DataHubFactory,
                        "menu":true,
                        "itemsubMenu":[{title:"Rename"
                                        ,id:row.datahub_id
                                        ,moduleTable:"datahub_factory"},
                                        {title:"Delete",
                                        id:row.datahub_id,
                                        moduleTable:"datahub_factory"},
                        ],
                        "children":[]

                        }
            return node
            })
        return {items:[
                        { 
                        id: 'applMgmt',
                        title: '',
                        type: 'group',
                        children: [
                            {
                                id: 'applDataF',
                                title: 'Data Hub',
                                type: 'collapse',
                                icon: DataWarehouseHub,
                                contextMenu: true,
                                open:true,
                                children: heirList
                            
                            },
                        ]
                        }
                    ]
                }
    },
    datahubFactoryData:[],
    getApplMgmtDataFactory : () => APIService.fetchApi('GET', {}, 'getApplMgmtDataHubFactory', {},set)
                                    .then(dataFactoryData=>{
                                        
                                        set((state)=>(
                                            { sideBarApplManagement:state.getHierList(dataFactoryData), 
                                                datahubFactoryData:dataFactoryData.result.data,
                                                errorMessage: dataFactoryData.errorMessage,
                                                isErrored:dataFactoryData.error,
                                                }))
                                        
                                        }
    ),
    getApplMgmtDataFactories: () => APIService.fetchApi('GET', {}, 'getApplMgmtDataHubFactory', {},set)
                        .then(dataFactoryData=>{
                            set((state)=>(
                                { 
                                    datahubFactoryData:dataFactoryData.result.data,
                                    errorMessage: dataFactoryData.errorMessage,
                                    isErrored:dataFactoryData.error,
                                    }))
                            }),

    applMgmtDatahubFactoryData:[],


    getApplMgmtDataFactoryById : (params) => APIService.fetchApi('GET', {}, 'getApplMgmtDataHubFactory', params,set)
                                    .then(dataFactoryData=>{
                                        set((state)=>(
                                            { applmgmtDatahubFactoryData: dataFactoryData.result.data,
                                                errorMessage: dataFactoryData.errorMessage,
                                                isErrored:dataFactoryData.error,
                                                }))
                                        }
    ),
    
    postDataFactory: (apiData)=>APIService.fetchApi('POST', apiData, 'postNewDataFactory', {},set)
                        .then(dataFactoryData=>{
                                    set ((state)=>(
                                            {applmgmt_datahub_factory: dataFactoryData.result
                                            ,errorMessage: dataFactoryData.errorMessage
                                            ,isErrored:dataFactoryData.error
                                            ,...state.getApplMgmtDataFactory()
                                            }))
                            }
                        ),
    
    putDataFactory: (apiData,params)=>APIService.fetchApi('PUT', apiData, 'putNewDataFactory', params,set)
                        .then(dataFactoryData=>{
                                    set ((state)=>({applications: dataFactoryData.result,
                                            errorMessage: dataFactoryData.errorMessage,
                                            isErrored:dataFactoryData.error,
                                            ...state.getApplMgmtDataFactory()
                                            }))
                                    
                                
                            }
                        ),

    

    applMgmtApplData:{},

    postApplMgmtAppl:(formData, params)=>APIService.fetchApi('POST', formData, 'postApplMgmtApplication', params,set)
                        .then(applicationData=>{
                                    set ((state)=>({applMgmtApplData: applicationData.result
                                            ,errorMessage: applicationData.errorMessage
                                            ,isErrored:applicationData.error
                                            ,...state.fetchApplicationsAll({param1:parseInt(state.treeViewMenusAction.clickedTree['id'])})
                                            }))
                            }
                        ),
    putApplMgmtAppl:(formData, params)=>APIService.fetchApi('PUT', formData, 'putApplMgmtApplication', params,set)
                        .then(applicationData=>{
                                    set ((state)=>({applMgmtApplData: applicationData.result
                                            ,errorMessage: applicationData.errorMessage
                                            ,isErrored:applicationData.error
                                            ,...state.fetchApplicationsAll({param1:parseInt(state.treeViewMenusAction.clickedTree['id'])})
                                            }))
                                
                        }),

    deleteApplMgmtAppl: (params)=>APIService.fetchApi('DELETE', {}, 'deleteApplMgmtApplication', params,set)
                        .then(applicationData=>{
                                    set ((state)=>({applMgmtApplData: applicationData.result
                                            ,errorMessage: applicationData.errorMessage
                                            ,isErrored:applicationData.error
                                            ,...state.fetchApplicationsAll({param1:parseInt(state.treeViewMenusAction.clickedTree['id'])})
                                            }))
                                
                        }),

    applMgmtApplications:[],

    fetchApplicationsAll:(params)=>APIService.fetchApi('GET', {}, 'fetchAllApplMgmtAppl',params,set)
                            .then(applicationData=>{            
                                        set ({applMgmtApplications: applicationData.result.data
                                                ,errorMessage: applicationData.errorMessage
                                                ,isErrored:applicationData.error
                                                
                                                })
                                }
                            ),

    applmgmtApplicationModules:{},

    fetchApplicationsModules:(params)=>APIService.fetchApi('GET', {}, 'getApplMgmtApplModule',params,set)
                            .then(applModuleData=>{            
                                        set ({applmgmtApplicationModules: applModuleData.result
                                                ,errorMessage: applModuleData.errorMessage
                                                ,isErrored:applModuleData.error
                                                
                                                })
                                            return     applModuleData.result.data   
                               }
                            ),
   

    postApplMgmtModules:(formData,params)=>APIService.fetchApi('POST', formData, 'postApplMgmtApplModule',params,set)
                        .then(applmgmtModuleData=>{
                            
                                    set ({applmgmtApplicationModules: applmgmtModuleData.result
                                            ,errorMessage: applmgmtModuleData.errorMessage
                                            ,isErrored:applmgmtModuleData.error
                                            })
                                    return  applmgmtModuleData.error 
                            }
                        ),
    
    putApplMgmtModules:(formData,params)=>APIService.fetchApi('PUT', formData, 'putApplMgmtApplModule',params,set)
                        .then(applmgmtModuleData=>{
                            
                                    set ({applmgmtApplicationModules: applmgmtModuleData.result
                                            ,errorMessage: applmgmtModuleData.errorMessage
                                            ,isErrored:applmgmtModuleData.error
                                            })
                                    return  applmgmtModuleData.error 
                            }
                        ),


    deleteApplMgmtModule:(params)=>APIService.fetchApi('DELETE', {}, 'putApplMgmtApplModule',params,set)
                        .then(applmgmtModuleData=>{
                            
                                    set ({applmgmtApplicationModules: applmgmtModuleData.result
                                            ,errorMessage: applmgmtModuleData.errorMessage
                                            ,isErrored:applmgmtModuleData.error
                                            })
                                    return  applmgmtModuleData.error 
                            }
                        ),

    getApplMgmtContact:(params)=>APIService.fetchApi('GET', {} , 'getApplMgmtContact',params,set)
                        .then(applmgmtContactData=>{
                            
                                    set ({//applmgmtApplicationModules: applmgmtModuleData.result,
                                            errorMessage: applmgmtContactData.errorMessage,
                                            isErrored:applmgmtContactData.error,
                                            })
                                    return  applmgmtContactData.result.data
                            }
                        ),
            
    postApplMgmtContact:(formData,params)=>APIService.fetchApi('POST', formData, 'postApplMgmtContact',params,set)
                        .then(applmgmtContactData=>{
                            
                                    set ({//applmgmtApplicationModules: applmgmtModuleData.result,
                                            errorMessage: applmgmtContactData.errorMessage,
                                            isErrored:applmgmtContactData.error,
                                            })
                                    return  applmgmtContactData.error 
                            }
                        ),
    
    putApplMgmtContact:(formData,params)=>APIService.fetchApi('PUT', formData, 'putApplMgmtContact',params,set)
                        .then(applmgmtContactData=>{
                            
                                    set ({//applmgmtApplicationModules: applmgmtModuleData.result,
                                            errorMessage: applmgmtContactData.errorMessage,
                                            isErrored:applmgmtContactData.error,
                                            })
                                    return  applmgmtContactData.isErrored 
                            }
                        ),

    deleteApplMgmtContact:(params)=>APIService.fetchApi('DELETE', {}, 'deleteApplMgmtContact',params,set)
                        .then(applmgmtContactData=>{
                            
                                    set ({//applmgmtApplicationModules: applmgmtModuleData.result,
                                            errorMessage: applmgmtContactData.errorMessage,
                                            isErrored:applmgmtContactData.error,
                                            })
                                    return  applmgmtContactData.error 
                            }
                        ),                    
});

export default applManagament;
