// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
//import menuItem from '../../../constants/menuItems';
import { useStore } from '../../../store/useStore';

//state.appMenuBarAction.sideBarMenu
// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {

    //console.log(useStore((state)=>(state.appMenuBarAction.sideBarMenu)));
    //[state[abc]]
    const {menuItem, //menuOpen
    } = useStore((state)=>(
            {menuItem:  state[state.appMenuBarAction.sideBarMenu],
            //menuOpen: state.appMenuBarAction,
            
            })
    );

    const navItems = menuItem.items?.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;