import {APIService} from "../service";
import util from "../util/util";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import { FolderGlossary,FolderGlossaryUser,LookupData,NewTable } from "../icons/SvgIcons";


const business = (set,get) => ({


    getBusinessGlossary: (param) => APIService.fetchApi('GET', {}  ,'getBusinessGlossary',param,set)
                                .then(apiResult=>{
                                        set({
                                            errorMessage: apiResult.errorMessage
                                            ,isErrored:apiResult.error
                                        })
                                    
                                        return apiResult
                                    
                                }
    ),

    putBusinessGlossary: (param, apiData ) => APIService.fetchApi('PUT', apiData  ,'putBusinessGlossary',param,set)
                                        .then(apiResult=>{
                                                set({
                                                    errorMessage: apiResult.errorMessage
                                                    ,isErrored:apiResult.error
                                                })
                                            
                                                return apiResult
                                            
                                        }
    ),

    postBusinessGlossary: (param, apiData ) => APIService.fetchApi('POST', apiData  ,'postBusinessGlossary',param,set)
                                        .then(apiResult=>{
                                                set({
                                                    errorMessage: apiResult.errorMessage
                                                    ,isErrored:apiResult.error
                                                })
                                            
                                                return apiResult
                                            
                                        }
    ),

    getCodeSubjects: () => APIService.fetchApi('GET', {}  ,'getCodeSubjects', {param1:'code'},set)
                                    .then(apiResult=>{

                                            if (!apiResult.error) {
                                                const parentParamsER = {
                                                    idKey:'subj_id'
                                                    ,titleKey:'subj_name'
                                                    ,type:'collapse'
                                                    ,icon:SourceOutlinedIcon
                                                    ,url:'url'
                                                    ,parentIdkey:'parent_subj_id'
                                                    ,breadCrumb: false
                                                    ,contextMenu: true
                                                    ,treeType:"code"
                                                    ,rows:apiResult.result.data
                                                }

                                                const childParamsER = {idKey:'subj_id'
                                                            ,titleKey:'subj_name'
                                                            ,type:'item'
                                                            ,icon:SourceOutlinedIcon
                                                            ,url:false
                                                            ,parentIdkey:'parent_subj_id'
                                                            ,breadCrumb: true
                                                            ,treeType:"code"
                                                            ,childRows:[]
                                                        } 


                                                const rootRec = get().getRootRec(apiResult.result.data)[0];
                                                const subjects = util.treeHierarchy(parentParamsER, childParamsER);
                                                
                                                get().getCodeSubjectFolderList(subjects, rootRec.subj_id);
                                            };

                                            set({
                                                errorMessage: apiResult.errorMessage
                                                ,isErrored: apiResult.error
                                            })
                                            
                                            return apiResult
        
            }
    ),
    
    getRootRec : (rows) => {
        const rootRec = rows.filter(rw=>!rw.parent_subj_id)
        return rootRec
    },

    getSDTSubjects: () => APIService.fetchApi('GET', {}  ,'getCodeSubjects',{param1:'sdt'},set)
                                    .then(apiResult=>{

                                        if (!apiResult.error) {
                                            const parentParamsER = {
                                                idKey:'subj_id'
                                                ,titleKey:'subj_name'
                                                ,type:'collapse'
                                                ,icon:SourceOutlinedIcon
                                                ,url:'url'
                                                ,parentIdkey:'parent_subj_id'
                                                ,breadCrumb: false
                                                ,contextMenu: true
                                                ,treeType:"sdt"
                                                ,rows:apiResult.result.data
                                            };

                                            const childParamsER = {idKey:'subj_id'
                                                            ,titleKey:'subj_name'
                                                            ,type:'item'
                                                            ,icon:SourceOutlinedIcon
                                                            ,url:false
                                                            ,parentIdkey:'parent_subj_id'
                                                            ,breadCrumb: true
                                                            ,treeType:"sdt"
                                                            ,childRows:[]
                                                        };

                                            const rootRec = get().getRootRec(apiResult.result.data)[0];
                                            
                                            const subjects =  util.treeHierarchy(parentParamsER,childParamsER);
                                            get().getSDTSideBarMenus(subjects,rootRec.subj_id);
                                        };

                                        set({
                                            errorMessage: apiResult.errorMessage
                                            ,isErrored: apiResult.error
                                        })
                                        
                                        return apiResult
        
            }
    ),

    getCodesListBySubject:(param) => APIService.fetchApi('GET', 
                                                {}  ,
                                                'getCodeTypeList',
                                                param,
                                                set
        ).then(res=>{
            set({
                errorMessage: res.errorMessage
                ,isErrored: res.error
            })
            return res
        }
    ),

    getSDTCodeTypeList:() => APIService.fetchApi('GET', 
                                                {}  ,
                                                'getSDTCodeTypeList',
                                                {},set
        ).then(res=>{
            set({
                errorMessage: res.errorMessage
                ,isErrored: res.error
            })
            return res
        }
    ),

    getLKPSubjects: () => APIService.fetchApi('GET', {}  ,'getCodeSubjects',{param1:'lkp'},set)
                        .then(apiResult=>{
                            if (!apiResult.error) {
                               

                                APIService.fetchApi('GET', {}  ,'getCodeSubjects',{param1:'lkp-tbl'},set)
                                .then(lklptbls=>{
                                    const parentParamsER = {
                                        idKey:'subj_id'
                                        ,titleKey:'subj_name'
                                        ,type:'collapse'
                                        ,icon:SourceOutlinedIcon
                                        ,url:'url'
                                        ,parentIdkey:'parent_subj_id'
                                        ,breadCrumb: false
                                        ,contextMenu: true
                                        ,treeType:"lkp"
                                        ,rows:apiResult.result.data
                                    }; 

                                    const childParamsER = {idKey:'subj_id'
                                                    ,titleKey:'subj_name'
                                                    ,type:'item'
                                                    ,icon:ListAltOutlinedIcon
                                                    ,url:false
                                                    ,parentIdkey:'parent_subj_id'
                                                    ,breadCrumb: false
                                                    ,treeType:"lkp"
                                                    ,childRows:lklptbls.result.data
                                                };

                                    const rootRec = get().getRootRec(apiResult.result.data)[0];
                                    
                                    const subjects =  util.treeHierarchy(parentParamsER,childParamsER);
                                    get().getLKPsideBarMenus(subjects,rootRec.subj_id);
                            

                                set({
                                    errorMessage: apiResult.errorMessage
                                    ,isErrored: apiResult.error
                                });
                            })
                        };
                      //return apiResult
        
    }),

    postCodeSubjects: (param, apiData ) => APIService.fetchApi('POST', apiData 
                                             ,'postCodeSubjects',param,set
                                    )
                                    .then(apiResult=>{
                                            set({
                                                errorMessage: apiResult.errorMessage
                                                ,isErrored:apiResult.error
                                            })
                                        
                                            return apiResult
        
            }
    ),

    putCodeSubjects: (param, apiData ) => APIService.fetchApi('PUT', apiData  ,'putCodeSubjects',param,set)
                                    .then(apiResult=>{
                                            set({
                                                errorMessage: apiResult.errorMessage
                                                ,isErrored:apiResult.error
                                            })
                                        
                                            return apiResult
        
            }
    ),

    deleteCodeSubjects: (param ) => APIService.fetchApi('DELETE', {}  ,'deleteCodeSubjects',param,set)
                                    .then(apiResult=>{
                                            set({
                                                errorMessage: apiResult.errorMessage
                                                ,isErrored:apiResult.error
                                            })
                                        
                                            return apiResult
        
            }
    ),
    
    getCodeValues:( param ) => APIService.fetchApi('GET', {}, param ? 'getCodeValuesBySubject' : 'getCodeValues'  , param ? param : {} ,set)
                            .then(apiResult=>{
                            set({
                                codeValues:apiResult.result.data,
                                errorMessage: apiResult.errorMessage,
                                isErrored:apiResult.error,
                            })
                        
                            return apiResult

        }
    ),

    getCodeValuesBySubject:(param ) => APIService.fetchApi('GET', {}  , 'getCodeValuesBySubject' ,param,set)
                            .then(apiResult=>{
                            set({
                                codeValues:apiResult.result.data,
                                errorMessage: apiResult.errorMessage,
                                isErrored:apiResult.error,
                            })
                        
                            return apiResult

        }
    ),

    postCodeValues:(apiData) => APIService.fetchApi('POST', apiData  ,'postCodeValues',{},set)
                                .then(apiResult=>{
                                set({
                                    errorMessage: apiResult.errorMessage
                                    ,isErrored:apiResult.error
                                })

                                return apiResult

                            }
    ),


    putCodeValues:(param, apiData) => APIService.fetchApi('PUT', apiData  ,'putCodeValues',{},set)
                                .then(apiResult=>{
                                set({
                                    errorMessage: apiResult.errorMessage
                                    ,isErrored:apiResult.error
                                })

                                return apiResult

                            }
    ),

    deleteCodeValues:(apiData) => APIService.fetchApi('DELETE', apiData  ,'deleteCodeValues',{},set)
                                .then(apiResult=>{
                                set({
                                    errorMessage: apiResult.errorMessage
                                    ,isErrored:apiResult.error
                                })

                                return apiResult

                            }
    ),
    
    codeValues:[],

    getCodeSubjectFolderList: (subjectHier,subj_id) =>{
        //const subjectHier = get().codeSubjectFolderList

        set({codeValuesSideBarMenus:{items:[
            {
                id: 'sdt',
                title: 'Codes Standardization',
                type: 'group',
                children: [
                    {
                        id: `${subj_id}-code`,
                        title: 'Code Values',
                        type: 'collapse',
                        icon: ArticleOutlinedIcon,
                        url: "",
                        treeType:"code",
                        contextMenu: true,
                        open:false,
                        children:[    
                            ...subjectHier 
                        ]
                    },
                ]
            }
        ]}})
        
    },

    getSDTSideBarMenus: (subjectHier, subj_id) => {
        set({sdtSideBarMenus:{items:[
            {
                id: 'sdt',
                title: 'Codes Standardization',
                type: 'group',
                children: [
                    {
                        id: `${subj_id}-sdt`,
                        title: 'Code Translation',
                        type: 'collapse',
                        icon: ArticleOutlinedIcon,
                        contextMenu: true,
                        treeType:"sdt",
                        open:false,
                        children:[
                            ...subjectHier 
                        ]
                    },
                ]
            }
        ]}})
    } , 

    getLKPsideBarMenus: (subjectHier, subj_id) => {
        set({lkpSideBarMenus:{items:[
            {
                id: 'lkp',
                title: 'Lookup Reference Data',
                type: 'group',
                children: [
                    {
                        id: `${subj_id}-lkp`,
                        title: 'Lookup Data',
                        type: 'collapse',
                        icon: ArticleOutlinedIcon,
                        contextMenu: true,
                        treeType:"lkp",
                        open:false,
                        children:[...subjectHier ]
                    }
                ]
                
            },
        ]}})
    } , 

    codeValuesSideBarMenus:{
        items:[
            {
                id: 'sdt',
                title: 'Codes Standardization',
                type: 'group',
                children: [
                    {
                        id: '0-code',
                        title: 'Code Values',
                        type: 'collapse',
                        icon: ArticleOutlinedIcon,
                        url: "",
                        contextMenu: true,
                        open:false,
                        children:[     
                        ]
                    },
                ]
            },
           
        ]
    },

 

    sdtSideBarMenus: {
        items:[
            {
                id: 'sdt',
                title: 'Code Translation',
                type: 'group',
                children: []
                
            },
           
        ]
    },

    lkpSideBarMenus: {
        items:[
            {
                id: 'lkp',
                title: 'Lookup Reference Data',
                type: 'group',
                children: [
                    {
                        id: 'lkp',
                        title: 'Lookup Data',
                        type: 'collapse',
                        icon: ArticleOutlinedIcon,
                        contextMenu: true,
                        treeType:"lkp",
                        open:false,
                        children:[]
                    }
                ]
                
            },
           
        ]
    },


    businessSideBarMenus: {
        items:[
          
            { 
            id: 'business',
            title: 'Business Glossary and Terms',
            type: 'group',
            children: [
                {
                    id: 'glossary',
                    title: 'Glossary',
                    type: 'collapse',
                    icon: ArticleOutlinedIcon,
                    contextMenu: false,
                    open:false,
                    children:[ 
                        {
                            id: 'glossary-ins',
                            title: 'Insurance',
                            type: 'collapse',
                            icon: ArticleOutlinedIcon,
                            contextMenu: false,
                            open:false,
                            children:[ 
                                {
                                    id: 'glossary-insurance-stand',
                                    title: 'Standard',
                                    type: 'item',
                                    icon: FolderGlossary,
                                    contextMenu: false,
                                    treeType:"insurance-stand",
                                    open:false,
                                    children:[],
                                },
                                {
                                    id: 'glossary-insurance-user',
                                    title: 'User Defined',
                                    type: 'item',
                                    icon: FolderGlossaryUser,
                                    contextMenu: false,
                                    treeType:"insurance-user",
                                    open:false,
                                    children:[],
                                }
                            ]
                        },
                        {
                            id: 'glossary-banking',
                            title: 'Banking',
                            type: 'collapse',
                            icon: ArticleOutlinedIcon,
                            contextMenu: false,
                            open:true,
                            children:[]
                    
                        },
                        {
                            id: 'glossary-invest',
                            title: 'Investment',
                            type: 'collapse',
                            icon: ArticleOutlinedIcon,
                            contextMenu: false,
                            open:false,
                            children:[]
                    
                        },
                        {
                            id: 'glossary-manf',
                            title: 'Manufacturing',
                            type: 'collapse',
                            icon: ArticleOutlinedIcon,
                            contextMenu: false,
                            open:false,
                            children:[]
                    
                        },
                        {
                            id: 'glossary-ecom',
                            title: 'Ecommerce',
                            type: 'collapse',
                            icon: ArticleOutlinedIcon,
                            contextMenu: false,
                            open:false,
                            children:[]
                    
                        },
                        {
                            id: 'glossary-supc',
                            title: 'Supply Chain',
                            type: 'collapse',
                            icon: ArticleOutlinedIcon,
                            contextMenu: false,
                            open:false,
                            children:[]
                    
                        },
                    ]
                
                },
                
            ]},
        ]
    },


    postLookupData:(apiData, param) => APIService.fetchApi('POST', apiData  ,'postLookupData',param,set)
                                .then(apiResult=>{
                                set({
                                    errorMessage: apiResult.errorMessage
                                    ,isErrored:apiResult.error
                                })

                                return apiResult

                                }
    ),

    getLookupData:(param) => APIService.fetchApi('GET', {}  ,'getLookupData',param,set)
                                .then(apiResult=>{
                                set({
                                    errorMessage: apiResult.errorMessage
                                    ,isErrored:apiResult.error
                                })

                                return apiResult

                                }
    ),

    updateLookupData:(apiData,param) => APIService.fetchApi('PUT', apiData  ,'updateLookupData',param,set)
                                .then(apiResult=>{
                                set({
                                    errorMessage: apiResult.errorMessage
                                    ,isErrored:apiResult.error
                                })

                                return apiResult

                                }
    ),

    deleteLookupData:(apiData) => APIService.fetchApi('DELETE', apiData  ,'deleteLookupData',{},set)
                                .then(apiResult=>{
                                    set({
                                        errorMessage: apiResult.errorMessage
                                        ,isErrored:apiResult.error
                                    })

                                return apiResult

                             }
),

    codeTypeDroppedBUS:"",

    updateCodeTypeAddedBUS:(val)=>{
        set({codeTypeDroppedBUS:val})
    },

    codeTypeMenuActionsBUS:{
        isOpen: [], // for active default menu
        defaultId: 'default',
        fontFamily: `'Roboto', sans-serif`,
        opened: true,
        borderRadius:12
    },
  
    updateCodeTypeMenuActionsBUS: (action) => {
        let id;
        if (action.type==='MENU_OPEN') {
          id = action.id;
          set((state)=>({codeTypeMenuActionsBUS:{...state,isOpen:[...id,]}}))
        } else if(action.type==='SET_MENU') {
          set((state)=>({codeTypeMenuActionsBUS:{...state,opened:action.opened}}))
        }
    },

    codeTypeMenuBarActionBUS:{module:"", open:false, sideBarMenu:"default"},

    updateCodeTypeMenuActionBUS : (action) => {
        set({codeTypeMenuBarActionBUS:action})
      },

    codeTypetreeViewMenusActionBUS:{anchorEl:null,clickedTree:{}},

    updateCodeTypeTreeViewMenusActionBUS: (action) => {
          set({codeTypetreeViewMenusActionBUS:action})
    },


    rowSourceSDT:"",

    updateRowSourceSDT: (rec)=>{
        set({rowSourceSDT:rec})
    },

    enterpriseDataTranslationMapping:[],

    updateEnterpriseDataTranslationMapping: (mappedRec) => {
        set({enterpriseDataTranslationMapping: [...get().enterpriseDataTranslationMapping, mappedRec ]} )
    },

    codeRowBoudingValues: {},

    updateCodeRowBoudingValues: (boundingVal) => {
        set({codeRowBoudingValues:boundingVal})
    },



});


export default business;


