import {IconUsers, IconKey,IconDashboard ,IconTypography
    , IconPalette, IconShadow, IconWindmill
    ,IconList,IconListDetails,IconUserCircle
    ,IconDeviceLaptop,IconUserCode,IconUserBolt,IconPoint
    ,IconTable,IconDatabase,IconFileDatabase,IconHierarchy2,IconUsb
  } from '@tabler/icons-react';

import  FolderDeleteIcon  from '@mui/icons-material/FolderDelete';
import  EditIcon  from '@mui/icons-material/Edit';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

import {CreateNewFolderOutlined as CreateNewFolderOutlinedIcon
    , FolderDeleteOutlined as FolderDeleteOutlinedIcon 
    , EditOutlined as  EditOutlineIcon
 } from '@mui/icons-material';




const icons = {
    IconUsers,
    IconList,
    IconListDetails,
    IconUserCode,
    IconUserBolt,
    IconPoint,
    IconUserCircle,
    IconDeviceLaptop,
    IconKey,
    IconDashboard,
    IconTypography, IconPalette, IconShadow, IconWindmill,
    IconTable,IconDatabase,IconFileDatabase,IconHierarchy2,IconUsb
    ,CreateNewFolderOutlinedIcon,FolderDeleteOutlinedIcon,EditOutlineIcon
};



export const subMenuItems = (set, get) => ({
    sideBarAdminMenus1:{items:[{
        id: 'admin',
        title: '',
        type: 'group',
        children: [
                {
                id: 'userlist',
                title: 'Users',
                type: 'collapse',
                icon: icons.IconUsers,
                children:[
                    {
                        id: 'active',
                        title: 'Active',
                        type: 'item',
                        url: '/gui/admin/users',
                        icon: icons.IconUserCircle,
                        breadcrumbs: false
                    },
                    {
                      id: 'inactive',
                      title: 'Inactive',
                      type: 'item',
                      url: '/gui/admin/inactive',
                      icon: icons.IconUserCircle,
                      breadcrumbs: false
                    },
                    {
                      id: 'terminated',
                      title: 'Terminated',
                      type: 'item',
                      url: '/gui/admin/suspend',
                      icon: icons.IconUserCircle,
                      breadcrumbs: false
                    }
                    ]
                }
                    
            ]
        },
        {
        id: 'roles',
        title: '',
        type: 'group',
        children:[
            {
            id: 'roles',
            title: 'Roles',
            type: 'item',
            url: '/gui/admin/roles',
            icon: icons.IconList,
            breadcrumbs: false
            },
        ]
        },
        {
        id: 'activity',
        //title: 'Activity',
        type: 'group',
        children:[
            {
            id: 'activity',
            title: 'Activity',
            type: 'collapse',
            icon: icons.IconUserCode,
            children:[
                {
                id: 'activityOnline',
                title: 'Online',
                type: 'item',
                url: '/admin/useronline',
                icon: icons.IconUserBolt,
                breadcrumbs: false
                },
                {
                id: 'activitytxns',
                title: 'Transaction',
                type: 'item',
                url: '/user/useractivities',
                icon: icons.IconListDetails,
                breadcrumbs: false
                }
            ]
            }
        ]
        }
        ]
    },

    sideBarConnectionMenus: {items:[{
        id: 'connection',
        title: '',
        type: 'group',
        children: [
              {
                id: 'connectionlist',
                title: 'Connections',
                type: 'collapse',
                open:true,
                icon: icons.IconListDetails,
                children:[
                    {
                        id: 'azure',
                        title: 'Azure',
                        type: 'item',
                        url: '',
                        icon: icons.IconFileDatabase,
                        breadcrumbs: false
                    },
                    {
                        id: 'awss3',
                        title: 'AWS S3',
                        type: 'item',
                        url: '',
                        icon: icons.IconFileDatabase,
                        breadcrumbs: false
                    },
                    {
                      id: 'bigquery',
                      title: 'BigQuery',
                      type: 'item',
                      url: '',
                      icon: icons.IconDatabase,
                      breadcrumbs: false
                    },
                    {
                      id: 'db2',
                      title: 'DB2',
                      type: 'item',
                      url: '',
                      icon: icons.IconDatabase,
                      breadcrumbs: false
                    },
                    {
                        id: 'mysql',
                        title: 'MySQL',
                        type: 'item',
                        url: '',
                        icon: icons.IconDatabase,
                        breadcrumbs: false
                      },
                      {
                        id: 'mssqlserver',
                        title: 'MS SQL Server',
                        type: 'item',
                        url: '',
                        icon: icons.IconDatabase,
                        breadcrumbs: false
                      },
                      {
                        id: 'ibmcobol',
                        title: 'IBM Cobol',
                        type: 'item',
                        url: '',
                        icon: icons.IconDatabase,
                        breadcrumbs: false
                      },
                      {
                        id: 'oracle',
                        title: 'Oracle',
                        type: 'item',
                        url: '',
                        icon: icons.IconDatabase,
                        breadcrumbs: false
                      },
                      {
                        id: 'postgres',
                        title: 'Postgres',
                        type: 'item',
                        url: '',
                        icon: icons.IconDatabase,
                        breadcrumbs: false
                      },
                      {
                        id: 'redshift',
                        title: 'Red Shift',
                        type: 'item',
                        url: '',
                        icon: icons.IconDatabase,
                        breadcrumbs: false
                      },
                      {
                        id: 'snowflake',
                        title: 'Snowflake',
                        type: 'item',
                        url: '',
                        icon: icons.IconDatabase,
                        breadcrumbs: false
                      },
                      {
                        id: 'text',
                        title: 'Text File',
                        type: 'item',
                        url: '',
                        icon: icons.IconFileDatabase,
                        breadcrumbs: false
                      },
                      {
                        id: 'vertica',
                        title: 'Vertica',
                        type: 'item',
                        url: '',
                        icon: icons.IconDatabase,
                        breadcrumbs: false
                      },
                    ]
            }
                    
        ]
        }]},
    
    sideBarMetadataMenus:{items:[
        { 
        id: 'catalog',
        title: '',
        type: 'group',
        children: [
            {
                id: 'metadata',
                title: 'Metadata',
                type: 'collapse',
                icon: icons.IconDatabase,
                children:[
                    {
                        id: 'app',
                        title: 'APP',
                        type: 'collapse',
                        icon: icons.IconFileDatabase,

                    }
                ]
            },
        ]},
        {
            id: 'ermodelheader',
            title: '',
            type: 'group',
            children:[
                {
                id: 'model',
                title: 'ER Module',
                type: 'item',
                url: '/gui/catalog/er',
                icon: icons.IconHierarchy2,
                breadcrumbs: false
                },
            ]
        },
        {
            id: 'conn',
            title: '',
            type: 'group',
            children:[
                {
                id: 'connection',
                title: 'Connection',
                type: 'item',
                url: '/gui/catalog/conn',
                icon: icons.IconUsb,
                breadcrumbs: false
                },
            ]
        },


    ]},
    updatesideBarMetadataMenus: (menus) =>{
        set({sideBarMetadataMenus:menus})
    },

    treeViewMenusAction:{anchorEl:null,clickedTree:{}},

    updateTreeViewMenusAction: (action) => {
        set({treeViewMenusAction:action})
    },
    

    treeViewSubMenu : {
        items:[
            {   id:'rename',
                title: 'Rename Folder',
                icon: icons.EditOutlineIcon,
                actionType: 'rename'
            },
            {   id:'create',
                title: 'Create New Subject',
                icon: icons.CreateNewFolderOutlinedIcon,
                actionType: 'create'
            },
            {   id:'delete',
                title: 'Delete Folder',
                icon: icons.FolderDeleteOutlinedIcon,
                actionType: 'delete'
            }
        ]
    },
    folderMenuAction:{type:null, dialog:false, content:{}, treeData:{}},

    updateFolderMenuAction:(action) =>{
        set({folderMenuAction:action})
    },



});